import {PaymentMethod} from '@marketplace/shared-lib/graphql/graphql'
import {toKebabCase} from '@marketplace/shared-lib/src/utils/stringUtils'
import {RadioButtonGroup, Text} from '@s-group/design-system-components'
import RadioButtonOrCheckbox from 'Components/SelectionBox/RadioButtonOrCheckbox'
import {useTranslation} from 'react-i18next'

type LoyaltyProgramSelectionProps = {
  availableMethods: PaymentMethod[]
  selectedValue: PaymentMethod | undefined
  onChange: (paymentMethod: PaymentMethod) => void
}

const PaymentMethodSelection = ({availableMethods, selectedValue, onChange}: LoyaltyProgramSelectionProps) => {
  const {t} = useTranslation('order')
  const onlyCard = availableMethods.length === 1 && availableMethods[0] === PaymentMethod.CARD
  const onlyRoomBill = availableMethods.length === 1 && availableMethods[0] === PaymentMethod.ROOM_BILL
  const noMethods = availableMethods.length < 1

  if (noMethods) {
    return <div />
  }

  if (onlyCard) {
    return (
      <Text variant='heading' sizing='xxxsmall' weight='medium' data-testid='payment-card'>
        {t('summary.paymentCard')}
      </Text>
    )
  }

  if (onlyRoomBill) {
    return (
      <Text variant='heading' sizing='xxxsmall' weight='medium' data-testid='payment-room-bill'>
        {t('summary.paymentRoomBill')}
      </Text>
    )
  }

  return (
    <RadioButtonGroup>
      <Text variant='heading' sizing='xxxsmall' weight='medium'>
        {t('summary.paymentMethodLabel')}
      </Text>
      {availableMethods.map((method) => (
        <RadioButtonOrCheckbox
          id={`payment-method-${toKebabCase(method)}`}
          name='payment-method-selector'
          testId={`payment-method-${toKebabCase(method)}`}
          buttonType='radio'
          checked={selectedValue === method}
          onChange={() => onChange(method)}
          value={method}
          key={`payment-method-${toKebabCase(method)}`}
          label={
            <Text variant='body' sizing='small' weight={selectedValue === method ? 'medium' : 'regular'}>
              {t(`summary.paymentMethod.${method}`)}
            </Text>
          }
        />
      ))}
    </RadioButtonGroup>
  )
}

export default PaymentMethodSelection
