import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {Text} from '@s-group/design-system-components'
import {type BodySize} from '@s-group/design-system-components/dist/esm/Text/Text'
import {type ComponentProps} from 'react'
import {useTranslation} from 'react-i18next'
import {CoopMemberPriceWrapper, SMemberPriceIcon} from './PriceStyles'

const defaultTextProps: ComponentProps<typeof Text> = {
  variant: 'body',
  weight: 'bold',
  sizing: 'medium',
}

const CoopMemberPrice = ({
  coopMemberPrice,
  marginLeft = '1rem',
  formatSignDisplay,
  testId,
  textProps,
}: {
  coopMemberPrice?: number | null
  marginLeft?: string
  formatSignDisplay?: boolean
  testId?: string
  textProps?: ComponentProps<typeof Text>
}) => {
  const {t} = useTranslation('order')

  if (!coopMemberPrice) {
    return null
  }

  const textPropsMerged = {...defaultTextProps, ...textProps}

  return (
    <CoopMemberPriceWrapper marginLeft={marginLeft} data-testid={testId}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Text {...textPropsMerged}>
        <SMemberPriceIcon aria-label={t('menu.coopMemberPrice')} $sizing={textPropsMerged.sizing as BodySize} />
        {formatPrice({price: coopMemberPrice, signDisplay: formatSignDisplay})}
      </Text>
    </CoopMemberPriceWrapper>
  )
}

export default CoopMemberPrice
