import {BodySize} from '@s-group/design-system-components/dist/esm/Text/Text'
import {IconPaymentSbonusHorizontalFilled} from '@s-group/design-system-icons'
import {SDS_BRAND_COLOR_ELEMENT_MEDIUM_PRIMARY} from '@s-group/design-system-tokens/web/tokens/sbrand/colors.es6'
import styled from 'styled-components'

export const CoopMemberPriceWrapper = styled.span<{marginLeft: string}>`
  margin-left: ${({marginLeft}) => marginLeft};
  white-space: nowrap;
`

/**
 * Green S Member icon to signify the ASOM member price.
 *
 * When used with the Text component from SDS, place the SMemberPriceIcon tag
 * inside the Text component so that it inherits the text's line-height to align
 * the icon vertically.
 */
export const SMemberPriceIcon = styled(IconPaymentSbonusHorizontalFilled)<{$sizing?: BodySize}>`
  color: ${SDS_BRAND_COLOR_ELEMENT_MEDIUM_PRIMARY};
  margin-right: ${({theme}) => theme.spacing.xxxxsmall};

  /* SBonusIcon is only used with numbers. Numbers don't go below the baseline
   * like letters such as 'g' or 'y'. Because of this, 'vertical-align: middle'
   * doesn't look quite in the middle. We need to do minor adjustments to the
   * vertical position with negative margin make it look better with numbers. */
  vertical-align: middle;
  margin-top: ${({$sizing}) => {
    switch ($sizing) {
      case 'xsmall':
        return '-2px'
      case 'small':
        return '-2px'
      case 'medium':
        return '-3px'
      case 'large':
        return '-3px'
      default:
        return '0'
    }
  }};
`
