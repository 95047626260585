import {useEffect, useRef} from 'react'

/**
 * This hook initiates provided callback when user clicks either outside the ref element or clicks "ESC" key
 * @param callback callback to execute
 * @returns ref for targeting certain HTML element
 */
const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLElement>(null)
  const timeOutRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // removes possible issue where outside element also triggers same callback
        timeOutRef.current = setTimeout(() => callback(), 10)
      }
    }

    const handleKeyboardClick = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback()
      }
    }

    document.addEventListener('mouseup', handleClickOutside)
    document.addEventListener('touchend', handleClickOutside)
    document.addEventListener('keydown', handleKeyboardClick)
    return () => {
      clearTimeout(timeOutRef.current)
      document.removeEventListener('mouseup', handleClickOutside)
      document.removeEventListener('touchend', handleClickOutside)
      document.removeEventListener('keydown', handleKeyboardClick)
    }
  }, [callback])

  return ref
}

export default useOutsideClick
