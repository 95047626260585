import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {Text} from '@s-group/design-system-components'
import Divider from 'Components/Divider/Divider'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useTriggerEvent from 'hooks/useTriggerEvent'
import {useTranslation} from 'react-i18next'
import {color} from 'theme'
import AgeDialog from './AgeDialog'
import {WelcomeHeader, WelcomeMain, WelcomeTextWrapper} from './WelcomeStyles'

const {ROOM, OPEN_ROOM} = TableType

const Welcome = () => {
  const {t} = useTranslation('welcome')
  const {tableData} = useRestaurantContext()
  const {loading} = useTriggerEvent()

  const tableType = tableData?.getTable.tableType
  /**
   * Do NOT show welcome text on room service.
   * We still want to present age dialog in case there are alcohol on the menu
   */
  const showWelcomeText = tableType !== ROOM && tableType !== OPEN_ROOM

  if (loading) return <LoadingScreen />

  return (
    <WelcomeMain data-testid='welcome-main'>
      <WelcomeHeader variant='display' sizing='xxxxsmall' weight='semibold' data-testid='welcome-dialog-header'>
        {t('header')}
      </WelcomeHeader>
      <AgeDialog />
      {showWelcomeText && (
        <>
          <Divider color={color.border.mediumSecondary} />
          <WelcomeTextWrapper
            data-testid='main'
            aria-labelledby='welcome-dialog-header'
            aria-describedby='welcome-dialog-paragraph'
          >
            <Text sizing='small' data-testid='welcome-dialog-paragraph' id='welcome-dialog-paragraph'>
              {t('welcomeParagraph')}
            </Text>
          </WelcomeTextWrapper>
        </>
      )}
    </WelcomeMain>
  )
}

export default Welcome
