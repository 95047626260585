import {useTranslation} from 'react-i18next'
import {PriceType} from '@marketplace/shared-lib/graphql/graphql'
import {formatPricePlainText} from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import {useGetCommaSeparatedDietTagsAbbreviations} from 'Components/Product/productCardUtils'
import {NoWrap, PortionOptionLabelWrapper} from './SelectionBoxStyles'
import {PortionOptionWithStatus} from './SelectionBoxTypes'

type PortionOptionLabelProps = {
  portionOption: PortionOptionWithStatus
}

/**
 * Label for a portion option for radio button or checkbox.
 *
 * Label contains:
 *   - portion option name
 *   - possible "out fo stock" message
 *   - possible diet tags
 *   - possible price info (including normal and coop member price)
 */
const PortionOptionLabel = ({portionOption: {label, price, diets, outOfStock}}: PortionOptionLabelProps) => {
  const {
    t,
    i18n: {language},
  } = useTranslation('order')
  const getDietTags = useGetCommaSeparatedDietTagsAbbreviations()
  const labelMainText = getLocalized(label, language)
  const outOfStockMessage = outOfStock ? <NoWrap>{`(${t('menu.outOfStock')})`}</NoWrap> : ''
  const dietTags = getDietTags(diets)
  const {normal, coopMember, type: priceType} = price || {}
  const showPriceWithPlusSign = priceType === PriceType.ADDITIONAL
  const normalPriceFormatted = normal && formatPricePlainText(normal, showPriceWithPlusSign)
  const coopMemberPriceElement = (
    <CoopMemberPrice
      textProps={{
        sizing: 'small',
        style: {
          // Override line-height or otherwise the vertical alignment
          // is off between the radio button and the label
          lineHeight: '1rem',
        },
      }}
      coopMemberPrice={coopMember}
      formatSignDisplay={priceType === PriceType.ADDITIONAL}
    />
  )
  // keep the price info on one line
  const priceElement = !!normalPriceFormatted && (
    <NoWrap>
      {normalPriceFormatted}
      {coopMemberPriceElement}
    </NoWrap>
  )
  const hasExtraInfo = !!dietTags || !!priceElement

  return (
    <PortionOptionLabelWrapper>
      {labelMainText}
      {outOfStockMessage && (
        <>
          {` `}
          {outOfStockMessage}
        </>
      )}
      {hasExtraInfo && ', '}
      {dietTags && ` (${dietTags})`}
      {priceElement && (
        <>
          {` `}
          {priceElement}
        </>
      )}
    </PortionOptionLabelWrapper>
  )
}

export default PortionOptionLabel
