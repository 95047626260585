import {BrandWrapper, Link, LinkButton, Notification} from '@s-group/design-system-components'
import {
  IconNavigationChevronRight,
  IconPaymentSbonusHorizontal,
  IconStatusErrorFilled,
} from '@s-group/design-system-icons'
import {
  SDS_BRAND_COLOR_TEXT_ERROR,
  SDS_BRAND_COLOR_TEXT_SUCCESS,
} from '@s-group/design-system-tokens/web/tokens/raflaamo/colors.es6'
import '@s-group/design-system-tokens/web/tokens/sbrand/colors.css'

import {useTranslation} from 'react-i18next'
import {DoubleIconWrapper, NotificationWrapper, PopUpHeading, PopupRow, PopUpText} from '../Login/LoginPopUpStyles'
import {getLinkToAttachLoyaltyCard, getLinkToJoinLoyalty} from './sidLinkUtils'

const TrailerComponent = () => {
  const {t, i18n} = useTranslation('login')
  const attachBonusCardLink = getLinkToAttachLoyaltyCard(i18n.language)
  const joinLoyaltyLink = getLinkToJoinLoyalty(i18n.language)
  return (
    <NotificationWrapper>
      <PopUpText variant='body' sizing='small' weight='regular' tagName='p' data-testid='attach-bonus-card-description'>
        {t('attachBonusCardDescription')}
      </PopUpText>
      <BrandWrapper theme='sbrand'>
        <LinkButton
          data-testid='attach-bonus-card-action'
          color='primary'
          variant='filled'
          rounding='pill'
          sizing='xsmall'
          href={attachBonusCardLink}
        >
          {t('attachBonusCardHeader')}
        </LinkButton>
      </BrandWrapper>

      <Link
        target='_blank'
        href={joinLoyaltyLink}
        color='neutral'
        standalone
        icon={<IconNavigationChevronRight size='16' />}
      >
        {t('joinSLoyalty')}
      </Link>
    </NotificationWrapper>
  )
}

const AttachBonusCard = () => {
  const {t} = useTranslation('login')

  return (
    <PopupRow $noPadding>
      <Notification
        alignment='left'
        contentProps='100%'
        header={
          <PopUpHeading
            sizing='xxxxsmall'
            variant='heading'
            weight='bold'
            tagName='h3'
            data-testid='attach-bonus-card-header'
          >
            {t('attachBonusCardHeader')}
          </PopUpHeading>
        }
        icon={
          <DoubleIconWrapper>
            <IconPaymentSbonusHorizontal color={SDS_BRAND_COLOR_TEXT_SUCCESS} size='24' />
            <IconStatusErrorFilled color={SDS_BRAND_COLOR_TEXT_ERROR} />
          </DoubleIconWrapper>
        }
        orientation='vertical'
        status='success'
        sizing='small'
        close={false}
        trailer={<TrailerComponent />}
      />
    </PopupRow>
  )
}

export default AttachBonusCard
