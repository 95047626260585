import {PortionOptionSectionType} from '@marketplace/shared-lib/graphql/graphql'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Label, Text} from '@s-group/design-system-components'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import RadioButtonOrCheckbox from 'Components/SelectionBox/RadioButtonOrCheckbox'
import SelectionBoxGroup from 'Components/SelectionBox/SelectionBoxGroup'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'react-i18next'
import {spacing} from 'theme'
import {PortionSelectedIds} from 'types/productCardTypes'
import {getPortionPrice} from 'utils/portionPriceUtils'
import useTriggerEventNew from '../../hooks/useTriggerEventNew'
import useTriggerViewPromotionEvent from '../../hooks/useTriggerViewPromotionEvent'
import {EventItemContext} from '../../types/analyticsTypesNew'
import {mapPortionToEventItem} from '../../utils/analytics'
import {
  DietTagsAbbreviations,
  OptionsWrapper,
  RecommendedOptionSectionName,
  RecommendedOptionsWrapper,
  RecommendedPortionDescription,
  RecommendedPortionInfo,
  RecommendedPortionWrapper,
  RecommendedProductPrice,
  SectionTitle,
  SizeLabel,
} from './ProductStyles'
import {
  PortionWithMenuID,
  getButtonType,
  getPortionSelections,
  getSelectedPortionOptions,
  getSelectedSizeLabel,
  useGetCommaSeparatedDietTagsAbbreviations,
} from './productCardUtils'
import {getPortionOptionsWithStatus} from './utils'

export type ProductRecommendationsProps = {
  recommendationsHeader: string
  recommendations: PortionWithMenuID[]
  selectedPortions: PortionWithMenuID[]
  setSelectedPortions: Dispatch<SetStateAction<PortionWithMenuID[]>>
  portionsSelections: PortionSelectedIds[]
  handleOptionChangeRadio: (portion: PortionWithMenuID, optionSectionId: string, optionId: string) => void
  handleOptionChangeCheckbox: (
    portion: PortionWithMenuID,
    optionSectionId: string,
    optionId: string,
    maxCount?: number | null,
    decreaseQuantity?: boolean,
    deselect?: boolean
  ) => void
  analytics: EventItemContext
}

const ProductRecommendations = ({
  recommendationsHeader,
  recommendations,
  selectedPortions,
  setSelectedPortions,
  portionsSelections,
  handleOptionChangeRadio,
  handleOptionChangeCheckbox,
  analytics,
}: ProductRecommendationsProps) => {
  const {i18n, t} = useTranslation('order')
  const locale = i18n.language
  const getDietTags = useGetCommaSeparatedDietTagsAbbreviations()
  const triggerEventNew = useTriggerEventNew()
  const {restaurantFeatures} = useRestaurantContext()

  useTriggerViewPromotionEvent(recommendations, analytics)

  if (!recommendations.length) return null

  return (
    <>
      <OptionsWrapper>
        <SectionTitle variant='display' sizing='xxxxsmall' weight='bold' data-testid='recommendations-section-title'>
          {recommendationsHeader}
        </SectionTitle>
      </OptionsWrapper>
      {recommendations.map((recommendedPortion) => {
        const {id: portionId, name, description, portionOptionSections, diet} = recommendedPortion
        const portionName = getLocalized(name, locale)
        const portionDescription = getLocalized(description, locale)
        const commaSeparatedDietTagsAbbreviations = getDietTags(diet)

        const isChecked = !!selectedPortions.find((item) => item.id === portionId)
        const portionSelections = getPortionSelections(portionId, portionsSelections)
        const sizeLabel = getLocalized(getSelectedSizeLabel(portionOptionSections, portionSelections), locale)
        const {totalNormalPrice, totalCoopMemberPrice} = getPortionPrice(recommendedPortion, portionSelections)

        const onlySizeSelection =
          portionOptionSections.length === 1 && portionOptionSections[0]?.type === PortionOptionSectionType.SIZE
        const doNotShowTotalPrice = onlySizeSelection && isChecked

        return (
          <OptionsWrapper key={portionId} data-testid={`recommendation-container-${portionId}`}>
            <RecommendedPortionWrapper data-testid={`recommendation-wrapper-${portionId}`}>
              <RadioButtonOrCheckbox
                id={`recommendation-checkbox-${portionId}`}
                testId={`recommendation-checkbox-${portionId}`}
                buttonType='checkbox'
                value={portionId}
                name={portionName}
                checked={isChecked}
                onChange={({target}) => {
                  if (target.checked) {
                    const portionItem = getSelectedPortionOptions(recommendedPortion, portionSelections)
                    if (!portionItem) return
                    setSelectedPortions((prevState) => [...prevState, portionItem])
                    const index = recommendations.findIndex(
                      (recommendation) => recommendation.id === recommendedPortion.id
                    )
                    triggerEventNew?.({
                      event: 'select_promotion',
                      ecommerce: {
                        items: [{...mapPortionToEventItem(recommendedPortion), index, ...analytics}],
                      },
                    })
                  } else {
                    setSelectedPortions((prevState) => [...prevState.filter((portion) => portion.id !== portionId)])
                  }
                }}
                label={
                  <RecommendedPortionInfo>
                    <Label
                      htmlFor={`recommendation-checkbox-${portionId}`}
                      data-testid={`recommendation-label-${portionId}`}
                    >
                      <Text variant='heading' sizing='xxsmall' weight='medium'>
                        {portionName}
                      </Text>
                      {commaSeparatedDietTagsAbbreviations && (
                        <DietTagsAbbreviations variant='heading' sizing='xxsmall' $paddingLeft={spacing.xxsmall}>
                          {`(${commaSeparatedDietTagsAbbreviations})`}
                        </DietTagsAbbreviations>
                      )}
                    </Label>
                    {portionDescription && (
                      <RecommendedPortionDescription
                        variant='body'
                        sizing='small'
                        $lineClamp={!isChecked}
                        data-testid={`recommendation-description-${portionId}`}
                      >
                        {portionDescription}
                      </RecommendedPortionDescription>
                    )}
                    {portionOptionSections.map((optionSection) => {
                      if (!optionSection || !isChecked || !portionSelections) return null
                      const {id: optionSectionId, portionOptions, type, minCount, maxCount} = optionSection

                      // minCount and maxCount can be undefined
                      const definedButtonType = getButtonType(type, minCount, maxCount)
                      const optionSectionNameTranslated = getLocalized(optionSection.name, locale)
                      const selectedOptionIds = (portionSelections ?? [])
                        .filter(({selectedSectionId}) => selectedSectionId === optionSectionId)
                        .flatMap((ids) => ids.selectedOptionIds)
                        .filter((id): id is string => !!id)

                      const portionOptionsWithStatus = getPortionOptionsWithStatus({
                        portionID: portionId,
                        portionOptions,
                        selectedOptionIds,
                        maxCount,
                        disabledPortions: restaurantFeatures?.disabledPortions ?? [],
                        buttonType: definedButtonType,
                      })

                      return (
                        <RecommendedOptionsWrapper
                          key={`${optionSectionId}-wrapper`}
                          data-testid={`recommendation-option-section-${optionSectionId}`}
                        >
                          {optionSectionNameTranslated && (
                            <RecommendedOptionSectionName
                              variant='heading'
                              sizing='xxsmall'
                              weight='medium'
                              data-testid='size-options-title'
                            >
                              {optionSectionNameTranslated}
                            </RecommendedOptionSectionName>
                          )}
                          <SelectionBoxGroup
                            compact
                            key={optionSectionId}
                            portionId={portionId}
                            buttonType={definedButtonType}
                            maxCount={maxCount}
                            options={portionOptionsWithStatus}
                            optionSectionId={optionSectionId}
                            selectedIds={selectedOptionIds}
                            onChange={(optionId, decreaseQuantity, deselect) => {
                              if (definedButtonType === 'checkbox') {
                                handleOptionChangeCheckbox(
                                  recommendedPortion,
                                  optionSectionId,
                                  optionId,
                                  maxCount,
                                  decreaseQuantity,
                                  deselect
                                )
                              } else {
                                handleOptionChangeRadio(recommendedPortion, optionSectionId, optionId)
                              }
                            }}
                          />
                        </RecommendedOptionsWrapper>
                      )
                    })}
                    {!doNotShowTotalPrice && (
                      <RecommendedProductPrice>
                        <Text variant='body' sizing='medium' weight={!totalCoopMemberPrice ? 'bold' : 'regular'}>
                          {t('total')}:
                        </Text>
                        {sizeLabel && (
                          <SizeLabel
                            variant='body'
                            sizing='small'
                            data-testid={`recommendation-size-label-${portionId}`}
                          >
                            {sizeLabel}
                          </SizeLabel>
                        )}
                        <Text variant='body' sizing='medium' weight={!totalCoopMemberPrice ? 'bold' : 'regular'}>
                          {formatPrice({price: totalNormalPrice})}
                        </Text>
                        <CoopMemberPrice coopMemberPrice={totalCoopMemberPrice} />
                      </RecommendedProductPrice>
                    )}
                  </RecommendedPortionInfo>
                }
              />
            </RecommendedPortionWrapper>
          </OptionsWrapper>
        )
      })}
    </>
  )
}

export default ProductRecommendations
