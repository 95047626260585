import {gql} from '@apollo/client/core'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>}
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never}
export type Incremental<T> = T | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
}

export type Address = {
  __typename?: 'Address'
  additionalLocationInfo: LocalizedString
  country: LocalizedString
  countryEnum: Country
  municipality: LocalizedString
  municipalityID: Scalars['ID']['output']
  postOffice: LocalizedString
  street: LocalizedString
  zipCode: Scalars['String']['output']
}

export type BenefitsAndPurchases = {
  __typename?: 'BenefitsAndPurchases'
  periodBonusBenefitAmount?: Maybe<Scalars['Float']['output']>
  periodBonusPercent?: Maybe<Scalars['Float']['output']>
  periodId: Scalars['String']['output']
  periodPurchasesForNextLevel?: Maybe<Scalars['Float']['output']>
}

export type BundledOrders = {
  __typename?: 'BundledOrders'
  id: Scalars['ID']['output']
  orderItems: Array<Order>
  requestedAt: Scalars['String']['output']
  state: OrderState
}

export enum CallWaiterType {
  CALL_WAITER_WITH_BILL = 'CALL_WAITER_WITH_BILL',
  CALL_WAITER_WITH_BILL_SPLIT = 'CALL_WAITER_WITH_BILL_SPLIT',
  GENERIC = 'GENERIC',
}

export type Cart = {
  __typename?: 'Cart'
  id: Scalars['ID']['output']
  items: Array<CartItem>
  restaurantID: Scalars['ID']['output']
  total: TotalPrice
}

export type CartItem = {
  __typename?: 'CartItem'
  additionalInformation?: Maybe<Scalars['String']['output']>
  analytics?: Maybe<CartItemAnalytics>
  cartItemID: Scalars['ID']['output']
  menuID?: Maybe<Scalars['ID']['output']>
  portion: Portion
  quantity: Scalars['Int']['output']
  rowTotal: TotalPrice
  unitPrice: PortionPrice
}

export type CartItemAnalytics = {
  __typename?: 'CartItemAnalytics'
  creativeName?: Maybe<Scalars['String']['output']>
  index?: Maybe<Scalars['Int']['output']>
  itemListName?: Maybe<Scalars['String']['output']>
  promotionName?: Maybe<Scalars['String']['output']>
  relatedItemId?: Maybe<Scalars['String']['output']>
}

export type CartItemAnalyticsInput = {
  creativeName?: InputMaybe<Scalars['String']['input']>
  index?: InputMaybe<Scalars['Int']['input']>
  itemListName?: InputMaybe<Scalars['String']['input']>
  promotionName?: InputMaybe<Scalars['String']['input']>
  relatedItemId?: InputMaybe<Scalars['String']['input']>
}

export type CartItemInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  analytics?: InputMaybe<CartItemAnalyticsInput>
  cartItemID?: InputMaybe<Scalars['ID']['input']>
  menuID?: InputMaybe<Scalars['ID']['input']>
  portionID: Scalars['ID']['input']
  portionName?: InputMaybe<LocalizedStringInput>
  portionOptions?: InputMaybe<Array<PortionOptionInput>>
  quantity: Scalars['Int']['input']
}

export enum ChangeTableStateError {
  CHANGE_TABLE_STATE_DISABLED = 'CHANGE_TABLE_STATE_DISABLED',
}

export type ChangeTableStateInput = {
  state: TableStateInput
  token: Scalars['ID']['input']
}

export enum CommonError {
  DUPLICATE_ENTRY = 'DUPLICATE_ENTRY',
  GENERAL_ERROR = 'GENERAL_ERROR',
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type ContactInfo = {
  __typename?: 'ContactInfo'
  primaryPhone?: Maybe<PhoneNumber>
}

export enum ContainsAlcohol {
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  YES = 'YES',
}

export enum Country {
  ESTONIA = 'ESTONIA',
  FINLAND = 'FINLAND',
}

export type CreateOrUpdateTableInput = {
  isTestTable: Scalars['Boolean']['input']
  oldToken?: InputMaybe<Scalars['ID']['input']>
  restaurantID: Scalars['ID']['input']
  restaurantSelection?: InputMaybe<Array<RestaurantSelectionInput>>
  tableID: Scalars['ID']['input']
  tableType: TableType
  token: Scalars['ID']['input']
}

export type CreateOrderInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  cartID: Scalars['ID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  loyaltyProgram?: InputMaybe<LoyaltyProgram>
  nickName?: InputMaybe<Scalars['String']['input']>
  paymentMethod?: InputMaybe<PaymentMethod>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  pickupTime?: InputMaybe<PickupTimeInput>
  restaurantID: Scalars['ID']['input']
}

export type Customer = {
  __typename?: 'Customer'
  address?: Maybe<CustomerAddress>
  analyticsConsent: Scalars['Boolean']['output']
  benefitsAndPurchases?: Maybe<Array<BenefitsAndPurchases>>
  boaId?: Maybe<Scalars['ID']['output']>
  casId: Scalars['ID']['output']
  customerType: CustomerType
  email?: Maybe<Email>
  hasBonusCard: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name?: Maybe<Name>
  phone?: Maybe<Phone>
}

export type CustomerAccount = {
  __typename?: 'CustomerAccount'
  customer?: Maybe<Customer>
}

export type CustomerAddress = {
  __typename?: 'CustomerAddress'
  apartment?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  countryCode?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
}

export type CustomerLogin = {
  __typename?: 'CustomerLogin'
  loginUrl: Scalars['String']['output']
}

export type CustomerLoginInput = {
  loginLang?: InputMaybe<Scalars['String']['input']>
  loginType?: InputMaybe<LoginType>
  postLoginUrl?: InputMaybe<Scalars['String']['input']>
  redirectUrl?: InputMaybe<Scalars['String']['input']>
}

export enum CustomerType {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
}

export type DailyAvailability = {
  __typename?: 'DailyAvailability'
  timeRange?: Maybe<TimeRange>
  type: DailyAvailabilityType
}

export enum DailyAvailabilityType {
  AVAILABLE_DURING_OPENING_HOURS = 'AVAILABLE_DURING_OPENING_HOURS',
  AVAILABLE_DURING_TIME_RANGE = 'AVAILABLE_DURING_TIME_RANGE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export type DailyOpeningTime = {
  __typename?: 'DailyOpeningTime'
  ranges?: Maybe<Array<TimeRange>>
  type: OpeningTime
}

export type DateRange = {
  __typename?: 'DateRange'
  end?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['String']['output']>
}

export enum DeliveryMethod {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP',
}

export enum DietTag {
  CONTAINS_NUTS = 'CONTAINS_NUTS',
  GLUTEN_FREE = 'GLUTEN_FREE',
  GLUTEN_FREE_ON_REQUEST = 'GLUTEN_FREE_ON_REQUEST',
  LACTOSE_FREE = 'LACTOSE_FREE',
  LOW_CALORIE = 'LOW_CALORIE',
  LOW_LACTOSE = 'LOW_LACTOSE',
  MILK_FREE = 'MILK_FREE',
  ORGANIC = 'ORGANIC',
  SPICY_HOT = 'SPICY_HOT',
  VEGAN = 'VEGAN',
  VEGAN_ON_REQUEST = 'VEGAN_ON_REQUEST',
  VEGETARIAN = 'VEGETARIAN',
}

export type DisabledPortion = {
  __typename?: 'DisabledPortion'
  id: Scalars['ID']['output']
  portionOptions: Array<Scalars['ID']['output']>
}

export type DisabledPortionInput = {
  id: Scalars['ID']['input']
  portionOptions: Array<Scalars['ID']['input']>
}

export enum DoneBy {
  CUSTOMER = 'CUSTOMER',
  STAFF = 'STAFF',
}

export type Email = {
  __typename?: 'Email'
  email?: Maybe<Scalars['String']['output']>
  verified?: Maybe<Scalars['Boolean']['output']>
}

export enum GetCartError {
  CART_DOES_NOT_EXIST = 'CART_DOES_NOT_EXIST',
}

export type GetCartInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  restaurantID?: InputMaybe<Scalars['ID']['input']>
}

export type GetHotelInput = {
  hotelID: Scalars['ID']['input']
}

export type GetRestaurantFeaturesInput = {
  restaurantID: Scalars['ID']['input']
}

export type GetRestaurantInput = {
  restaurantID: Scalars['ID']['input']
}

export type GetStoredOrdersConnection = {
  __typename?: 'GetStoredOrdersConnection'
  items: Array<StoredOrder>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type GetStoredOrdersInput = {
  cartID?: InputMaybe<Scalars['ID']['input']>
  getType: GetStoredOrdersType
  limit?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
  restaurantID?: InputMaybe<Scalars['ID']['input']>
  tableID?: InputMaybe<Scalars['ID']['input']>
}

export enum GetStoredOrdersType {
  CART_ORDERS = 'CART_ORDERS',
  RESTAURANT_ORDERS = 'RESTAURANT_ORDERS',
  RESTAURANT_ORDERS_BY_CART_ID = 'RESTAURANT_ORDERS_BY_CART_ID',
  RESTAURANT_ORDERS_BY_TABLE_ID = 'RESTAURANT_ORDERS_BY_TABLE_ID',
}

export type GetTableByOpenTokenInput = {
  tableID: Scalars['ID']['input']
  token: Scalars['ID']['input']
}

export type GetTableInput = {
  token: Scalars['ID']['input']
}

export type GetTablesInput = {
  restaurantID: Scalars['ID']['input']
}

export type GetTransactionsConnection = {
  __typename?: 'GetTransactionsConnection'
  items: Array<Transaction>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type GetTransactionsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
  restaurantID: Scalars['ID']['input']
}

export type Hotel = {
  __typename?: 'Hotel'
  heroImage?: Maybe<Image>
  heroMobileImage?: Maybe<Image>
  id: Scalars['ID']['output']
  logoImage?: Maybe<Image>
  name: LocalizedString
  negativeLogoImage?: Maybe<Image>
}

export type Image = {
  __typename?: 'Image'
  text: LocalizedString
  url: Scalars['String']['output']
}

export type LocalizedString = {
  __typename?: 'LocalizedString'
  en: Scalars['String']['output']
  fi: Scalars['String']['output']
  sv: Scalars['String']['output']
}

export type LocalizedStringInput = {
  en: Scalars['String']['input']
  fi: Scalars['String']['input']
  sv: Scalars['String']['input']
}

export enum LoginType {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
}

export enum LoyaltyProgram {
  COOP_MEMBER = 'COOP_MEMBER',
  NONE = 'NONE',
  RADISSON_REWARDS = 'RADISSON_REWARDS',
  RADISSON_REWARDS_PREMIUM = 'RADISSON_REWARDS_PREMIUM',
  RADISSON_REWARDS_VIP = 'RADISSON_REWARDS_VIP',
  S_CARD = 'S_CARD',
  S_CARD_PREMIUM = 'S_CARD_PREMIUM',
}

export type Menu = {
  __typename?: 'Menu'
  availability: MenuAvailability
  id: Scalars['ID']['output']
  menuSections: Array<MenuSection>
  name: LocalizedString
  recommendations: MenuRecommendationsListings
}

export type MenuAvailability = {
  __typename?: 'MenuAvailability'
  tableTypeAvailabilities?: Maybe<Array<TableType>>
  weeklyAvailabilities?: Maybe<WeeklyAvailabilities>
}

/**
 * Collection of menu-specific recommendations
 *
 * If there are multiple menus with recommendations:
 * - show only one header: use the header of the recommendations of the first menu
 * - concatenate the recommended portions from all menus into one list
 */
export type MenuRecommendationsListings = {
  __typename?: 'MenuRecommendationsListings'
  /** Recommendations for the cart page */
  cart: RecommendationsListing
  /** Recommendations for the welcome dialog */
  welcome: RecommendationsListing
}

export type MenuSection = {
  __typename?: 'MenuSection'
  description?: Maybe<LocalizedString>
  name: LocalizedString
  portions: Array<Portion>
}

export enum ModifyOrdersAction {
  CREATE_ORDER = 'CREATE_ORDER',
  MOVE_TO_DONE = 'MOVE_TO_DONE',
  MOVE_TO_IN_PROGRESS = 'MOVE_TO_IN_PROGRESS',
  MOVE_TO_REQUESTED = 'MOVE_TO_REQUESTED',
}

export enum ModifyOrdersError {
  CART_DIDNT_CLEAR = 'CART_DIDNT_CLEAR',
  CART_NOT_FOUND = 'CART_NOT_FOUND',
  EMPTY_CART = 'EMPTY_CART',
  ORDERS_DISABLED = 'ORDERS_DISABLED',
  ORDERS_NOT_FOUND = 'ORDERS_NOT_FOUND',
}

export type ModifyOrdersInput = {
  action: ModifyOrdersAction
  bundleID?: InputMaybe<Scalars['ID']['input']>
  createOrderInput?: InputMaybe<CreateOrderInput>
  orderID?: InputMaybe<Scalars['ID']['input']>
  token: Scalars['ID']['input']
}

export type ModifyOrdersOutput = {
  __typename?: 'ModifyOrdersOutput'
  bundleID?: Maybe<Scalars['ID']['output']>
  bundledOrders?: Maybe<Array<BundledOrders>>
  doneOrders?: Maybe<BundledOrders>
  madeOrder?: Maybe<Order>
  orders?: Maybe<Orders>
  restaurantID: Scalars['ID']['output']
  token: Scalars['ID']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  changeTableState: Table
  createOrUpdateTable: Table
  customerLogin: CustomerLogin
  modifyOrders: ModifyOrdersOutput
  sendOrdersToTeho: SendOrdersToTehoOutput
  setRestaurantFeatures: RestaurantFeaturesOutput
  updateCart: Cart
  updateTime: TimeStamp
}

export type MutationChangeTableStateArgs = {
  input: ChangeTableStateInput
}

export type MutationCreateOrUpdateTableArgs = {
  input: CreateOrUpdateTableInput
}

export type MutationCustomerLoginArgs = {
  input?: InputMaybe<CustomerLoginInput>
}

export type MutationModifyOrdersArgs = {
  input: ModifyOrdersInput
}

export type MutationSendOrdersToTehoArgs = {
  input: SendOrdersToTehoInput
}

export type MutationSetRestaurantFeaturesArgs = {
  input: SetRestaurantFeaturesInput
}

export type MutationUpdateCartArgs = {
  input: UpdateCartInput
}

export type Name = {
  __typename?: 'Name'
  first?: Maybe<Scalars['String']['output']>
  last?: Maybe<Scalars['String']['output']>
}

export enum OpeningTime {
  CLOSED = 'CLOSED',
  OPEN_ONLY_BY_APPOINTMENT = 'OPEN_ONLY_BY_APPOINTMENT',
  TIMES = 'TIMES',
  TWENTYFOUR_HOURS = 'TWENTYFOUR_HOURS',
}

export type Order = {
  __typename?: 'Order'
  additionalInformation?: Maybe<Scalars['String']['output']>
  cart: Array<CartItem>
  cartID: Scalars['ID']['output']
  cartTotal: TotalPrice
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  loyaltyProgram?: Maybe<LoyaltyProgram>
  nickName?: Maybe<Scalars['String']['output']>
  paymentMethod?: Maybe<PaymentMethod>
  phoneNumber?: Maybe<Scalars['String']['output']>
  pickupTime?: Maybe<PickupTime>
  requestedAt: Scalars['String']['output']
}

export enum OrderState {
  DISABLED = 'DISABLED',
  IDLE = 'IDLE',
  IN_PROGRESS = 'IN_PROGRESS',
  REQUESTED = 'REQUESTED',
}

export type Orders = {
  __typename?: 'Orders'
  orderItems: Array<Order>
  requestedAt: Scalars['String']['output']
}

export enum PaymentMethod {
  CARD = 'CARD',
  ROOM_BILL = 'ROOM_BILL',
}

export type Phone = {
  __typename?: 'Phone'
  number?: Maybe<Scalars['String']['output']>
  verified?: Maybe<Scalars['Boolean']['output']>
}

export type PhoneNumber = {
  __typename?: 'PhoneNumber'
  description: LocalizedString
  number: Scalars['String']['output']
  price: LocalizedString
}

export type PickupTime = {
  __typename?: 'PickupTime'
  asap?: Maybe<Scalars['Boolean']['output']>
  time?: Maybe<Scalars['String']['output']>
}

export type PickupTimeInput = {
  asap?: InputMaybe<Scalars['Boolean']['input']>
  time?: InputMaybe<Scalars['String']['input']>
}

export type Portion = {
  __typename?: 'Portion'
  containsAlcohol: ContainsAlcohol
  description: LocalizedString
  diet: Array<DietTag>
  dishTags: Array<RestaurantPortionDishTag>
  drinkTags: Array<RestaurantPortionDrinkTag>
  id: Scalars['ID']['output']
  images: Array<Image>
  name: LocalizedString
  portionOptionSections: Array<Maybe<PortionOptionSection>>
  price?: Maybe<PortionPrice>
  recommendations: RecommendationsListing
  teho?: Maybe<TehoPortion>
  type: PortionType
}

export type PortionOption = {
  __typename?: 'PortionOption'
  diets: Array<DietTag>
  id: Scalars['ID']['output']
  isDefault: Scalars['Boolean']['output']
  label: LocalizedString
  price?: Maybe<PortionOptionPrice>
  teho?: Maybe<TehoPortionOption>
}

export type PortionOptionInput = {
  optionID: Scalars['ID']['input']
  optionSectionID: Scalars['ID']['input']
}

export type PortionOptionPrice = {
  __typename?: 'PortionOptionPrice'
  coopMember?: Maybe<Scalars['Int']['output']>
  normal: Scalars['Int']['output']
  type: PriceType
}

export type PortionOptionSection = {
  __typename?: 'PortionOptionSection'
  id: Scalars['ID']['output']
  maxCount?: Maybe<Scalars['Int']['output']>
  minCount?: Maybe<Scalars['Int']['output']>
  name?: Maybe<LocalizedString>
  portionOptions: Array<PortionOption>
  type: PortionOptionSectionType
}

export enum PortionOptionSectionType {
  DIETARY = 'DIETARY',
  GENERIC = 'GENERIC',
  HIDDEN = 'HIDDEN',
  ICE = 'ICE',
  RARITY = 'RARITY',
  SIZE = 'SIZE',
  STARTER_AS_MAIN_DISH = 'STARTER_AS_MAIN_DISH',
}

export type PortionPrice = {
  __typename?: 'PortionPrice'
  coopMember?: Maybe<Scalars['Int']['output']>
  normal: Scalars['Int']['output']
}

export enum PortionType {
  DISH = 'DISH',
  DRINK = 'DRINK',
}

export enum PriceType {
  ABSOLUTE = 'ABSOLUTE',
  ADDITIONAL = 'ADDITIONAL',
}

export type Query = {
  __typename?: 'Query'
  getCart: Cart
  getCustomerAccount: CustomerAccount
  getHotel: Hotel
  getRestaurant: Restaurant
  getRestaurantFeatures: RestaurantFeaturesOutput
  getStoredOrders: GetStoredOrdersConnection
  getTable: Table
  getTableByOpenToken: Table
  getTables: Array<Table>
  getTime: TimeStamp
  getTransactions: GetTransactionsConnection
  tehoGetTable: TehoGetTableOutput
}

export type QueryGetCartArgs = {
  input: GetCartInput
}

export type QueryGetHotelArgs = {
  input: GetHotelInput
}

export type QueryGetRestaurantArgs = {
  input: GetRestaurantInput
}

export type QueryGetRestaurantFeaturesArgs = {
  input: GetRestaurantFeaturesInput
}

export type QueryGetStoredOrdersArgs = {
  input: GetStoredOrdersInput
}

export type QueryGetTableArgs = {
  input: GetTableInput
}

export type QueryGetTableByOpenTokenArgs = {
  input: GetTableByOpenTokenInput
}

export type QueryGetTablesArgs = {
  input: GetTablesInput
}

export type QueryGetTransactionsArgs = {
  input: GetTransactionsInput
}

export type QueryTehoGetTableArgs = {
  input: TehoGetTableInput
}

export type RecommendationsListing = {
  __typename?: 'RecommendationsListing'
  header: LocalizedString
  /**
   * List of recommended portion IDs; can be dishes or drinks.
   *
   * The IDs refer to portions in the menu(s).
   *
   * Frontend must resolve which recommended portion IDs are found in the
   * currently available menus, and show only those. For example, the portion ID
   * might refer to a portion in a night menu which is not available at the
   * moment.
   */
  portionIDs: Array<Scalars['ID']['output']>
}

export type Restaurant = {
  __typename?: 'Restaurant'
  address: Address
  contact: ContactInfo
  costCenterID: Scalars['ID']['output']
  description: LocalizedString
  descriptionTitle: LocalizedString
  id: Scalars['ID']['output']
  isTestRestaurant: Scalars['Boolean']['output']
  links: RestaurantLinks
  menus: Array<Menu>
  name: LocalizedString
  visualContent: RestaurantVisualContent
  weekOpeningTimes: Array<WeekOpeningTime>
}

export type RestaurantFeatures = {
  __typename?: 'RestaurantFeatures'
  billRequest?: Maybe<Scalars['Boolean']['output']>
  disabledPortions: Array<DisabledPortion>
  kitchenClosed: Scalars['Boolean']['output']
  kitchenOpenAutomatic?: Maybe<Scalars['Boolean']['output']>
  lastCallBuffer: Scalars['Int']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  quickOrder: Scalars['Boolean']['output']
  roomService: RoomService
  rushHour: RushHour
  tableActions: Scalars['Boolean']['output']
  tableActionsAutomatic?: Maybe<Scalars['Boolean']['output']>
  tableAreas?: Maybe<TableAreas>
  tehoEnabled?: Maybe<Scalars['Boolean']['output']>
  waiterCall?: Maybe<Scalars['Boolean']['output']>
}

export type RestaurantFeaturesInput = {
  billRequest?: InputMaybe<Scalars['Boolean']['input']>
  disabledPortions?: InputMaybe<Array<DisabledPortionInput>>
  kitchenClosed?: InputMaybe<Scalars['Boolean']['input']>
  kitchenOpenAutomatic?: InputMaybe<Scalars['Boolean']['input']>
  lastCallBuffer?: InputMaybe<Scalars['Int']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  quickOrder?: InputMaybe<Scalars['Boolean']['input']>
  roomService?: InputMaybe<RoomServiceInput>
  rushHour?: InputMaybe<RushHourInput>
  tableActions?: InputMaybe<Scalars['Boolean']['input']>
  tableActionsAutomatic?: InputMaybe<Scalars['Boolean']['input']>
  tableAreas?: InputMaybe<TableAreasInput>
  tehoEnabled?: InputMaybe<Scalars['Boolean']['input']>
  waiterCall?: InputMaybe<Scalars['Boolean']['input']>
}

export type RestaurantFeaturesOutput = {
  __typename?: 'RestaurantFeaturesOutput'
  features: RestaurantFeatures
  restaurantID: Scalars['ID']['output']
}

export type RestaurantLinks = {
  __typename?: 'RestaurantLinks'
  homepageLocalized: LocalizedString
  menuLocalized: LocalizedString
}

export enum RestaurantPortionDishTag {
  BIRD = 'BIRD',
  BREAD_AND_PASTRY = 'BREAD_AND_PASTRY',
  BREAKFAST = 'BREAKFAST',
  BRUNCH = 'BRUNCH',
  BUFFET = 'BUFFET',
  BURGER_AND_HOTDOG = 'BURGER_AND_HOTDOG',
  CHILDRENS_FOOD = 'CHILDRENS_FOOD',
  DESSERT = 'DESSERT',
  FISH = 'FISH',
  GAME = 'GAME',
  LUNCH = 'LUNCH',
  MAIN = 'MAIN',
  MEAT_DISH = 'MEAT_DISH',
  PASTA_AND_RISOTTO = 'PASTA_AND_RISOTTO',
  PIZZA = 'PIZZA',
  SALAD = 'SALAD',
  SEAFOOD = 'SEAFOOD',
  SOUP = 'SOUP',
  STARTER = 'STARTER',
  TORTILLA_BURRITO_AND_TACO = 'TORTILLA_BURRITO_AND_TACO',
  VEGETARIAN = 'VEGETARIAN',
  WOK = 'WOK',
}

export enum RestaurantPortionDrinkTag {
  BEER = 'BEER',
  CIDER = 'CIDER',
  COCKTAIL = 'COCKTAIL',
  COCOA = 'COCOA',
  COFFEE = 'COFFEE',
  HOT_CHOCOLATE = 'HOT_CHOCOLATE',
  JUICE = 'JUICE',
  LONG_DRINK = 'LONG_DRINK',
  NON_ALCOHOLIC_BEER = 'NON_ALCOHOLIC_BEER',
  NON_ALCOHOLIC_CIDER = 'NON_ALCOHOLIC_CIDER',
  NON_ALCOHOLIC_GINGER_BEER = 'NON_ALCOHOLIC_GINGER_BEER',
  NON_ALCOHOLIC_WINE = 'NON_ALCOHOLIC_WINE',
  OTHER_WINE = 'OTHER_WINE',
  RED_WINE = 'RED_WINE',
  ROSE_WINE = 'ROSE_WINE',
  SOFT_DRINK = 'SOFT_DRINK',
  SPARKLING_WINE = 'SPARKLING_WINE',
  TEA = 'TEA',
  WHITE_WINE = 'WHITE_WINE',
}

export type RestaurantSelection = {
  __typename?: 'RestaurantSelection'
  restaurantID: Scalars['ID']['output']
  tableToken: Scalars['ID']['output']
}

export type RestaurantSelectionInput = {
  restaurantID: Scalars['ID']['input']
  tableToken: Scalars['ID']['input']
}

export type RestaurantVisualContent = {
  __typename?: 'RestaurantVisualContent'
  backgroundImage?: Maybe<Scalars['String']['output']>
  hero?: Maybe<Image>
  listingImage?: Maybe<Image>
  logo?: Maybe<Image>
}

export type RoomService = {
  __typename?: 'RoomService'
  delay: Scalars['Int']['output']
  /** @deprecated use enabled and deliveryMethods */
  deliveryEnabled: Scalars['Boolean']['output']
  deliveryFee: Scalars['Int']['output']
  deliveryMethods: Array<DeliveryMethod>
  enabled: Scalars['Boolean']['output']
  freeDeliveryThreshold?: Maybe<Scalars['Int']['output']>
  paymentMethods: Array<PaymentMethod>
  /** @deprecated use enabled and deliveryMethods */
  pickupEnabled: Scalars['Boolean']['output']
}

export type RoomServiceInput = {
  delay: Scalars['Int']['input']
  deliveryEnabled: Scalars['Boolean']['input']
  deliveryFee: Scalars['Int']['input']
  deliveryMethods: Array<DeliveryMethod>
  enabled: Scalars['Boolean']['input']
  freeDeliveryThreshold?: InputMaybe<Scalars['Int']['input']>
  paymentMethods: Array<PaymentMethod>
  pickupEnabled: Scalars['Boolean']['input']
}

export type RushHour = {
  __typename?: 'RushHour'
  delay: Scalars['Int']['output']
  enabled: Scalars['Boolean']['output']
  rushHourType: RushHourType
}

export type RushHourInput = {
  delay: Scalars['Int']['input']
  enabled: Scalars['Boolean']['input']
  rushHourType: RushHourType
}

export enum RushHourType {
  GENERAL = 'GENERAL',
  KITCHEN = 'KITCHEN',
}

export type SendOrdersToTehoInput = {
  orders: Array<SendOrdersToTehoOrderInput>
  restaurantID: Scalars['ID']['input']
  tableID: Scalars['ID']['input']
}

export type SendOrdersToTehoOrderInput = {
  items: Array<SendOrdersToTehoOrderItemInput>
  nickName?: InputMaybe<Scalars['String']['input']>
  orderID: Scalars['ID']['input']
}

export type SendOrdersToTehoOrderItemInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  portion: SendOrdersToTehoPortionInput
  quantity: Scalars['Int']['input']
}

export type SendOrdersToTehoOutput = {
  __typename?: 'SendOrdersToTehoOutput'
  portionsNotFound: Array<Scalars['String']['output']>
}

export type SendOrdersToTehoPortionInput = {
  itemNo: Scalars['String']['input']
  portionID: Scalars['ID']['input']
  portionOptions: Array<InputMaybe<SendOrdersToTehoPortionOptionInput>>
  unitOfMeasure: Scalars['String']['input']
  unitPrice: Scalars['Int']['input']
}

export type SendOrdersToTehoPortionOptionInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  itemNo?: InputMaybe<Scalars['String']['input']>
  portionOptionID: Scalars['String']['input']
  portionOptionValue: Scalars['String']['input']
  textSubcode?: InputMaybe<Scalars['String']['input']>
  type: PortionOptionSectionType
  unitOfMeasure?: InputMaybe<Scalars['String']['input']>
  unitPrice: Scalars['Int']['input']
}

export type SetRestaurantFeaturesInput = {
  features: RestaurantFeaturesInput
  restaurantID: Scalars['ID']['input']
}

export type StoredOrder = {
  __typename?: 'StoredOrder'
  additionalInformation?: Maybe<Scalars['String']['output']>
  cart: Array<CartItem>
  cartID: Scalars['ID']['output']
  cartTotal: TotalPrice
  completedAt: Scalars['String']['output']
  lastName?: Maybe<Scalars['String']['output']>
  loyaltyProgram?: Maybe<LoyaltyProgram>
  nickName?: Maybe<Scalars['String']['output']>
  orderID: Scalars['ID']['output']
  paymentMethod?: Maybe<PaymentMethod>
  phoneNumber?: Maybe<Scalars['String']['output']>
  pickupTime?: Maybe<PickupTime>
  requestedAt: Scalars['String']['output']
  restaurantID: Scalars['ID']['output']
  tableID: Scalars['ID']['output']
}

export type Subscription = {
  __typename?: 'Subscription'
  onChangeRestaurantFeatures?: Maybe<RestaurantFeaturesOutput>
  onChangeTableState?: Maybe<Table>
  onChangeTableStateStaff?: Maybe<Table>
  onChangeTime?: Maybe<TimeStamp>
  onModifyOrders?: Maybe<ModifyOrdersOutput>
  onModifyOrdersStaff?: Maybe<ModifyOrdersOutput>
  onUpdateCart?: Maybe<Cart>
}

export type SubscriptionOnChangeRestaurantFeaturesArgs = {
  restaurantID: Scalars['ID']['input']
}

export type SubscriptionOnChangeTableStateArgs = {
  token: Scalars['ID']['input']
}

export type SubscriptionOnChangeTableStateStaffArgs = {
  restaurantID: Scalars['ID']['input']
}

export type SubscriptionOnModifyOrdersArgs = {
  token: Scalars['ID']['input']
}

export type SubscriptionOnModifyOrdersStaffArgs = {
  restaurantID: Scalars['ID']['input']
}

export type SubscriptionOnUpdateCartArgs = {
  id: Scalars['ID']['input']
}

export type Table = {
  __typename?: 'Table'
  bundledOrders: Array<BundledOrders>
  isTestTable: Scalars['Boolean']['output']
  orders?: Maybe<Orders>
  restaurantID: Scalars['ID']['output']
  restaurantName: Scalars['String']['output']
  restaurantSelection: Array<RestaurantSelection>
  state: TableState
  tableID: Scalars['ID']['output']
  tableType: TableType
  token: Scalars['ID']['output']
}

export type TableArea = {
  __typename?: 'TableArea'
  id: Scalars['ID']['output']
  name: LocalizedString
  tables: Array<Scalars['ID']['output']>
}

export type TableAreaInput = {
  id: Scalars['ID']['input']
  name: LocalizedStringInput
  tables: Array<Scalars['ID']['input']>
}

export type TableAreas = {
  __typename?: 'TableAreas'
  areas: Array<TableArea>
  /** @deprecated not used */
  enabled: Scalars['Boolean']['output']
}

export type TableAreasInput = {
  areas: Array<TableAreaInput>
  /** @deprecated not used */
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type TableState = {
  __typename?: 'TableState'
  billRequest?: Maybe<Scalars['Boolean']['output']>
  callWaiterType: CallWaiterType
  lastUpdated?: Maybe<Scalars['String']['output']>
  quickOrder?: Maybe<Scalars['Boolean']['output']>
  requestedAt?: Maybe<Scalars['String']['output']>
  status: TableStatusType
  waiterCall?: Maybe<Scalars['Boolean']['output']>
}

export type TableStateInput = {
  billRequest?: InputMaybe<Scalars['Boolean']['input']>
  callWaiterType: CallWaiterType
  quickOrder?: InputMaybe<Scalars['Boolean']['input']>
  status: TableStatusType
  waiterCall?: InputMaybe<Scalars['Boolean']['input']>
}

export enum TableStatusType {
  DISABLED = 'DISABLED',
  IDLE = 'IDLE',
  IN_PROGRESS = 'IN_PROGRESS',
  REQUESTED = 'REQUESTED',
}

export enum TableType {
  BAR = 'BAR',
  GENERAL = 'GENERAL',
  MEETING = 'MEETING',
  OPEN_ROOM = 'OPEN_ROOM',
  OPEN_TABLE = 'OPEN_TABLE',
  PICKUP = 'PICKUP',
  REDIRECT = 'REDIRECT',
  RESTAURANT_SELECTION = 'RESTAURANT_SELECTION',
  ROOM = 'ROOM',
  SAUNA = 'SAUNA',
  SELF_SERVICE = 'SELF_SERVICE',
  TERRACE = 'TERRACE',
  TEST = 'TEST',
}

export enum TehoError {
  RATE_LIMIT = 'RATE_LIMIT',
}

export type TehoGetTableInput = {
  restaurantID: Scalars['ID']['input']
  tableID: Scalars['ID']['input']
}

export type TehoGetTableOutput = {
  __typename?: 'TehoGetTableOutput'
  status: TehoTableStatus
  tableID: Scalars['ID']['output']
}

export type TehoPortion = {
  __typename?: 'TehoPortion'
  description: Scalars['String']['output']
  itemNo: Scalars['ID']['output']
  receiptDescription: Scalars['String']['output']
  unitOfMeasure?: Maybe<Scalars['String']['output']>
}

export type TehoPortionOption = {
  __typename?: 'TehoPortionOption'
  amount: Scalars['Float']['output']
  description?: Maybe<Scalars['String']['output']>
  itemNo?: Maybe<Scalars['ID']['output']>
  textSubcode?: Maybe<Scalars['String']['output']>
  unitOfMeasure?: Maybe<Scalars['String']['output']>
}

export enum TehoTableStatus {
  idle = 'idle',
  receiptOpen = 'receiptOpen',
}

export type TimeRange = {
  __typename?: 'TimeRange'
  end: Scalars['String']['output']
  endNextDay: Scalars['Boolean']['output']
  start: Scalars['String']['output']
}

export type TimeStamp = {
  __typename?: 'TimeStamp'
  builtOn: Scalars['String']['output']
  now: Scalars['String']['output']
}

export type TotalPrice = {
  __typename?: 'TotalPrice'
  coopMember?: Maybe<Scalars['Int']['output']>
  normal: Scalars['Int']['output']
}

export type Transaction = {
  __typename?: 'Transaction'
  callWaiterType?: Maybe<CallWaiterType>
  completedAt: Scalars['String']['output']
  costCenterID: Scalars['ID']['output']
  doneBy: DoneBy
  id: Scalars['ID']['output']
  orders: Array<TransactionOrder>
  requestedAt?: Maybe<Scalars['String']['output']>
  restaurantID: Scalars['ID']['output']
  tableID: Scalars['ID']['output']
  tableType: TableType
  type?: Maybe<TransactionType>
}

export type TransactionOrder = {
  __typename?: 'TransactionOrder'
  additionalInformation?: Maybe<Scalars['String']['output']>
  cart: Array<CartItem>
  cartID: Scalars['ID']['output']
  cartTotal: TotalPrice
  lastName?: Maybe<Scalars['String']['output']>
  loyaltyProgram?: Maybe<LoyaltyProgram>
  nickName?: Maybe<Scalars['String']['output']>
  paymentMethod?: Maybe<PaymentMethod>
  phoneNumber?: Maybe<Scalars['String']['output']>
  pickupTime?: Maybe<PickupTime>
}

export enum TransactionType {
  CALL_WAITER = 'CALL_WAITER',
  ORDER = 'ORDER',
}

export enum UpdateCartAction {
  ADD = 'ADD',
  CLEAR = 'CLEAR',
  CLEAR_COMMENT = 'CLEAR_COMMENT',
  CLEAR_ITEM = 'CLEAR_ITEM',
  SUBTRACT = 'SUBTRACT',
  UPDATE = 'UPDATE',
}

export enum UpdateCartError {
  CART_NOT_FOUND = 'CART_NOT_FOUND',
  INVALID_QUANTITY = 'INVALID_QUANTITY',
  ITEM_NOT_FOUND = 'ITEM_NOT_FOUND',
  NO_ITEMS_PROVIDED = 'NO_ITEMS_PROVIDED',
}

export type UpdateCartInput = {
  action: UpdateCartAction
  id: Scalars['ID']['input']
  items?: InputMaybe<Array<InputMaybe<CartItemInput>>>
}

export type WeekOpeningTime = {
  __typename?: 'WeekOpeningTime'
  date: DateRange
  kitchenOpeningTimes?: Maybe<WeeklyOpeningTimes>
  openingTimes: WeeklyOpeningTimes
  weekNumber: Scalars['Int']['output']
}

export type WeeklyAvailabilities = {
  __typename?: 'WeeklyAvailabilities'
  friday: DailyAvailability
  monday: DailyAvailability
  saturday: DailyAvailability
  sunday: DailyAvailability
  thursday: DailyAvailability
  tuesday: DailyAvailability
  wednesday: DailyAvailability
}

export type WeeklyOpeningTimes = {
  __typename?: 'WeeklyOpeningTimes'
  friday: DailyOpeningTime
  monday: DailyOpeningTime
  saturday: DailyOpeningTime
  sunday: DailyOpeningTime
  thursday: DailyOpeningTime
  tuesday: DailyOpeningTime
  wednesday: DailyOpeningTime
}

export type LocalesFragment = {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}

export type ImageFieldsFragment = {
  __typename?: 'Image'
  url: string
  text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
}

export type RecommendationsListingFieldsFragment = {
  __typename?: 'RecommendationsListing'
  portionIDs: Array<string>
  header: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
}

export type PortionFieldsFragment = {
  __typename?: 'Portion'
  id: string
  type: PortionType
  containsAlcohol: ContainsAlcohol
  diet: Array<DietTag>
  dishTags: Array<RestaurantPortionDishTag>
  drinkTags: Array<RestaurantPortionDrinkTag>
  price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
  name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
  description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
  teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
  images: Array<{
    __typename?: 'Image'
    url: string
    text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
  }>
  portionOptionSections: Array<{
    __typename?: 'PortionOptionSection'
    id: string
    type: PortionOptionSectionType
    maxCount?: number | null
    minCount?: number | null
    name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
    portionOptions: Array<{
      __typename?: 'PortionOption'
      id: string
      isDefault: boolean
      diets: Array<DietTag>
      price?: {__typename?: 'PortionOptionPrice'; type: PriceType; normal: number; coopMember?: number | null} | null
      teho?: {
        __typename?: 'TehoPortionOption'
        itemNo?: string | null
        amount: number
        unitOfMeasure?: string | null
        textSubcode?: string | null
      } | null
      label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    }>
  } | null>
  recommendations: {
    __typename?: 'RecommendationsListing'
    portionIDs: Array<string>
    header: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
  }
}

export type TimeRangeFieldsFragment = {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}

export type MenuAvailabilityFieldsFragment = {
  __typename?: 'MenuAvailability'
  tableTypeAvailabilities?: Array<TableType> | null
  weeklyAvailabilities?: {
    __typename?: 'WeeklyAvailabilities'
    monday: {
      __typename?: 'DailyAvailability'
      type: DailyAvailabilityType
      timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
    }
    tuesday: {
      __typename?: 'DailyAvailability'
      type: DailyAvailabilityType
      timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
    }
    wednesday: {
      __typename?: 'DailyAvailability'
      type: DailyAvailabilityType
      timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
    }
    thursday: {
      __typename?: 'DailyAvailability'
      type: DailyAvailabilityType
      timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
    }
    friday: {
      __typename?: 'DailyAvailability'
      type: DailyAvailabilityType
      timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
    }
    saturday: {
      __typename?: 'DailyAvailability'
      type: DailyAvailabilityType
      timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
    }
    sunday: {
      __typename?: 'DailyAvailability'
      type: DailyAvailabilityType
      timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
    }
  } | null
}

export type PhoneNumberFieldsFragment = {
  __typename?: 'PhoneNumber'
  number: string
  description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
  price: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
}

export type DailyOpeningTimeFieldsFragment = {
  __typename?: 'DailyOpeningTime'
  type: OpeningTime
  ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
}

export type WeeklyOpeningTimesFieldsFragment = {
  __typename?: 'WeeklyOpeningTimes'
  monday: {
    __typename?: 'DailyOpeningTime'
    type: OpeningTime
    ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
  }
  tuesday: {
    __typename?: 'DailyOpeningTime'
    type: OpeningTime
    ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
  }
  wednesday: {
    __typename?: 'DailyOpeningTime'
    type: OpeningTime
    ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
  }
  thursday: {
    __typename?: 'DailyOpeningTime'
    type: OpeningTime
    ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
  }
  friday: {
    __typename?: 'DailyOpeningTime'
    type: OpeningTime
    ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
  }
  saturday: {
    __typename?: 'DailyOpeningTime'
    type: OpeningTime
    ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
  }
  sunday: {
    __typename?: 'DailyOpeningTime'
    type: OpeningTime
    ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
  }
}

export type MenuRecommendationsListingsFieldsFragment = {
  __typename?: 'MenuRecommendationsListings'
  welcome: {
    __typename?: 'RecommendationsListing'
    portionIDs: Array<string>
    header: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
  }
  cart: {
    __typename?: 'RecommendationsListing'
    portionIDs: Array<string>
    header: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
  }
}

export type CartItemFieldsFragment = {
  __typename?: 'CartItem'
  cartItemID: string
  menuID?: string | null
  quantity: number
  additionalInformation?: string | null
  portion: {
    __typename?: 'Portion'
    id: string
    type: PortionType
    containsAlcohol: ContainsAlcohol
    diet: Array<DietTag>
    dishTags: Array<RestaurantPortionDishTag>
    drinkTags: Array<RestaurantPortionDrinkTag>
    price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
    name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
    portionOptionSections: Array<{
      __typename?: 'PortionOptionSection'
      id: string
      type: PortionOptionSectionType
      portionOptions: Array<{
        __typename?: 'PortionOption'
        isDefault: boolean
        id: string
        diets: Array<DietTag>
        price?: {__typename?: 'PortionOptionPrice'; type: PriceType; coopMember?: number | null; normal: number} | null
        teho?: {
          __typename?: 'TehoPortionOption'
          itemNo?: string | null
          amount: number
          unitOfMeasure?: string | null
          textSubcode?: string | null
        } | null
        label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      }>
      name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
    } | null>
  }
  unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
  rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
  analytics?: {
    __typename?: 'CartItemAnalytics'
    index?: number | null
    itemListName?: string | null
    creativeName?: string | null
    promotionName?: string | null
    relatedItemId?: string | null
  } | null
}

export type OrderFieldsFragment = {
  __typename?: 'Order'
  requestedAt: string
  cartID: string
  nickName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  loyaltyProgram?: LoyaltyProgram | null
  paymentMethod?: PaymentMethod | null
  id: string
  pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
  cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
  cart: Array<{
    __typename?: 'CartItem'
    cartItemID: string
    menuID?: string | null
    quantity: number
    additionalInformation?: string | null
    portion: {
      __typename?: 'Portion'
      id: string
      type: PortionType
      containsAlcohol: ContainsAlcohol
      diet: Array<DietTag>
      dishTags: Array<RestaurantPortionDishTag>
      drinkTags: Array<RestaurantPortionDrinkTag>
      price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
      name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
      portionOptionSections: Array<{
        __typename?: 'PortionOptionSection'
        id: string
        type: PortionOptionSectionType
        portionOptions: Array<{
          __typename?: 'PortionOption'
          isDefault: boolean
          id: string
          diets: Array<DietTag>
          price?: {
            __typename?: 'PortionOptionPrice'
            type: PriceType
            coopMember?: number | null
            normal: number
          } | null
          teho?: {
            __typename?: 'TehoPortionOption'
            itemNo?: string | null
            amount: number
            unitOfMeasure?: string | null
            textSubcode?: string | null
          } | null
          label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        }>
        name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
      } | null>
    }
    unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
    rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
    analytics?: {
      __typename?: 'CartItemAnalytics'
      index?: number | null
      itemListName?: string | null
      creativeName?: string | null
      promotionName?: string | null
      relatedItemId?: string | null
    } | null
  }>
}

export type CartFieldsFragment = {
  __typename?: 'Cart'
  id: string
  restaurantID: string
  items: Array<{
    __typename?: 'CartItem'
    cartItemID: string
    menuID?: string | null
    quantity: number
    additionalInformation?: string | null
    portion: {
      __typename?: 'Portion'
      id: string
      type: PortionType
      containsAlcohol: ContainsAlcohol
      diet: Array<DietTag>
      dishTags: Array<RestaurantPortionDishTag>
      drinkTags: Array<RestaurantPortionDrinkTag>
      price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
      name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
      portionOptionSections: Array<{
        __typename?: 'PortionOptionSection'
        id: string
        type: PortionOptionSectionType
        portionOptions: Array<{
          __typename?: 'PortionOption'
          isDefault: boolean
          id: string
          diets: Array<DietTag>
          price?: {
            __typename?: 'PortionOptionPrice'
            type: PriceType
            coopMember?: number | null
            normal: number
          } | null
          teho?: {
            __typename?: 'TehoPortionOption'
            itemNo?: string | null
            amount: number
            unitOfMeasure?: string | null
            textSubcode?: string | null
          } | null
          label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        }>
        name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
      } | null>
    }
    unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
    rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
    analytics?: {
      __typename?: 'CartItemAnalytics'
      index?: number | null
      itemListName?: string | null
      creativeName?: string | null
      promotionName?: string | null
      relatedItemId?: string | null
    } | null
  }>
  total: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
}

export type StoredOrderFieldsFragment = {
  __typename?: 'StoredOrder'
  orderID: string
  cartID: string
  nickName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  loyaltyProgram?: LoyaltyProgram | null
  paymentMethod?: PaymentMethod | null
  requestedAt: string
  pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
  cart: Array<{
    __typename?: 'CartItem'
    cartItemID: string
    menuID?: string | null
    quantity: number
    additionalInformation?: string | null
    portion: {
      __typename?: 'Portion'
      id: string
      type: PortionType
      containsAlcohol: ContainsAlcohol
      diet: Array<DietTag>
      dishTags: Array<RestaurantPortionDishTag>
      drinkTags: Array<RestaurantPortionDrinkTag>
      price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
      name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
      portionOptionSections: Array<{
        __typename?: 'PortionOptionSection'
        id: string
        type: PortionOptionSectionType
        portionOptions: Array<{
          __typename?: 'PortionOption'
          isDefault: boolean
          id: string
          diets: Array<DietTag>
          price?: {
            __typename?: 'PortionOptionPrice'
            type: PriceType
            coopMember?: number | null
            normal: number
          } | null
          teho?: {
            __typename?: 'TehoPortionOption'
            itemNo?: string | null
            amount: number
            unitOfMeasure?: string | null
            textSubcode?: string | null
          } | null
          label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        }>
        name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
      } | null>
    }
    unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
    rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
    analytics?: {
      __typename?: 'CartItemAnalytics'
      index?: number | null
      itemListName?: string | null
      creativeName?: string | null
      promotionName?: string | null
      relatedItemId?: string | null
    } | null
  }>
  cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
}

export type StoredOrdersConnectionFragment = {
  __typename?: 'GetStoredOrdersConnection'
  nextToken?: string | null
  items: Array<{
    __typename?: 'StoredOrder'
    orderID: string
    cartID: string
    nickName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    loyaltyProgram?: LoyaltyProgram | null
    paymentMethod?: PaymentMethod | null
    requestedAt: string
    pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
    cart: Array<{
      __typename?: 'CartItem'
      cartItemID: string
      menuID?: string | null
      quantity: number
      additionalInformation?: string | null
      portion: {
        __typename?: 'Portion'
        id: string
        type: PortionType
        containsAlcohol: ContainsAlcohol
        diet: Array<DietTag>
        dishTags: Array<RestaurantPortionDishTag>
        drinkTags: Array<RestaurantPortionDrinkTag>
        price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
        name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
        portionOptionSections: Array<{
          __typename?: 'PortionOptionSection'
          id: string
          type: PortionOptionSectionType
          portionOptions: Array<{
            __typename?: 'PortionOption'
            isDefault: boolean
            id: string
            diets: Array<DietTag>
            price?: {
              __typename?: 'PortionOptionPrice'
              type: PriceType
              coopMember?: number | null
              normal: number
            } | null
            teho?: {
              __typename?: 'TehoPortionOption'
              itemNo?: string | null
              amount: number
              unitOfMeasure?: string | null
              textSubcode?: string | null
            } | null
            label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          }>
          name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
        } | null>
      }
      unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
      rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      analytics?: {
        __typename?: 'CartItemAnalytics'
        index?: number | null
        itemListName?: string | null
        creativeName?: string | null
        promotionName?: string | null
        relatedItemId?: string | null
      } | null
    }>
    cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
  }>
}

export type CustomerFieldsFragment = {
  __typename?: 'Customer'
  id: string
  casId: string
  boaId?: string | null
  customerType: CustomerType
  analyticsConsent: boolean
  hasBonusCard: boolean
  benefitsAndPurchases?: Array<{
    __typename?: 'BenefitsAndPurchases'
    periodId: string
    periodBonusPercent?: number | null
    periodBonusBenefitAmount?: number | null
    periodPurchasesForNextLevel?: number | null
  }> | null
  email?: {__typename?: 'Email'; email?: string | null; verified?: boolean | null} | null
  name?: {__typename?: 'Name'; first?: string | null; last?: string | null} | null
  phone?: {__typename?: 'Phone'; number?: string | null; verified?: boolean | null} | null
  address?: {
    __typename?: 'CustomerAddress'
    street?: string | null
    apartment?: string | null
    code?: string | null
    city?: string | null
    countryCode?: string | null
  } | null
}

export type CustomerAccountFieldsFragment = {
  __typename?: 'CustomerAccount'
  customer?: {
    __typename?: 'Customer'
    id: string
    casId: string
    boaId?: string | null
    customerType: CustomerType
    analyticsConsent: boolean
    hasBonusCard: boolean
    benefitsAndPurchases?: Array<{
      __typename?: 'BenefitsAndPurchases'
      periodId: string
      periodBonusPercent?: number | null
      periodBonusBenefitAmount?: number | null
      periodPurchasesForNextLevel?: number | null
    }> | null
    email?: {__typename?: 'Email'; email?: string | null; verified?: boolean | null} | null
    name?: {__typename?: 'Name'; first?: string | null; last?: string | null} | null
    phone?: {__typename?: 'Phone'; number?: string | null; verified?: boolean | null} | null
    address?: {
      __typename?: 'CustomerAddress'
      street?: string | null
      apartment?: string | null
      code?: string | null
      city?: string | null
      countryCode?: string | null
    } | null
  } | null
}

export type ChangeTableStateMutationVariables = Exact<{
  input: ChangeTableStateInput
}>

export type ChangeTableStateMutation = {
  __typename?: 'Mutation'
  changeTableState: {
    __typename?: 'Table'
    token: string
    tableID: string
    restaurantID: string
    state: {
      __typename?: 'TableState'
      callWaiterType: CallWaiterType
      status: TableStatusType
      lastUpdated?: string | null
      requestedAt?: string | null
      waiterCall?: boolean | null
      quickOrder?: boolean | null
      billRequest?: boolean | null
    }
  }
}

export type CustomerLoginMutationVariables = Exact<{
  input?: InputMaybe<CustomerLoginInput>
}>

export type CustomerLoginMutation = {
  __typename?: 'Mutation'
  customerLogin: {__typename?: 'CustomerLogin'; loginUrl: string}
}

export type ModifyOrdersMutationVariables = Exact<{
  input: ModifyOrdersInput
}>

export type ModifyOrdersMutation = {
  __typename?: 'Mutation'
  modifyOrders: {
    __typename?: 'ModifyOrdersOutput'
    token: string
    restaurantID: string
    bundleID?: string | null
    bundledOrders?: Array<{
      __typename?: 'BundledOrders'
      id: string
      state: OrderState
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          menuID?: string | null
          quantity: number
          additionalInformation?: string | null
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
            name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              portionOptions: Array<{
                __typename?: 'PortionOption'
                isDefault: boolean
                id: string
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  coopMember?: number | null
                  normal: number
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
              }>
              name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            } | null>
          }
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          analytics?: {
            __typename?: 'CartItemAnalytics'
            index?: number | null
            itemListName?: string | null
            creativeName?: string | null
            promotionName?: string | null
            relatedItemId?: string | null
          } | null
        }>
      }>
    }> | null
    orders?: {
      __typename?: 'Orders'
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          menuID?: string | null
          quantity: number
          additionalInformation?: string | null
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
            name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              portionOptions: Array<{
                __typename?: 'PortionOption'
                isDefault: boolean
                id: string
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  coopMember?: number | null
                  normal: number
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
              }>
              name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            } | null>
          }
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          analytics?: {
            __typename?: 'CartItemAnalytics'
            index?: number | null
            itemListName?: string | null
            creativeName?: string | null
            promotionName?: string | null
            relatedItemId?: string | null
          } | null
        }>
      }>
    } | null
    madeOrder?: {
      __typename?: 'Order'
      requestedAt: string
      cartID: string
      nickName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      loyaltyProgram?: LoyaltyProgram | null
      paymentMethod?: PaymentMethod | null
      id: string
      pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
      cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      cart: Array<{
        __typename?: 'CartItem'
        cartItemID: string
        menuID?: string | null
        quantity: number
        additionalInformation?: string | null
        portion: {
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
          name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            portionOptions: Array<{
              __typename?: 'PortionOption'
              isDefault: boolean
              id: string
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                coopMember?: number | null
                normal: number
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            }>
            name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
          } | null>
        }
        unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
        rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        analytics?: {
          __typename?: 'CartItemAnalytics'
          index?: number | null
          itemListName?: string | null
          creativeName?: string | null
          promotionName?: string | null
          relatedItemId?: string | null
        } | null
      }>
    } | null
  }
}

export type UpdateCartMutationVariables = Exact<{
  input: UpdateCartInput
}>

export type UpdateCartMutation = {
  __typename?: 'Mutation'
  updateCart: {
    __typename?: 'Cart'
    id: string
    restaurantID: string
    items: Array<{
      __typename?: 'CartItem'
      cartItemID: string
      menuID?: string | null
      quantity: number
      additionalInformation?: string | null
      portion: {
        __typename?: 'Portion'
        id: string
        type: PortionType
        containsAlcohol: ContainsAlcohol
        diet: Array<DietTag>
        dishTags: Array<RestaurantPortionDishTag>
        drinkTags: Array<RestaurantPortionDrinkTag>
        price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
        name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
        portionOptionSections: Array<{
          __typename?: 'PortionOptionSection'
          id: string
          type: PortionOptionSectionType
          portionOptions: Array<{
            __typename?: 'PortionOption'
            isDefault: boolean
            id: string
            diets: Array<DietTag>
            price?: {
              __typename?: 'PortionOptionPrice'
              type: PriceType
              coopMember?: number | null
              normal: number
            } | null
            teho?: {
              __typename?: 'TehoPortionOption'
              itemNo?: string | null
              amount: number
              unitOfMeasure?: string | null
              textSubcode?: string | null
            } | null
            label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          }>
          name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
        } | null>
      }
      unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
      rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      analytics?: {
        __typename?: 'CartItemAnalytics'
        index?: number | null
        itemListName?: string | null
        creativeName?: string | null
        promotionName?: string | null
        relatedItemId?: string | null
      } | null
    }>
    total: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
  }
}

export type GetTableQueryVariables = Exact<{
  input: GetTableInput
}>

export type GetTableQuery = {
  __typename?: 'Query'
  getTable: {
    __typename?: 'Table'
    token: string
    tableID: string
    tableType: TableType
    isTestTable: boolean
    restaurantID: string
    state: {
      __typename?: 'TableState'
      callWaiterType: CallWaiterType
      status: TableStatusType
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder?: boolean | null
      lastUpdated?: string | null
      requestedAt?: string | null
    }
    orders?: {
      __typename?: 'Orders'
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          menuID?: string | null
          quantity: number
          additionalInformation?: string | null
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
            name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              portionOptions: Array<{
                __typename?: 'PortionOption'
                isDefault: boolean
                id: string
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  coopMember?: number | null
                  normal: number
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
              }>
              name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            } | null>
          }
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          analytics?: {
            __typename?: 'CartItemAnalytics'
            index?: number | null
            itemListName?: string | null
            creativeName?: string | null
            promotionName?: string | null
            relatedItemId?: string | null
          } | null
        }>
      }>
    } | null
    bundledOrders: Array<{
      __typename?: 'BundledOrders'
      id: string
      state: OrderState
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          menuID?: string | null
          quantity: number
          additionalInformation?: string | null
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
            name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              portionOptions: Array<{
                __typename?: 'PortionOption'
                isDefault: boolean
                id: string
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  coopMember?: number | null
                  normal: number
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
              }>
              name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            } | null>
          }
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          analytics?: {
            __typename?: 'CartItemAnalytics'
            index?: number | null
            itemListName?: string | null
            creativeName?: string | null
            promotionName?: string | null
            relatedItemId?: string | null
          } | null
        }>
      }>
    }>
    restaurantSelection: Array<{__typename?: 'RestaurantSelection'; restaurantID: string; tableToken: string}>
  }
}

export type GetTableByOpenTokenQueryVariables = Exact<{
  token: Scalars['ID']['input']
  tableID: Scalars['ID']['input']
}>

export type GetTableByOpenTokenQuery = {
  __typename?: 'Query'
  getTableByOpenToken: {
    __typename?: 'Table'
    token: string
    restaurantID: string
    tableID: string
    tableType: TableType
  }
}

export type GetRestaurantFeaturesQueryVariables = Exact<{
  input: GetRestaurantFeaturesInput
}>

export type GetRestaurantFeaturesQuery = {
  __typename?: 'Query'
  getRestaurantFeatures: {
    __typename?: 'RestaurantFeaturesOutput'
    restaurantID: string
    features: {
      __typename?: 'RestaurantFeatures'
      tableActions: boolean
      tableActionsAutomatic?: boolean | null
      lastCallBuffer: number
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder: boolean
      kitchenClosed: boolean
      kitchenOpenAutomatic?: boolean | null
      phoneNumber?: string | null
      rushHour: {__typename?: 'RushHour'; enabled: boolean; rushHourType: RushHourType; delay: number}
      roomService: {
        __typename?: 'RoomService'
        enabled: boolean
        deliveryMethods: Array<DeliveryMethod>
        deliveryFee: number
        freeDeliveryThreshold?: number | null
        delay: number
        paymentMethods: Array<PaymentMethod>
      }
      disabledPortions: Array<{__typename?: 'DisabledPortion'; id: string; portionOptions: Array<string>}>
    }
  }
}

export type GetRestaurantQueryVariables = Exact<{
  input: GetRestaurantInput
  withMenus?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetRestaurantQuery = {
  __typename?: 'Query'
  getRestaurant: {
    __typename?: 'Restaurant'
    id: string
    costCenterID: string
    isTestRestaurant: boolean
    name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    descriptionTitle: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    address: {
      __typename?: 'Address'
      municipality: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    }
    contact: {
      __typename?: 'ContactInfo'
      primaryPhone?: {
        __typename?: 'PhoneNumber'
        number: string
        description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        price: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      } | null
    }
    weekOpeningTimes: Array<{
      __typename?: 'WeekOpeningTime'
      weekNumber: number
      date: {__typename?: 'DateRange'; start?: string | null; end?: string | null}
      openingTimes: {
        __typename?: 'WeeklyOpeningTimes'
        monday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        tuesday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        wednesday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        thursday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        friday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        saturday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        sunday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
      }
      kitchenOpeningTimes?: {
        __typename?: 'WeeklyOpeningTimes'
        monday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        tuesday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        wednesday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        thursday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        friday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        saturday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        sunday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
      } | null
    }>
    links: {
      __typename?: 'RestaurantLinks'
      homepageLocalized: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      menuLocalized: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    }
    visualContent: {
      __typename?: 'RestaurantVisualContent'
      backgroundImage?: string | null
      logo?: {
        __typename?: 'Image'
        url: string
        text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      } | null
      hero?: {
        __typename?: 'Image'
        url: string
        text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      } | null
    }
    menus?: Array<{
      __typename?: 'Menu'
      id: string
      name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      menuSections: Array<{
        __typename?: 'MenuSection'
        name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        description?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
        portions: Array<{
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
          name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          images: Array<{
            __typename?: 'Image'
            url: string
            text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          }>
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            maxCount?: number | null
            minCount?: number | null
            name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            portionOptions: Array<{
              __typename?: 'PortionOption'
              id: string
              isDefault: boolean
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                normal: number
                coopMember?: number | null
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            }>
          } | null>
          recommendations: {
            __typename?: 'RecommendationsListing'
            portionIDs: Array<string>
            header: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          }
        }>
      }>
      availability: {
        __typename?: 'MenuAvailability'
        tableTypeAvailabilities?: Array<TableType> | null
        weeklyAvailabilities?: {
          __typename?: 'WeeklyAvailabilities'
          monday: {
            __typename?: 'DailyAvailability'
            type: DailyAvailabilityType
            timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
          }
          tuesday: {
            __typename?: 'DailyAvailability'
            type: DailyAvailabilityType
            timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
          }
          wednesday: {
            __typename?: 'DailyAvailability'
            type: DailyAvailabilityType
            timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
          }
          thursday: {
            __typename?: 'DailyAvailability'
            type: DailyAvailabilityType
            timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
          }
          friday: {
            __typename?: 'DailyAvailability'
            type: DailyAvailabilityType
            timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
          }
          saturday: {
            __typename?: 'DailyAvailability'
            type: DailyAvailabilityType
            timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
          }
          sunday: {
            __typename?: 'DailyAvailability'
            type: DailyAvailabilityType
            timeRange?: {__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean} | null
          }
        } | null
      }
      recommendations: {
        __typename?: 'MenuRecommendationsListings'
        welcome: {
          __typename?: 'RecommendationsListing'
          portionIDs: Array<string>
          header: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        }
        cart: {
          __typename?: 'RecommendationsListing'
          portionIDs: Array<string>
          header: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        }
      }
    }>
  }
}

export type GetRestaurantListItemQueryVariables = Exact<{
  input: GetRestaurantInput
}>

export type GetRestaurantListItemQuery = {
  __typename?: 'Query'
  getRestaurant: {
    __typename?: 'Restaurant'
    id: string
    name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    visualContent: {
      __typename?: 'RestaurantVisualContent'
      backgroundImage?: string | null
      logo?: {
        __typename?: 'Image'
        url: string
        text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      } | null
      hero?: {
        __typename?: 'Image'
        url: string
        text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      } | null
      listingImage?: {
        __typename?: 'Image'
        url: string
        text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      } | null
    }
  }
}

export type GetHotelQueryVariables = Exact<{
  input: GetHotelInput
}>

export type GetHotelQuery = {
  __typename?: 'Query'
  getHotel: {
    __typename?: 'Hotel'
    id: string
    name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    logoImage?: {
      __typename?: 'Image'
      url: string
      text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    } | null
    negativeLogoImage?: {
      __typename?: 'Image'
      url: string
      text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    } | null
    heroImage?: {
      __typename?: 'Image'
      url: string
      text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    } | null
    heroMobileImage?: {
      __typename?: 'Image'
      url: string
      text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
    } | null
  }
}

export type GetCartQueryVariables = Exact<{
  input: GetCartInput
}>

export type GetCartQuery = {
  __typename?: 'Query'
  getCart: {
    __typename?: 'Cart'
    id: string
    restaurantID: string
    items: Array<{
      __typename?: 'CartItem'
      cartItemID: string
      menuID?: string | null
      quantity: number
      additionalInformation?: string | null
      portion: {
        __typename?: 'Portion'
        id: string
        type: PortionType
        containsAlcohol: ContainsAlcohol
        diet: Array<DietTag>
        dishTags: Array<RestaurantPortionDishTag>
        drinkTags: Array<RestaurantPortionDrinkTag>
        price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
        name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
        portionOptionSections: Array<{
          __typename?: 'PortionOptionSection'
          id: string
          type: PortionOptionSectionType
          portionOptions: Array<{
            __typename?: 'PortionOption'
            isDefault: boolean
            id: string
            diets: Array<DietTag>
            price?: {
              __typename?: 'PortionOptionPrice'
              type: PriceType
              coopMember?: number | null
              normal: number
            } | null
            teho?: {
              __typename?: 'TehoPortionOption'
              itemNo?: string | null
              amount: number
              unitOfMeasure?: string | null
              textSubcode?: string | null
            } | null
            label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          }>
          name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
        } | null>
      }
      unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
      rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      analytics?: {
        __typename?: 'CartItemAnalytics'
        index?: number | null
        itemListName?: string | null
        creativeName?: string | null
        promotionName?: string | null
        relatedItemId?: string | null
      } | null
    }>
    total: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
  }
}

export type GetStoredOrdersQueryVariables = Exact<{
  input: GetStoredOrdersInput
}>

export type GetStoredOrdersQuery = {
  __typename?: 'Query'
  getStoredOrders: {
    __typename?: 'GetStoredOrdersConnection'
    nextToken?: string | null
    items: Array<{
      __typename?: 'StoredOrder'
      orderID: string
      cartID: string
      nickName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      loyaltyProgram?: LoyaltyProgram | null
      paymentMethod?: PaymentMethod | null
      requestedAt: string
      pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
      cart: Array<{
        __typename?: 'CartItem'
        cartItemID: string
        menuID?: string | null
        quantity: number
        additionalInformation?: string | null
        portion: {
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
          name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            portionOptions: Array<{
              __typename?: 'PortionOption'
              isDefault: boolean
              id: string
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                coopMember?: number | null
                normal: number
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            }>
            name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
          } | null>
        }
        unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
        rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        analytics?: {
          __typename?: 'CartItemAnalytics'
          index?: number | null
          itemListName?: string | null
          creativeName?: string | null
          promotionName?: string | null
          relatedItemId?: string | null
        } | null
      }>
      cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
    }>
  }
}

export type GetCustomerAccountQueryVariables = Exact<{[key: string]: never}>

export type GetCustomerAccountQuery = {
  __typename?: 'Query'
  getCustomerAccount: {
    __typename?: 'CustomerAccount'
    customer?: {
      __typename?: 'Customer'
      id: string
      casId: string
      boaId?: string | null
      customerType: CustomerType
      analyticsConsent: boolean
      hasBonusCard: boolean
      benefitsAndPurchases?: Array<{
        __typename?: 'BenefitsAndPurchases'
        periodId: string
        periodBonusPercent?: number | null
        periodBonusBenefitAmount?: number | null
        periodPurchasesForNextLevel?: number | null
      }> | null
      email?: {__typename?: 'Email'; email?: string | null; verified?: boolean | null} | null
      name?: {__typename?: 'Name'; first?: string | null; last?: string | null} | null
      phone?: {__typename?: 'Phone'; number?: string | null; verified?: boolean | null} | null
      address?: {
        __typename?: 'CustomerAddress'
        street?: string | null
        apartment?: string | null
        code?: string | null
        city?: string | null
        countryCode?: string | null
      } | null
    } | null
  }
}

export type OnChangeTableStateSubscriptionVariables = Exact<{
  token: Scalars['ID']['input']
}>

export type OnChangeTableStateSubscription = {
  __typename?: 'Subscription'
  onChangeTableState?: {
    __typename?: 'Table'
    token: string
    tableID: string
    restaurantID: string
    state: {
      __typename?: 'TableState'
      callWaiterType: CallWaiterType
      status: TableStatusType
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder?: boolean | null
      lastUpdated?: string | null
      requestedAt?: string | null
    }
  } | null
}

export type OnChangeRestaurantFeaturesSubscriptionVariables = Exact<{
  restaurantID: Scalars['ID']['input']
}>

export type OnChangeRestaurantFeaturesSubscription = {
  __typename?: 'Subscription'
  onChangeRestaurantFeatures?: {
    __typename?: 'RestaurantFeaturesOutput'
    restaurantID: string
    features: {
      __typename?: 'RestaurantFeatures'
      tableActions: boolean
      tableActionsAutomatic?: boolean | null
      lastCallBuffer: number
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder: boolean
      kitchenClosed: boolean
      kitchenOpenAutomatic?: boolean | null
      phoneNumber?: string | null
      rushHour: {__typename?: 'RushHour'; enabled: boolean; rushHourType: RushHourType; delay: number}
      roomService: {
        __typename?: 'RoomService'
        enabled: boolean
        deliveryMethods: Array<DeliveryMethod>
        deliveryFee: number
        freeDeliveryThreshold?: number | null
        delay: number
        paymentMethods: Array<PaymentMethod>
      }
      disabledPortions: Array<{__typename?: 'DisabledPortion'; id: string; portionOptions: Array<string>}>
    }
  } | null
}

export type OnModifyOrdersSubscriptionVariables = Exact<{
  token: Scalars['ID']['input']
}>

export type OnModifyOrdersSubscription = {
  __typename?: 'Subscription'
  onModifyOrders?: {
    __typename?: 'ModifyOrdersOutput'
    token: string
    restaurantID: string
    bundleID?: string | null
    bundledOrders?: Array<{
      __typename?: 'BundledOrders'
      id: string
      state: OrderState
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          menuID?: string | null
          quantity: number
          additionalInformation?: string | null
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
            name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              portionOptions: Array<{
                __typename?: 'PortionOption'
                isDefault: boolean
                id: string
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  coopMember?: number | null
                  normal: number
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
              }>
              name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            } | null>
          }
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          analytics?: {
            __typename?: 'CartItemAnalytics'
            index?: number | null
            itemListName?: string | null
            creativeName?: string | null
            promotionName?: string | null
            relatedItemId?: string | null
          } | null
        }>
      }>
    }> | null
    orders?: {
      __typename?: 'Orders'
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          menuID?: string | null
          quantity: number
          additionalInformation?: string | null
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
            name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              portionOptions: Array<{
                __typename?: 'PortionOption'
                isDefault: boolean
                id: string
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  coopMember?: number | null
                  normal: number
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
              }>
              name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            } | null>
          }
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          analytics?: {
            __typename?: 'CartItemAnalytics'
            index?: number | null
            itemListName?: string | null
            creativeName?: string | null
            promotionName?: string | null
            relatedItemId?: string | null
          } | null
        }>
      }>
    } | null
    madeOrder?: {
      __typename?: 'Order'
      requestedAt: string
      cartID: string
      nickName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      loyaltyProgram?: LoyaltyProgram | null
      paymentMethod?: PaymentMethod | null
      id: string
      pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
      cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      cart: Array<{
        __typename?: 'CartItem'
        cartItemID: string
        menuID?: string | null
        quantity: number
        additionalInformation?: string | null
        portion: {
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
          name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            portionOptions: Array<{
              __typename?: 'PortionOption'
              isDefault: boolean
              id: string
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                coopMember?: number | null
                normal: number
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            }>
            name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
          } | null>
        }
        unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
        rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        analytics?: {
          __typename?: 'CartItemAnalytics'
          index?: number | null
          itemListName?: string | null
          creativeName?: string | null
          promotionName?: string | null
          relatedItemId?: string | null
        } | null
      }>
    } | null
    doneOrders?: {
      __typename?: 'BundledOrders'
      id: string
      state: OrderState
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          menuID?: string | null
          quantity: number
          additionalInformation?: string | null
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; coopMember?: number | null; normal: number} | null
            name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              portionOptions: Array<{
                __typename?: 'PortionOption'
                isDefault: boolean
                id: string
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  coopMember?: number | null
                  normal: number
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
              }>
              name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            } | null>
          }
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          analytics?: {
            __typename?: 'CartItemAnalytics'
            index?: number | null
            itemListName?: string | null
            creativeName?: string | null
            promotionName?: string | null
            relatedItemId?: string | null
          } | null
        }>
      }>
    } | null
  } | null
}

export type SupportedLocalesFragment = {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}

export type CartItemFragment = {
  __typename?: 'CartItem'
  cartItemID: string
  quantity: number
  additionalInformation?: string | null
  rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
  unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
  portion: {
    __typename?: 'Portion'
    id: string
    type: PortionType
    containsAlcohol: ContainsAlcohol
    diet: Array<DietTag>
    dishTags: Array<RestaurantPortionDishTag>
    drinkTags: Array<RestaurantPortionDrinkTag>
    price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
    name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
    description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
    teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
    portionOptionSections: Array<{
      __typename?: 'PortionOptionSection'
      id: string
      type: PortionOptionSectionType
      name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
      portionOptions: Array<{
        __typename?: 'PortionOption'
        id: string
        isDefault: boolean
        diets: Array<DietTag>
        price?: {__typename?: 'PortionOptionPrice'; type: PriceType; normal: number; coopMember?: number | null} | null
        teho?: {
          __typename?: 'TehoPortionOption'
          itemNo?: string | null
          amount: number
          unitOfMeasure?: string | null
          textSubcode?: string | null
        } | null
        label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
      }>
    } | null>
  }
}

export type StaffOrderFieldsFragment = {
  __typename?: 'Order'
  requestedAt: string
  cartID: string
  nickName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  loyaltyProgram?: LoyaltyProgram | null
  paymentMethod?: PaymentMethod | null
  id: string
  pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
  cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
  cart: Array<{
    __typename?: 'CartItem'
    cartItemID: string
    quantity: number
    additionalInformation?: string | null
    rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
    unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
    portion: {
      __typename?: 'Portion'
      id: string
      type: PortionType
      containsAlcohol: ContainsAlcohol
      diet: Array<DietTag>
      dishTags: Array<RestaurantPortionDishTag>
      drinkTags: Array<RestaurantPortionDrinkTag>
      price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
      name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
      description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
      teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
      portionOptionSections: Array<{
        __typename?: 'PortionOptionSection'
        id: string
        type: PortionOptionSectionType
        name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
        portionOptions: Array<{
          __typename?: 'PortionOption'
          id: string
          isDefault: boolean
          diets: Array<DietTag>
          price?: {
            __typename?: 'PortionOptionPrice'
            type: PriceType
            normal: number
            coopMember?: number | null
          } | null
          teho?: {
            __typename?: 'TehoPortionOption'
            itemNo?: string | null
            amount: number
            unitOfMeasure?: string | null
            textSubcode?: string | null
          } | null
          label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
        }>
      } | null>
    }
  }>
}

export type TransactionOrderFragment = {
  __typename?: 'TransactionOrder'
  cartID: string
  nickName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  loyaltyProgram?: LoyaltyProgram | null
  paymentMethod?: PaymentMethod | null
  pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
  cart: Array<{
    __typename?: 'CartItem'
    cartItemID: string
    quantity: number
    additionalInformation?: string | null
    rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
    unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
    portion: {
      __typename?: 'Portion'
      id: string
      type: PortionType
      containsAlcohol: ContainsAlcohol
      diet: Array<DietTag>
      dishTags: Array<RestaurantPortionDishTag>
      drinkTags: Array<RestaurantPortionDrinkTag>
      price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
      name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
      description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
      teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
      portionOptionSections: Array<{
        __typename?: 'PortionOptionSection'
        id: string
        type: PortionOptionSectionType
        name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
        portionOptions: Array<{
          __typename?: 'PortionOption'
          id: string
          isDefault: boolean
          diets: Array<DietTag>
          price?: {
            __typename?: 'PortionOptionPrice'
            type: PriceType
            normal: number
            coopMember?: number | null
          } | null
          teho?: {
            __typename?: 'TehoPortionOption'
            itemNo?: string | null
            amount: number
            unitOfMeasure?: string | null
            textSubcode?: string | null
          } | null
          label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
        }>
      } | null>
    }
  }>
  cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
}

export type CompleteTableFragment = {
  __typename?: 'Table'
  token: string
  tableID: string
  tableType: TableType
  isTestTable: boolean
  restaurantID: string
  state: {
    __typename?: 'TableState'
    callWaiterType: CallWaiterType
    status: TableStatusType
    billRequest?: boolean | null
    quickOrder?: boolean | null
    waiterCall?: boolean | null
    lastUpdated?: string | null
    requestedAt?: string | null
  }
  orders?: {
    __typename?: 'Orders'
    requestedAt: string
    orderItems: Array<{
      __typename?: 'Order'
      requestedAt: string
      cartID: string
      nickName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      loyaltyProgram?: LoyaltyProgram | null
      paymentMethod?: PaymentMethod | null
      id: string
      pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
      cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      cart: Array<{
        __typename?: 'CartItem'
        cartItemID: string
        quantity: number
        additionalInformation?: string | null
        rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
        portion: {
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
          name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
          description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
            portionOptions: Array<{
              __typename?: 'PortionOption'
              id: string
              isDefault: boolean
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                normal: number
                coopMember?: number | null
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            }>
          } | null>
        }
      }>
    }>
  } | null
  bundledOrders: Array<{
    __typename?: 'BundledOrders'
    id: string
    state: OrderState
    requestedAt: string
    orderItems: Array<{
      __typename?: 'Order'
      requestedAt: string
      cartID: string
      nickName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      loyaltyProgram?: LoyaltyProgram | null
      paymentMethod?: PaymentMethod | null
      id: string
      pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
      cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      cart: Array<{
        __typename?: 'CartItem'
        cartItemID: string
        quantity: number
        additionalInformation?: string | null
        rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
        portion: {
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
          name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
          description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
            portionOptions: Array<{
              __typename?: 'PortionOption'
              id: string
              isDefault: boolean
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                normal: number
                coopMember?: number | null
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            }>
          } | null>
        }
      }>
    }>
  }>
  restaurantSelection: Array<{__typename?: 'RestaurantSelection'; restaurantID: string; tableToken: string}>
}

export type ChangeTableStateStaffMutationVariables = Exact<{
  input: ChangeTableStateInput
}>

export type ChangeTableStateStaffMutation = {
  __typename?: 'Mutation'
  changeTableState: {
    __typename?: 'Table'
    token: string
    tableID: string
    restaurantID: string
    state: {
      __typename?: 'TableState'
      callWaiterType: CallWaiterType
      status: TableStatusType
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder?: boolean | null
      lastUpdated?: string | null
      requestedAt?: string | null
    }
  }
}

export type CreateOrUpdateTableMutationVariables = Exact<{
  input: CreateOrUpdateTableInput
}>

export type CreateOrUpdateTableMutation = {
  __typename?: 'Mutation'
  createOrUpdateTable: {
    __typename?: 'Table'
    token: string
    tableID: string
    restaurantID: string
    tableType: TableType
    isTestTable: boolean
    restaurantSelection: Array<{__typename?: 'RestaurantSelection'; restaurantID: string; tableToken: string}>
  }
}

export type SetRestaurantFeaturesMutationVariables = Exact<{
  input: SetRestaurantFeaturesInput
}>

export type SetRestaurantFeaturesMutation = {
  __typename?: 'Mutation'
  setRestaurantFeatures: {
    __typename?: 'RestaurantFeaturesOutput'
    restaurantID: string
    features: {
      __typename?: 'RestaurantFeatures'
      tableActions: boolean
      tableActionsAutomatic?: boolean | null
      lastCallBuffer: number
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder: boolean
      kitchenClosed: boolean
      kitchenOpenAutomatic?: boolean | null
      tehoEnabled?: boolean | null
      phoneNumber?: string | null
      rushHour: {__typename?: 'RushHour'; enabled: boolean; rushHourType: RushHourType; delay: number}
      roomService: {
        __typename?: 'RoomService'
        enabled: boolean
        deliveryMethods: Array<DeliveryMethod>
        deliveryFee: number
        freeDeliveryThreshold?: number | null
        delay: number
        paymentMethods: Array<PaymentMethod>
        pickupEnabled: boolean
        deliveryEnabled: boolean
      }
      disabledPortions: Array<{__typename?: 'DisabledPortion'; id: string; portionOptions: Array<string>}>
      tableAreas?: {
        __typename?: 'TableAreas'
        areas: Array<{
          __typename?: 'TableArea'
          id: string
          tables: Array<string>
          name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
        }>
      } | null
    }
  }
}

export type ModifyOrdersStaffMutationVariables = Exact<{
  input: ModifyOrdersInput
}>

export type ModifyOrdersStaffMutation = {
  __typename?: 'Mutation'
  modifyOrders: {
    __typename?: 'ModifyOrdersOutput'
    token: string
    restaurantID: string
    bundleID?: string | null
    bundledOrders?: Array<{
      __typename?: 'BundledOrders'
      id: string
      state: OrderState
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          quantity: number
          additionalInformation?: string | null
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
            name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
              portionOptions: Array<{
                __typename?: 'PortionOption'
                id: string
                isDefault: boolean
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  normal: number
                  coopMember?: number | null
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
              }>
            } | null>
          }
        }>
      }>
    }> | null
    orders?: {
      __typename?: 'Orders'
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          quantity: number
          additionalInformation?: string | null
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
            name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
              portionOptions: Array<{
                __typename?: 'PortionOption'
                id: string
                isDefault: boolean
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  normal: number
                  coopMember?: number | null
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
              }>
            } | null>
          }
        }>
      }>
    } | null
    madeOrder?: {
      __typename?: 'Order'
      requestedAt: string
      cartID: string
      nickName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      loyaltyProgram?: LoyaltyProgram | null
      paymentMethod?: PaymentMethod | null
      id: string
      pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
      cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      cart: Array<{
        __typename?: 'CartItem'
        cartItemID: string
        quantity: number
        additionalInformation?: string | null
        rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
        portion: {
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
          name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
          description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
            portionOptions: Array<{
              __typename?: 'PortionOption'
              id: string
              isDefault: boolean
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                normal: number
                coopMember?: number | null
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            }>
          } | null>
        }
      }>
    } | null
    doneOrders?: {
      __typename?: 'BundledOrders'
      id: string
      state: OrderState
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          quantity: number
          additionalInformation?: string | null
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
            name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
              portionOptions: Array<{
                __typename?: 'PortionOption'
                id: string
                isDefault: boolean
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  normal: number
                  coopMember?: number | null
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
              }>
            } | null>
          }
        }>
      }>
    } | null
  }
}

export type SendOrdersToTehoMutationVariables = Exact<{
  input: SendOrdersToTehoInput
}>

export type SendOrdersToTehoMutation = {
  __typename?: 'Mutation'
  sendOrdersToTeho: {__typename?: 'SendOrdersToTehoOutput'; portionsNotFound: Array<string>}
}

export type TehoGetTableQueryVariables = Exact<{
  input: TehoGetTableInput
}>

export type TehoGetTableQuery = {
  __typename?: 'Query'
  tehoGetTable: {__typename?: 'TehoGetTableOutput'; tableID: string; status: TehoTableStatus}
}

export type GetTablesQueryVariables = Exact<{
  input: GetTablesInput
}>

export type GetTablesQuery = {
  __typename?: 'Query'
  getTables: Array<{
    __typename?: 'Table'
    token: string
    tableID: string
    tableType: TableType
    isTestTable: boolean
    restaurantID: string
    state: {
      __typename?: 'TableState'
      callWaiterType: CallWaiterType
      status: TableStatusType
      billRequest?: boolean | null
      quickOrder?: boolean | null
      waiterCall?: boolean | null
      lastUpdated?: string | null
      requestedAt?: string | null
    }
    orders?: {
      __typename?: 'Orders'
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          quantity: number
          additionalInformation?: string | null
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
            name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
              portionOptions: Array<{
                __typename?: 'PortionOption'
                id: string
                isDefault: boolean
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  normal: number
                  coopMember?: number | null
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
              }>
            } | null>
          }
        }>
      }>
    } | null
    bundledOrders: Array<{
      __typename?: 'BundledOrders'
      id: string
      state: OrderState
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          quantity: number
          additionalInformation?: string | null
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
            name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
              portionOptions: Array<{
                __typename?: 'PortionOption'
                id: string
                isDefault: boolean
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  normal: number
                  coopMember?: number | null
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
              }>
            } | null>
          }
        }>
      }>
    }>
    restaurantSelection: Array<{__typename?: 'RestaurantSelection'; restaurantID: string; tableToken: string}>
  }>
}

export type GetTransactionsQueryVariables = Exact<{
  input: GetTransactionsInput
}>

export type GetTransactionsQuery = {
  __typename?: 'Query'
  getTransactions: {
    __typename?: 'GetTransactionsConnection'
    nextToken?: string | null
    items: Array<{
      __typename?: 'Transaction'
      id: string
      callWaiterType?: CallWaiterType | null
      completedAt: string
      doneBy: DoneBy
      requestedAt?: string | null
      restaurantID: string
      tableID: string
      tableType: TableType
      type?: TransactionType | null
      orders: Array<{
        __typename?: 'TransactionOrder'
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          quantity: number
          additionalInformation?: string | null
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
            name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
              portionOptions: Array<{
                __typename?: 'PortionOption'
                id: string
                isDefault: boolean
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  normal: number
                  coopMember?: number | null
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
              }>
            } | null>
          }
        }>
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      }>
    }>
  }
}

export type GetRestaurantFeaturesStaffQueryVariables = Exact<{
  input: GetRestaurantFeaturesInput
}>

export type GetRestaurantFeaturesStaffQuery = {
  __typename?: 'Query'
  getRestaurantFeatures: {
    __typename?: 'RestaurantFeaturesOutput'
    restaurantID: string
    features: {
      __typename?: 'RestaurantFeatures'
      tableActions: boolean
      tableActionsAutomatic?: boolean | null
      lastCallBuffer: number
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder: boolean
      kitchenClosed: boolean
      kitchenOpenAutomatic?: boolean | null
      tehoEnabled?: boolean | null
      phoneNumber?: string | null
      rushHour: {__typename?: 'RushHour'; enabled: boolean; rushHourType: RushHourType; delay: number}
      roomService: {
        __typename?: 'RoomService'
        enabled: boolean
        deliveryMethods: Array<DeliveryMethod>
        deliveryFee: number
        freeDeliveryThreshold?: number | null
        delay: number
        paymentMethods: Array<PaymentMethod>
        pickupEnabled: boolean
        deliveryEnabled: boolean
      }
      disabledPortions: Array<{__typename?: 'DisabledPortion'; id: string; portionOptions: Array<string>}>
      tableAreas?: {
        __typename?: 'TableAreas'
        areas: Array<{
          __typename?: 'TableArea'
          id: string
          tables: Array<string>
          name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
        }>
      } | null
    }
  }
}

export type GetRestaurantStaffQueryVariables = Exact<{
  input: GetRestaurantInput
}>

export type GetRestaurantStaffQuery = {
  __typename?: 'Query'
  getRestaurant: {
    __typename?: 'Restaurant'
    id: string
    isTestRestaurant: boolean
    name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
    descriptionTitle: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
    description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
    weekOpeningTimes: Array<{
      __typename?: 'WeekOpeningTime'
      weekNumber: number
      date: {__typename?: 'DateRange'; start?: string | null; end?: string | null}
      openingTimes: {
        __typename?: 'WeeklyOpeningTimes'
        monday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        tuesday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        wednesday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        thursday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        friday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        saturday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        sunday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
      }
      kitchenOpeningTimes?: {
        __typename?: 'WeeklyOpeningTimes'
        monday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        tuesday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        wednesday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        thursday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        friday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        saturday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
        sunday: {
          __typename?: 'DailyOpeningTime'
          type: OpeningTime
          ranges?: Array<{__typename?: 'TimeRange'; start: string; end: string; endNextDay: boolean}> | null
        }
      } | null
    }>
    menus: Array<{
      __typename?: 'Menu'
      id: string
      name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
      menuSections: Array<{
        __typename?: 'MenuSection'
        name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
        description?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
        portions: Array<{
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
          name: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          description: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          images: Array<{
            __typename?: 'Image'
            url: string
            text: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          }>
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            maxCount?: number | null
            minCount?: number | null
            name?: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string} | null
            portionOptions: Array<{
              __typename?: 'PortionOption'
              id: string
              isDefault: boolean
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                normal: number
                coopMember?: number | null
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
            }>
          } | null>
          recommendations: {
            __typename?: 'RecommendationsListing'
            portionIDs: Array<string>
            header: {__typename?: 'LocalizedString'; fi: string; sv: string; en: string}
          }
        }>
      }>
    }>
    links: {
      __typename?: 'RestaurantLinks'
      homepageLocalized: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
      menuLocalized: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
    }
  }
}

export type GetRestaurantNameQueryVariables = Exact<{
  input: GetRestaurantInput
}>

export type GetRestaurantNameQuery = {
  __typename?: 'Query'
  getRestaurant: {
    __typename?: 'Restaurant'
    id: string
    name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
  }
}

export type GetHotelNameQueryVariables = Exact<{
  input: GetHotelInput
}>

export type GetHotelNameQuery = {
  __typename?: 'Query'
  getHotel: {
    __typename?: 'Hotel'
    id: string
    name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
  }
}

export type GetTimeQueryVariables = Exact<{[key: string]: never}>

export type GetTimeQuery = {__typename?: 'Query'; getTime: {__typename?: 'TimeStamp'; now: string; builtOn: string}}

export type OnChangeTableStateStaffSubscriptionVariables = Exact<{
  restaurantID: Scalars['ID']['input']
}>

export type OnChangeTableStateStaffSubscription = {
  __typename?: 'Subscription'
  onChangeTableStateStaff?: {
    __typename?: 'Table'
    token: string
    tableID: string
    restaurantID: string
    state: {
      __typename?: 'TableState'
      callWaiterType: CallWaiterType
      status: TableStatusType
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder?: boolean | null
      lastUpdated?: string | null
      requestedAt?: string | null
    }
  } | null
}

export type OnChangeRestaurantFeaturesStaffSubscriptionVariables = Exact<{
  restaurantID: Scalars['ID']['input']
}>

export type OnChangeRestaurantFeaturesStaffSubscription = {
  __typename?: 'Subscription'
  onChangeRestaurantFeatures?: {
    __typename?: 'RestaurantFeaturesOutput'
    restaurantID: string
    features: {
      __typename?: 'RestaurantFeatures'
      tableActions: boolean
      tableActionsAutomatic?: boolean | null
      lastCallBuffer: number
      waiterCall?: boolean | null
      billRequest?: boolean | null
      quickOrder: boolean
      kitchenClosed: boolean
      kitchenOpenAutomatic?: boolean | null
      tehoEnabled?: boolean | null
      phoneNumber?: string | null
      rushHour: {__typename?: 'RushHour'; enabled: boolean; rushHourType: RushHourType; delay: number}
      roomService: {
        __typename?: 'RoomService'
        enabled: boolean
        deliveryMethods: Array<DeliveryMethod>
        deliveryFee: number
        freeDeliveryThreshold?: number | null
        delay: number
        paymentMethods: Array<PaymentMethod>
        pickupEnabled: boolean
        deliveryEnabled: boolean
      }
      disabledPortions: Array<{__typename?: 'DisabledPortion'; id: string; portionOptions: Array<string>}>
      tableAreas?: {
        __typename?: 'TableAreas'
        areas: Array<{
          __typename?: 'TableArea'
          id: string
          tables: Array<string>
          name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
        }>
      } | null
    }
  } | null
}

export type OnModifyOrdersStaffSubscriptionVariables = Exact<{
  restaurantID: Scalars['ID']['input']
}>

export type OnModifyOrdersStaffSubscription = {
  __typename?: 'Subscription'
  onModifyOrdersStaff?: {
    __typename?: 'ModifyOrdersOutput'
    token: string
    restaurantID: string
    bundleID?: string | null
    bundledOrders?: Array<{
      __typename?: 'BundledOrders'
      id: string
      state: OrderState
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          quantity: number
          additionalInformation?: string | null
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
            name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
              portionOptions: Array<{
                __typename?: 'PortionOption'
                id: string
                isDefault: boolean
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  normal: number
                  coopMember?: number | null
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
              }>
            } | null>
          }
        }>
      }>
    }> | null
    orders?: {
      __typename?: 'Orders'
      requestedAt: string
      orderItems: Array<{
        __typename?: 'Order'
        requestedAt: string
        cartID: string
        nickName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        loyaltyProgram?: LoyaltyProgram | null
        paymentMethod?: PaymentMethod | null
        id: string
        pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
        cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        cart: Array<{
          __typename?: 'CartItem'
          cartItemID: string
          quantity: number
          additionalInformation?: string | null
          rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
          unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
          portion: {
            __typename?: 'Portion'
            id: string
            type: PortionType
            containsAlcohol: ContainsAlcohol
            diet: Array<DietTag>
            dishTags: Array<RestaurantPortionDishTag>
            drinkTags: Array<RestaurantPortionDrinkTag>
            price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
            name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
            portionOptionSections: Array<{
              __typename?: 'PortionOptionSection'
              id: string
              type: PortionOptionSectionType
              name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
              portionOptions: Array<{
                __typename?: 'PortionOption'
                id: string
                isDefault: boolean
                diets: Array<DietTag>
                price?: {
                  __typename?: 'PortionOptionPrice'
                  type: PriceType
                  normal: number
                  coopMember?: number | null
                } | null
                teho?: {
                  __typename?: 'TehoPortionOption'
                  itemNo?: string | null
                  amount: number
                  unitOfMeasure?: string | null
                  textSubcode?: string | null
                } | null
                label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
              }>
            } | null>
          }
        }>
      }>
    } | null
    madeOrder?: {
      __typename?: 'Order'
      requestedAt: string
      cartID: string
      nickName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      loyaltyProgram?: LoyaltyProgram | null
      paymentMethod?: PaymentMethod | null
      id: string
      pickupTime?: {__typename?: 'PickupTime'; asap?: boolean | null; time?: string | null} | null
      cartTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
      cart: Array<{
        __typename?: 'CartItem'
        cartItemID: string
        quantity: number
        additionalInformation?: string | null
        rowTotal: {__typename?: 'TotalPrice'; normal: number; coopMember?: number | null}
        unitPrice: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null}
        portion: {
          __typename?: 'Portion'
          id: string
          type: PortionType
          containsAlcohol: ContainsAlcohol
          diet: Array<DietTag>
          dishTags: Array<RestaurantPortionDishTag>
          drinkTags: Array<RestaurantPortionDrinkTag>
          price?: {__typename?: 'PortionPrice'; normal: number; coopMember?: number | null} | null
          name: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
          description: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
          teho?: {__typename?: 'TehoPortion'; itemNo: string; unitOfMeasure?: string | null} | null
          portionOptionSections: Array<{
            __typename?: 'PortionOptionSection'
            id: string
            type: PortionOptionSectionType
            name?: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string} | null
            portionOptions: Array<{
              __typename?: 'PortionOption'
              id: string
              isDefault: boolean
              diets: Array<DietTag>
              price?: {
                __typename?: 'PortionOptionPrice'
                type: PriceType
                normal: number
                coopMember?: number | null
              } | null
              teho?: {
                __typename?: 'TehoPortionOption'
                itemNo?: string | null
                amount: number
                unitOfMeasure?: string | null
                textSubcode?: string | null
              } | null
              label: {__typename?: 'LocalizedString'; fi: string; en: string; sv: string}
            }>
          } | null>
        }
      }>
    } | null
  } | null
}

export type OnChangeTimeSubscriptionVariables = Exact<{[key: string]: never}>

export type OnChangeTimeSubscription = {
  __typename?: 'Subscription'
  onChangeTime?: {__typename?: 'TimeStamp'; now: string; builtOn: string} | null
}

export const LocalesFragmentDoc = gql`
  fragment Locales on LocalizedString {
    fi
    sv
    en
  }
`
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on Image {
    url
    text {
      ...Locales
    }
  }
  ${LocalesFragmentDoc}
`
export const RecommendationsListingFieldsFragmentDoc = gql`
  fragment RecommendationsListingFields on RecommendationsListing {
    header {
      ...Locales
    }
    portionIDs
  }
  ${LocalesFragmentDoc}
`
export const PortionFieldsFragmentDoc = gql`
  fragment PortionFields on Portion {
    id
    type
    containsAlcohol
    price {
      normal
      coopMember
    }
    name {
      ...Locales
    }
    description {
      ...Locales
    }
    teho {
      itemNo
      unitOfMeasure
    }
    images {
      url
      text {
        ...Locales
      }
    }
    diet
    dishTags
    drinkTags
    portionOptionSections {
      id
      type
      maxCount
      minCount
      name {
        ...Locales
      }
      portionOptions {
        id
        price {
          type
          normal
          coopMember
        }
        teho {
          itemNo
          amount
          unitOfMeasure
          textSubcode
        }
        label {
          ...Locales
        }
        isDefault
        diets
      }
    }
    recommendations {
      ...RecommendationsListingFields
    }
  }
  ${LocalesFragmentDoc}
  ${RecommendationsListingFieldsFragmentDoc}
`
export const TimeRangeFieldsFragmentDoc = gql`
  fragment TimeRangeFields on TimeRange {
    start
    end
    endNextDay
  }
`
export const MenuAvailabilityFieldsFragmentDoc = gql`
  fragment MenuAvailabilityFields on MenuAvailability {
    tableTypeAvailabilities
    weeklyAvailabilities {
      monday {
        type
        timeRange {
          ...TimeRangeFields
        }
      }
      tuesday {
        type
        timeRange {
          ...TimeRangeFields
        }
      }
      wednesday {
        type
        timeRange {
          ...TimeRangeFields
        }
      }
      thursday {
        type
        timeRange {
          ...TimeRangeFields
        }
      }
      friday {
        type
        timeRange {
          ...TimeRangeFields
        }
      }
      saturday {
        type
        timeRange {
          ...TimeRangeFields
        }
      }
      sunday {
        type
        timeRange {
          ...TimeRangeFields
        }
      }
    }
  }
  ${TimeRangeFieldsFragmentDoc}
`
export const PhoneNumberFieldsFragmentDoc = gql`
  fragment PhoneNumberFields on PhoneNumber {
    number
    description {
      ...Locales
    }
    price {
      ...Locales
    }
  }
  ${LocalesFragmentDoc}
`
export const DailyOpeningTimeFieldsFragmentDoc = gql`
  fragment DailyOpeningTimeFields on DailyOpeningTime {
    type
    ranges {
      start
      end
      endNextDay
    }
  }
`
export const WeeklyOpeningTimesFieldsFragmentDoc = gql`
  fragment WeeklyOpeningTimesFields on WeeklyOpeningTimes {
    monday {
      ...DailyOpeningTimeFields
    }
    tuesday {
      ...DailyOpeningTimeFields
    }
    wednesday {
      ...DailyOpeningTimeFields
    }
    thursday {
      ...DailyOpeningTimeFields
    }
    friday {
      ...DailyOpeningTimeFields
    }
    saturday {
      ...DailyOpeningTimeFields
    }
    sunday {
      ...DailyOpeningTimeFields
    }
  }
  ${DailyOpeningTimeFieldsFragmentDoc}
`
export const MenuRecommendationsListingsFieldsFragmentDoc = gql`
  fragment MenuRecommendationsListingsFields on MenuRecommendationsListings {
    welcome {
      ...RecommendationsListingFields
    }
    cart {
      ...RecommendationsListingFields
    }
  }
  ${RecommendationsListingFieldsFragmentDoc}
`
export const CartItemFieldsFragmentDoc = gql`
  fragment CartItemFields on CartItem {
    cartItemID
    portion {
      id
      type
      containsAlcohol
      price {
        coopMember
        normal
      }
      name {
        ...Locales
      }
      description {
        ...Locales
      }
      teho {
        itemNo
        unitOfMeasure
      }
      diet
      dishTags
      drinkTags
      portionOptionSections {
        portionOptions {
          price {
            type
            coopMember
            normal
          }
          teho {
            itemNo
            amount
            unitOfMeasure
            textSubcode
          }
          label {
            ...Locales
          }
          isDefault
          id
          diets
        }
        id
        type
        name {
          ...Locales
        }
      }
    }
    menuID
    quantity
    unitPrice {
      normal
      coopMember
    }
    rowTotal {
      normal
      coopMember
    }
    additionalInformation
    analytics {
      index
      itemListName
      creativeName
      promotionName
      relatedItemId
    }
  }
  ${LocalesFragmentDoc}
`
export const OrderFieldsFragmentDoc = gql`
  fragment OrderFields on Order {
    requestedAt
    cartID
    nickName
    lastName
    phoneNumber
    pickupTime {
      asap
      time
    }
    loyaltyProgram
    paymentMethod
    id
    cartTotal {
      normal
      coopMember
    }
    cart {
      ...CartItemFields
    }
  }
  ${CartItemFieldsFragmentDoc}
`
export const CartFieldsFragmentDoc = gql`
  fragment CartFields on Cart {
    id
    restaurantID
    items {
      ...CartItemFields
    }
    total {
      normal
      coopMember
    }
  }
  ${CartItemFieldsFragmentDoc}
`
export const StoredOrderFieldsFragmentDoc = gql`
  fragment StoredOrderFields on StoredOrder {
    orderID
    cartID
    nickName
    lastName
    phoneNumber
    loyaltyProgram
    paymentMethod
    pickupTime {
      asap
      time
    }
    cart {
      ...CartItemFields
    }
    cartTotal {
      normal
      coopMember
    }
    requestedAt
  }
  ${CartItemFieldsFragmentDoc}
`
export const StoredOrdersConnectionFragmentDoc = gql`
  fragment StoredOrdersConnection on GetStoredOrdersConnection {
    items {
      ...StoredOrderFields
    }
    nextToken
  }
  ${StoredOrderFieldsFragmentDoc}
`
export const CustomerFieldsFragmentDoc = gql`
  fragment CustomerFields on Customer {
    id
    casId
    boaId
    customerType
    analyticsConsent
    hasBonusCard
    benefitsAndPurchases {
      periodId
      periodBonusPercent
      periodBonusBenefitAmount
      periodPurchasesForNextLevel
    }
    email {
      email
      verified
    }
    name {
      first
      last
    }
    phone {
      number
      verified
    }
    address {
      street
      apartment
      code
      city
      countryCode
    }
  }
`
export const CustomerAccountFieldsFragmentDoc = gql`
  fragment CustomerAccountFields on CustomerAccount {
    customer {
      ...CustomerFields
    }
  }
  ${CustomerFieldsFragmentDoc}
`
export const SupportedLocalesFragmentDoc = gql`
  fragment SupportedLocales on LocalizedString {
    fi
    en
    sv
  }
`
export const CartItemFragmentDoc = gql`
  fragment CartItem on CartItem {
    cartItemID
    quantity
    additionalInformation
    rowTotal {
      normal
      coopMember
    }
    unitPrice {
      normal
      coopMember
    }
    portion {
      id
      type
      containsAlcohol
      price {
        normal
        coopMember
      }
      name {
        ...SupportedLocales
      }
      description {
        ...SupportedLocales
      }
      teho {
        itemNo
        unitOfMeasure
      }
      diet
      dishTags
      drinkTags
      portionOptionSections {
        id
        type
        name {
          ...SupportedLocales
        }
        portionOptions {
          id
          price {
            type
            normal
            coopMember
          }
          teho {
            itemNo
            amount
            unitOfMeasure
            textSubcode
          }
          label {
            ...SupportedLocales
          }
          isDefault
          diets
        }
      }
    }
  }
  ${SupportedLocalesFragmentDoc}
`
export const TransactionOrderFragmentDoc = gql`
  fragment TransactionOrder on TransactionOrder {
    cartID
    nickName
    lastName
    pickupTime {
      asap
      time
    }
    phoneNumber
    loyaltyProgram
    paymentMethod
    cart {
      ...CartItem
    }
    cartTotal {
      normal
      coopMember
    }
  }
  ${CartItemFragmentDoc}
`
export const StaffOrderFieldsFragmentDoc = gql`
  fragment StaffOrderFields on Order {
    requestedAt
    cartID
    nickName
    lastName
    pickupTime {
      asap
      time
    }
    phoneNumber
    loyaltyProgram
    paymentMethod
    id
    cartTotal {
      normal
      coopMember
    }
    cart {
      ...CartItem
    }
  }
  ${CartItemFragmentDoc}
`
export const CompleteTableFragmentDoc = gql`
  fragment CompleteTable on Table {
    token
    tableID
    tableType
    isTestTable
    restaurantID
    state {
      callWaiterType
      status
      billRequest
      quickOrder
      waiterCall
      lastUpdated
      requestedAt
    }
    orders {
      orderItems {
        ...StaffOrderFields
      }
      requestedAt
    }
    bundledOrders {
      id
      state
      requestedAt
      orderItems {
        ...StaffOrderFields
      }
    }
    restaurantSelection {
      restaurantID
      tableToken
    }
  }
  ${StaffOrderFieldsFragmentDoc}
`
export const ChangeTableStateDocument = gql`
  mutation ChangeTableState($input: ChangeTableStateInput!) {
    changeTableState(input: $input) {
      token
      tableID
      restaurantID
      state {
        callWaiterType
        status
        lastUpdated
        requestedAt
        waiterCall
        quickOrder
        billRequest
      }
    }
  }
`
export type ChangeTableStateMutationFn = Apollo.MutationFunction<
  ChangeTableStateMutation,
  ChangeTableStateMutationVariables
>

/**
 * __useChangeTableStateMutation__
 *
 * To run a mutation, you first call `useChangeTableStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTableStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTableStateMutation, { data, loading, error }] = useChangeTableStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTableStateMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeTableStateMutation, ChangeTableStateMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ChangeTableStateMutation, ChangeTableStateMutationVariables>(
    ChangeTableStateDocument,
    options
  )
}
export type ChangeTableStateMutationHookResult = ReturnType<typeof useChangeTableStateMutation>
export type ChangeTableStateMutationResult = Apollo.MutationResult<ChangeTableStateMutation>
export type ChangeTableStateMutationOptions = Apollo.BaseMutationOptions<
  ChangeTableStateMutation,
  ChangeTableStateMutationVariables
>
export const CustomerLoginDocument = gql`
  mutation CustomerLogin($input: CustomerLoginInput) {
    customerLogin(input: $input) {
      loginUrl
    }
  }
`
export type CustomerLoginMutationFn = Apollo.MutationFunction<CustomerLoginMutation, CustomerLoginMutationVariables>

/**
 * __useCustomerLoginMutation__
 *
 * To run a mutation, you first call `useCustomerLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerLoginMutation, { data, loading, error }] = useCustomerLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerLoginMutation, CustomerLoginMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CustomerLoginMutation, CustomerLoginMutationVariables>(CustomerLoginDocument, options)
}
export type CustomerLoginMutationHookResult = ReturnType<typeof useCustomerLoginMutation>
export type CustomerLoginMutationResult = Apollo.MutationResult<CustomerLoginMutation>
export type CustomerLoginMutationOptions = Apollo.BaseMutationOptions<
  CustomerLoginMutation,
  CustomerLoginMutationVariables
>
export const ModifyOrdersDocument = gql`
  mutation modifyOrders($input: ModifyOrdersInput!) {
    modifyOrders(input: $input) {
      token
      restaurantID
      bundleID
      bundledOrders {
        id
        orderItems {
          ...OrderFields
        }
        state
        requestedAt
      }
      orders {
        orderItems {
          ...OrderFields
        }
        requestedAt
      }
      madeOrder {
        ...OrderFields
      }
    }
  }
  ${OrderFieldsFragmentDoc}
`
export type ModifyOrdersMutationFn = Apollo.MutationFunction<ModifyOrdersMutation, ModifyOrdersMutationVariables>

/**
 * __useModifyOrdersMutation__
 *
 * To run a mutation, you first call `useModifyOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrdersMutation, { data, loading, error }] = useModifyOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyOrdersMutation, ModifyOrdersMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ModifyOrdersMutation, ModifyOrdersMutationVariables>(ModifyOrdersDocument, options)
}
export type ModifyOrdersMutationHookResult = ReturnType<typeof useModifyOrdersMutation>
export type ModifyOrdersMutationResult = Apollo.MutationResult<ModifyOrdersMutation>
export type ModifyOrdersMutationOptions = Apollo.BaseMutationOptions<
  ModifyOrdersMutation,
  ModifyOrdersMutationVariables
>
export const UpdateCartDocument = gql`
  mutation updateCart($input: UpdateCartInput!) {
    updateCart(input: $input) {
      ...CartFields
    }
  }
  ${CartFieldsFragmentDoc}
`
export type UpdateCartMutationFn = Apollo.MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>

/**
 * __useUpdateCartMutation__
 *
 * To run a mutation, you first call `useUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartMutation, { data, loading, error }] = useUpdateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCartMutation, UpdateCartMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(UpdateCartDocument, options)
}
export type UpdateCartMutationHookResult = ReturnType<typeof useUpdateCartMutation>
export type UpdateCartMutationResult = Apollo.MutationResult<UpdateCartMutation>
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<UpdateCartMutation, UpdateCartMutationVariables>
export const GetTableDocument = gql`
  query GetTable($input: GetTableInput!) {
    getTable(input: $input) {
      token
      tableID
      tableType
      isTestTable
      restaurantID
      state {
        callWaiterType
        status
        waiterCall
        billRequest
        quickOrder
        lastUpdated
        requestedAt
      }
      orders {
        orderItems {
          ...OrderFields
        }
        requestedAt
      }
      bundledOrders {
        id
        state
        requestedAt
        orderItems {
          ...OrderFields
        }
      }
      restaurantSelection {
        restaurantID
        tableToken
      }
    }
  }
  ${OrderFieldsFragmentDoc}
`

/**
 * __useGetTableQuery__
 *
 * To run a query within a React component, call `useGetTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTableQuery(
  baseOptions: Apollo.QueryHookOptions<GetTableQuery, GetTableQueryVariables> &
    ({variables: GetTableQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetTableQuery, GetTableQueryVariables>(GetTableDocument, options)
}
export function useGetTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTableQuery, GetTableQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetTableQuery, GetTableQueryVariables>(GetTableDocument, options)
}
export function useGetTableSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTableQuery, GetTableQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetTableQuery, GetTableQueryVariables>(GetTableDocument, options)
}
export type GetTableQueryHookResult = ReturnType<typeof useGetTableQuery>
export type GetTableLazyQueryHookResult = ReturnType<typeof useGetTableLazyQuery>
export type GetTableSuspenseQueryHookResult = ReturnType<typeof useGetTableSuspenseQuery>
export type GetTableQueryResult = Apollo.QueryResult<GetTableQuery, GetTableQueryVariables>
export const GetTableByOpenTokenDocument = gql`
  query getTableByOpenToken($token: ID!, $tableID: ID!) {
    getTableByOpenToken(input: {token: $token, tableID: $tableID}) {
      token
      restaurantID
      tableID
      tableType
    }
  }
`

/**
 * __useGetTableByOpenTokenQuery__
 *
 * To run a query within a React component, call `useGetTableByOpenTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableByOpenTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableByOpenTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *      tableID: // value for 'tableID'
 *   },
 * });
 */
export function useGetTableByOpenTokenQuery(
  baseOptions: Apollo.QueryHookOptions<GetTableByOpenTokenQuery, GetTableByOpenTokenQueryVariables> &
    ({variables: GetTableByOpenTokenQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetTableByOpenTokenQuery, GetTableByOpenTokenQueryVariables>(
    GetTableByOpenTokenDocument,
    options
  )
}
export function useGetTableByOpenTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTableByOpenTokenQuery, GetTableByOpenTokenQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetTableByOpenTokenQuery, GetTableByOpenTokenQueryVariables>(
    GetTableByOpenTokenDocument,
    options
  )
}
export function useGetTableByOpenTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTableByOpenTokenQuery, GetTableByOpenTokenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetTableByOpenTokenQuery, GetTableByOpenTokenQueryVariables>(
    GetTableByOpenTokenDocument,
    options
  )
}
export type GetTableByOpenTokenQueryHookResult = ReturnType<typeof useGetTableByOpenTokenQuery>
export type GetTableByOpenTokenLazyQueryHookResult = ReturnType<typeof useGetTableByOpenTokenLazyQuery>
export type GetTableByOpenTokenSuspenseQueryHookResult = ReturnType<typeof useGetTableByOpenTokenSuspenseQuery>
export type GetTableByOpenTokenQueryResult = Apollo.QueryResult<
  GetTableByOpenTokenQuery,
  GetTableByOpenTokenQueryVariables
>
export const GetRestaurantFeaturesDocument = gql`
  query getRestaurantFeatures($input: GetRestaurantFeaturesInput!) {
    getRestaurantFeatures(input: $input) {
      restaurantID
      features {
        tableActions
        tableActionsAutomatic
        lastCallBuffer
        waiterCall
        billRequest
        quickOrder
        kitchenClosed
        kitchenOpenAutomatic
        rushHour {
          enabled
          rushHourType
          delay
        }
        roomService {
          enabled
          deliveryMethods
          deliveryFee
          freeDeliveryThreshold
          delay
          paymentMethods
        }
        disabledPortions {
          id
          portionOptions
        }
        phoneNumber
      }
    }
  }
`

/**
 * __useGetRestaurantFeaturesQuery__
 *
 * To run a query within a React component, call `useGetRestaurantFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantFeaturesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRestaurantFeaturesQuery(
  baseOptions: Apollo.QueryHookOptions<GetRestaurantFeaturesQuery, GetRestaurantFeaturesQueryVariables> &
    ({variables: GetRestaurantFeaturesQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetRestaurantFeaturesQuery, GetRestaurantFeaturesQueryVariables>(
    GetRestaurantFeaturesDocument,
    options
  )
}
export function useGetRestaurantFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantFeaturesQuery, GetRestaurantFeaturesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetRestaurantFeaturesQuery, GetRestaurantFeaturesQueryVariables>(
    GetRestaurantFeaturesDocument,
    options
  )
}
export function useGetRestaurantFeaturesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRestaurantFeaturesQuery, GetRestaurantFeaturesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetRestaurantFeaturesQuery, GetRestaurantFeaturesQueryVariables>(
    GetRestaurantFeaturesDocument,
    options
  )
}
export type GetRestaurantFeaturesQueryHookResult = ReturnType<typeof useGetRestaurantFeaturesQuery>
export type GetRestaurantFeaturesLazyQueryHookResult = ReturnType<typeof useGetRestaurantFeaturesLazyQuery>
export type GetRestaurantFeaturesSuspenseQueryHookResult = ReturnType<typeof useGetRestaurantFeaturesSuspenseQuery>
export type GetRestaurantFeaturesQueryResult = Apollo.QueryResult<
  GetRestaurantFeaturesQuery,
  GetRestaurantFeaturesQueryVariables
>
export const GetRestaurantDocument = gql`
  query getRestaurant($input: GetRestaurantInput!, $withMenus: Boolean = true) {
    getRestaurant(input: $input) {
      id
      costCenterID
      isTestRestaurant
      name {
        ...Locales
      }
      descriptionTitle {
        ...Locales
      }
      description {
        ...Locales
      }
      address {
        municipality {
          ...Locales
        }
      }
      contact {
        primaryPhone {
          ...PhoneNumberFields
        }
      }
      weekOpeningTimes {
        date {
          start
          end
        }
        weekNumber
        openingTimes {
          ...WeeklyOpeningTimesFields
        }
        kitchenOpeningTimes {
          ...WeeklyOpeningTimesFields
        }
      }
      links {
        homepageLocalized {
          ...Locales
        }
        menuLocalized {
          ...Locales
        }
      }
      visualContent {
        logo {
          ...ImageFields
        }
        hero {
          ...ImageFields
        }
        backgroundImage
      }
      menus @include(if: $withMenus) {
        id
        name {
          ...Locales
        }
        menuSections {
          name {
            ...Locales
          }
          description {
            ...Locales
          }
          portions {
            ...PortionFields
          }
        }
        availability {
          ...MenuAvailabilityFields
        }
        recommendations {
          ...MenuRecommendationsListingsFields
        }
      }
    }
  }
  ${LocalesFragmentDoc}
  ${PhoneNumberFieldsFragmentDoc}
  ${WeeklyOpeningTimesFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${PortionFieldsFragmentDoc}
  ${MenuAvailabilityFieldsFragmentDoc}
  ${MenuRecommendationsListingsFieldsFragmentDoc}
`

/**
 * __useGetRestaurantQuery__
 *
 * To run a query within a React component, call `useGetRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantQuery({
 *   variables: {
 *      input: // value for 'input'
 *      withMenus: // value for 'withMenus'
 *   },
 * });
 */
export function useGetRestaurantQuery(
  baseOptions: Apollo.QueryHookOptions<GetRestaurantQuery, GetRestaurantQueryVariables> &
    ({variables: GetRestaurantQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetRestaurantQuery, GetRestaurantQueryVariables>(GetRestaurantDocument, options)
}
export function useGetRestaurantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantQuery, GetRestaurantQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetRestaurantQuery, GetRestaurantQueryVariables>(GetRestaurantDocument, options)
}
export function useGetRestaurantSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRestaurantQuery, GetRestaurantQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetRestaurantQuery, GetRestaurantQueryVariables>(GetRestaurantDocument, options)
}
export type GetRestaurantQueryHookResult = ReturnType<typeof useGetRestaurantQuery>
export type GetRestaurantLazyQueryHookResult = ReturnType<typeof useGetRestaurantLazyQuery>
export type GetRestaurantSuspenseQueryHookResult = ReturnType<typeof useGetRestaurantSuspenseQuery>
export type GetRestaurantQueryResult = Apollo.QueryResult<GetRestaurantQuery, GetRestaurantQueryVariables>
export const GetRestaurantListItemDocument = gql`
  query getRestaurantListItem($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      name {
        ...Locales
      }
      description {
        ...Locales
      }
      visualContent {
        logo {
          ...ImageFields
        }
        hero {
          ...ImageFields
        }
        backgroundImage
        listingImage {
          ...ImageFields
        }
      }
    }
  }
  ${LocalesFragmentDoc}
  ${ImageFieldsFragmentDoc}
`

/**
 * __useGetRestaurantListItemQuery__
 *
 * To run a query within a React component, call `useGetRestaurantListItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantListItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantListItemQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRestaurantListItemQuery(
  baseOptions: Apollo.QueryHookOptions<GetRestaurantListItemQuery, GetRestaurantListItemQueryVariables> &
    ({variables: GetRestaurantListItemQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetRestaurantListItemQuery, GetRestaurantListItemQueryVariables>(
    GetRestaurantListItemDocument,
    options
  )
}
export function useGetRestaurantListItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantListItemQuery, GetRestaurantListItemQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetRestaurantListItemQuery, GetRestaurantListItemQueryVariables>(
    GetRestaurantListItemDocument,
    options
  )
}
export function useGetRestaurantListItemSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRestaurantListItemQuery, GetRestaurantListItemQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetRestaurantListItemQuery, GetRestaurantListItemQueryVariables>(
    GetRestaurantListItemDocument,
    options
  )
}
export type GetRestaurantListItemQueryHookResult = ReturnType<typeof useGetRestaurantListItemQuery>
export type GetRestaurantListItemLazyQueryHookResult = ReturnType<typeof useGetRestaurantListItemLazyQuery>
export type GetRestaurantListItemSuspenseQueryHookResult = ReturnType<typeof useGetRestaurantListItemSuspenseQuery>
export type GetRestaurantListItemQueryResult = Apollo.QueryResult<
  GetRestaurantListItemQuery,
  GetRestaurantListItemQueryVariables
>
export const GetHotelDocument = gql`
  query getHotel($input: GetHotelInput!) {
    getHotel(input: $input) {
      id
      name {
        ...Locales
      }
      logoImage {
        ...ImageFields
      }
      negativeLogoImage {
        ...ImageFields
      }
      heroImage {
        ...ImageFields
      }
      heroMobileImage {
        ...ImageFields
      }
    }
  }
  ${LocalesFragmentDoc}
  ${ImageFieldsFragmentDoc}
`

/**
 * __useGetHotelQuery__
 *
 * To run a query within a React component, call `useGetHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHotelQuery(
  baseOptions: Apollo.QueryHookOptions<GetHotelQuery, GetHotelQueryVariables> &
    ({variables: GetHotelQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetHotelQuery, GetHotelQueryVariables>(GetHotelDocument, options)
}
export function useGetHotelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHotelQuery, GetHotelQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetHotelQuery, GetHotelQueryVariables>(GetHotelDocument, options)
}
export function useGetHotelSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHotelQuery, GetHotelQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetHotelQuery, GetHotelQueryVariables>(GetHotelDocument, options)
}
export type GetHotelQueryHookResult = ReturnType<typeof useGetHotelQuery>
export type GetHotelLazyQueryHookResult = ReturnType<typeof useGetHotelLazyQuery>
export type GetHotelSuspenseQueryHookResult = ReturnType<typeof useGetHotelSuspenseQuery>
export type GetHotelQueryResult = Apollo.QueryResult<GetHotelQuery, GetHotelQueryVariables>
export const GetCartDocument = gql`
  query getCart($input: GetCartInput!) {
    getCart(input: $input) {
      ...CartFields
    }
  }
  ${CartFieldsFragmentDoc}
`

/**
 * __useGetCartQuery__
 *
 * To run a query within a React component, call `useGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCartQuery(
  baseOptions: Apollo.QueryHookOptions<GetCartQuery, GetCartQueryVariables> &
    ({variables: GetCartQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options)
}
export function useGetCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartQuery, GetCartQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options)
}
export function useGetCartSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCartQuery, GetCartQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options)
}
export type GetCartQueryHookResult = ReturnType<typeof useGetCartQuery>
export type GetCartLazyQueryHookResult = ReturnType<typeof useGetCartLazyQuery>
export type GetCartSuspenseQueryHookResult = ReturnType<typeof useGetCartSuspenseQuery>
export type GetCartQueryResult = Apollo.QueryResult<GetCartQuery, GetCartQueryVariables>
export const GetStoredOrdersDocument = gql`
  query GetStoredOrders($input: GetStoredOrdersInput!) {
    getStoredOrders(input: $input) {
      ...StoredOrdersConnection
    }
  }
  ${StoredOrdersConnectionFragmentDoc}
`

/**
 * __useGetStoredOrdersQuery__
 *
 * To run a query within a React component, call `useGetStoredOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoredOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoredOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoredOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetStoredOrdersQuery, GetStoredOrdersQueryVariables> &
    ({variables: GetStoredOrdersQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetStoredOrdersQuery, GetStoredOrdersQueryVariables>(GetStoredOrdersDocument, options)
}
export function useGetStoredOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStoredOrdersQuery, GetStoredOrdersQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetStoredOrdersQuery, GetStoredOrdersQueryVariables>(GetStoredOrdersDocument, options)
}
export function useGetStoredOrdersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStoredOrdersQuery, GetStoredOrdersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetStoredOrdersQuery, GetStoredOrdersQueryVariables>(GetStoredOrdersDocument, options)
}
export type GetStoredOrdersQueryHookResult = ReturnType<typeof useGetStoredOrdersQuery>
export type GetStoredOrdersLazyQueryHookResult = ReturnType<typeof useGetStoredOrdersLazyQuery>
export type GetStoredOrdersSuspenseQueryHookResult = ReturnType<typeof useGetStoredOrdersSuspenseQuery>
export type GetStoredOrdersQueryResult = Apollo.QueryResult<GetStoredOrdersQuery, GetStoredOrdersQueryVariables>
export const GetCustomerAccountDocument = gql`
  query GetCustomerAccount {
    getCustomerAccount {
      ...CustomerAccountFields
    }
  }
  ${CustomerAccountFieldsFragmentDoc}
`

/**
 * __useGetCustomerAccountQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>(GetCustomerAccountDocument, options)
}
export function useGetCustomerAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>(
    GetCustomerAccountDocument,
    options
  )
}
export function useGetCustomerAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>(
    GetCustomerAccountDocument,
    options
  )
}
export type GetCustomerAccountQueryHookResult = ReturnType<typeof useGetCustomerAccountQuery>
export type GetCustomerAccountLazyQueryHookResult = ReturnType<typeof useGetCustomerAccountLazyQuery>
export type GetCustomerAccountSuspenseQueryHookResult = ReturnType<typeof useGetCustomerAccountSuspenseQuery>
export type GetCustomerAccountQueryResult = Apollo.QueryResult<
  GetCustomerAccountQuery,
  GetCustomerAccountQueryVariables
>
export const OnChangeTableStateDocument = gql`
  subscription OnChangeTableState($token: ID!) {
    onChangeTableState(token: $token) {
      token
      tableID
      restaurantID
      state {
        callWaiterType
        status
        waiterCall
        billRequest
        quickOrder
        lastUpdated
        requestedAt
      }
    }
  }
`

/**
 * __useOnChangeTableStateSubscription__
 *
 * To run a query within a React component, call `useOnChangeTableStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChangeTableStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChangeTableStateSubscription({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useOnChangeTableStateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<OnChangeTableStateSubscription, OnChangeTableStateSubscriptionVariables> &
    ({variables: OnChangeTableStateSubscriptionVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSubscription<OnChangeTableStateSubscription, OnChangeTableStateSubscriptionVariables>(
    OnChangeTableStateDocument,
    options
  )
}
export type OnChangeTableStateSubscriptionHookResult = ReturnType<typeof useOnChangeTableStateSubscription>
export type OnChangeTableStateSubscriptionResult = Apollo.SubscriptionResult<OnChangeTableStateSubscription>
export const OnChangeRestaurantFeaturesDocument = gql`
  subscription onChangeRestaurantFeatures($restaurantID: ID!) {
    onChangeRestaurantFeatures(restaurantID: $restaurantID) {
      restaurantID
      features {
        tableActions
        tableActionsAutomatic
        lastCallBuffer
        waiterCall
        billRequest
        quickOrder
        kitchenClosed
        kitchenOpenAutomatic
        rushHour {
          enabled
          rushHourType
          delay
        }
        roomService {
          enabled
          deliveryMethods
          deliveryFee
          freeDeliveryThreshold
          delay
          paymentMethods
        }
        disabledPortions {
          id
          portionOptions
        }
        phoneNumber
      }
    }
  }
`

/**
 * __useOnChangeRestaurantFeaturesSubscription__
 *
 * To run a query within a React component, call `useOnChangeRestaurantFeaturesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChangeRestaurantFeaturesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChangeRestaurantFeaturesSubscription({
 *   variables: {
 *      restaurantID: // value for 'restaurantID'
 *   },
 * });
 */
export function useOnChangeRestaurantFeaturesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnChangeRestaurantFeaturesSubscription,
    OnChangeRestaurantFeaturesSubscriptionVariables
  > &
    ({variables: OnChangeRestaurantFeaturesSubscriptionVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSubscription<
    OnChangeRestaurantFeaturesSubscription,
    OnChangeRestaurantFeaturesSubscriptionVariables
  >(OnChangeRestaurantFeaturesDocument, options)
}
export type OnChangeRestaurantFeaturesSubscriptionHookResult = ReturnType<
  typeof useOnChangeRestaurantFeaturesSubscription
>
export type OnChangeRestaurantFeaturesSubscriptionResult =
  Apollo.SubscriptionResult<OnChangeRestaurantFeaturesSubscription>
export const OnModifyOrdersDocument = gql`
  subscription OnModifyOrders($token: ID!) {
    onModifyOrders(token: $token) {
      token
      restaurantID
      bundleID
      bundledOrders {
        id
        orderItems {
          ...OrderFields
        }
        state
        requestedAt
      }
      orders {
        orderItems {
          ...OrderFields
        }
        requestedAt
      }
      madeOrder {
        ...OrderFields
      }
      doneOrders {
        id
        orderItems {
          ...OrderFields
        }
        state
        requestedAt
      }
    }
  }
  ${OrderFieldsFragmentDoc}
`

/**
 * __useOnModifyOrdersSubscription__
 *
 * To run a query within a React component, call `useOnModifyOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnModifyOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnModifyOrdersSubscription({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useOnModifyOrdersSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<OnModifyOrdersSubscription, OnModifyOrdersSubscriptionVariables> &
    ({variables: OnModifyOrdersSubscriptionVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSubscription<OnModifyOrdersSubscription, OnModifyOrdersSubscriptionVariables>(
    OnModifyOrdersDocument,
    options
  )
}
export type OnModifyOrdersSubscriptionHookResult = ReturnType<typeof useOnModifyOrdersSubscription>
export type OnModifyOrdersSubscriptionResult = Apollo.SubscriptionResult<OnModifyOrdersSubscription>
export const ChangeTableStateStaffDocument = gql`
  mutation ChangeTableStateStaff($input: ChangeTableStateInput!) {
    changeTableState(input: $input) {
      token
      tableID
      restaurantID
      state {
        callWaiterType
        status
        waiterCall
        billRequest
        quickOrder
        lastUpdated
        requestedAt
      }
    }
  }
`
export type ChangeTableStateStaffMutationFn = Apollo.MutationFunction<
  ChangeTableStateStaffMutation,
  ChangeTableStateStaffMutationVariables
>

/**
 * __useChangeTableStateStaffMutation__
 *
 * To run a mutation, you first call `useChangeTableStateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTableStateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTableStateStaffMutation, { data, loading, error }] = useChangeTableStateStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTableStateStaffMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeTableStateStaffMutation, ChangeTableStateStaffMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ChangeTableStateStaffMutation, ChangeTableStateStaffMutationVariables>(
    ChangeTableStateStaffDocument,
    options
  )
}
export type ChangeTableStateStaffMutationHookResult = ReturnType<typeof useChangeTableStateStaffMutation>
export type ChangeTableStateStaffMutationResult = Apollo.MutationResult<ChangeTableStateStaffMutation>
export type ChangeTableStateStaffMutationOptions = Apollo.BaseMutationOptions<
  ChangeTableStateStaffMutation,
  ChangeTableStateStaffMutationVariables
>
export const CreateOrUpdateTableDocument = gql`
  mutation CreateOrUpdateTable($input: CreateOrUpdateTableInput!) {
    createOrUpdateTable(input: $input) {
      token
      tableID
      restaurantID
      tableType
      isTestTable
      restaurantSelection {
        restaurantID
        tableToken
      }
    }
  }
`
export type CreateOrUpdateTableMutationFn = Apollo.MutationFunction<
  CreateOrUpdateTableMutation,
  CreateOrUpdateTableMutationVariables
>

/**
 * __useCreateOrUpdateTableMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTableMutation, { data, loading, error }] = useCreateOrUpdateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTableMutation, CreateOrUpdateTableMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateOrUpdateTableMutation, CreateOrUpdateTableMutationVariables>(
    CreateOrUpdateTableDocument,
    options
  )
}
export type CreateOrUpdateTableMutationHookResult = ReturnType<typeof useCreateOrUpdateTableMutation>
export type CreateOrUpdateTableMutationResult = Apollo.MutationResult<CreateOrUpdateTableMutation>
export type CreateOrUpdateTableMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateTableMutation,
  CreateOrUpdateTableMutationVariables
>
export const SetRestaurantFeaturesDocument = gql`
  mutation SetRestaurantFeatures($input: SetRestaurantFeaturesInput!) {
    setRestaurantFeatures(input: $input) {
      restaurantID
      features {
        tableActions
        tableActionsAutomatic
        lastCallBuffer
        waiterCall
        billRequest
        quickOrder
        kitchenClosed
        kitchenOpenAutomatic
        rushHour {
          enabled
          rushHourType
          delay
        }
        roomService {
          enabled
          deliveryMethods
          deliveryFee
          freeDeliveryThreshold
          delay
          paymentMethods
          pickupEnabled
          deliveryEnabled
        }
        disabledPortions {
          id
          portionOptions
        }
        tableAreas {
          areas {
            id
            name {
              ...SupportedLocales
            }
            tables
          }
        }
        tehoEnabled
        phoneNumber
      }
    }
  }
  ${SupportedLocalesFragmentDoc}
`
export type SetRestaurantFeaturesMutationFn = Apollo.MutationFunction<
  SetRestaurantFeaturesMutation,
  SetRestaurantFeaturesMutationVariables
>

/**
 * __useSetRestaurantFeaturesMutation__
 *
 * To run a mutation, you first call `useSetRestaurantFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRestaurantFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRestaurantFeaturesMutation, { data, loading, error }] = useSetRestaurantFeaturesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRestaurantFeaturesMutation(
  baseOptions?: Apollo.MutationHookOptions<SetRestaurantFeaturesMutation, SetRestaurantFeaturesMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<SetRestaurantFeaturesMutation, SetRestaurantFeaturesMutationVariables>(
    SetRestaurantFeaturesDocument,
    options
  )
}
export type SetRestaurantFeaturesMutationHookResult = ReturnType<typeof useSetRestaurantFeaturesMutation>
export type SetRestaurantFeaturesMutationResult = Apollo.MutationResult<SetRestaurantFeaturesMutation>
export type SetRestaurantFeaturesMutationOptions = Apollo.BaseMutationOptions<
  SetRestaurantFeaturesMutation,
  SetRestaurantFeaturesMutationVariables
>
export const ModifyOrdersStaffDocument = gql`
  mutation modifyOrdersStaff($input: ModifyOrdersInput!) {
    modifyOrders(input: $input) {
      token
      restaurantID
      bundleID
      bundledOrders {
        id
        orderItems {
          ...StaffOrderFields
        }
        state
        requestedAt
      }
      orders {
        orderItems {
          ...StaffOrderFields
        }
        requestedAt
      }
      madeOrder {
        ...StaffOrderFields
      }
      doneOrders {
        id
        orderItems {
          ...StaffOrderFields
        }
        state
        requestedAt
      }
    }
  }
  ${StaffOrderFieldsFragmentDoc}
`
export type ModifyOrdersStaffMutationFn = Apollo.MutationFunction<
  ModifyOrdersStaffMutation,
  ModifyOrdersStaffMutationVariables
>

/**
 * __useModifyOrdersStaffMutation__
 *
 * To run a mutation, you first call `useModifyOrdersStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrdersStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrdersStaffMutation, { data, loading, error }] = useModifyOrdersStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrdersStaffMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyOrdersStaffMutation, ModifyOrdersStaffMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ModifyOrdersStaffMutation, ModifyOrdersStaffMutationVariables>(
    ModifyOrdersStaffDocument,
    options
  )
}
export type ModifyOrdersStaffMutationHookResult = ReturnType<typeof useModifyOrdersStaffMutation>
export type ModifyOrdersStaffMutationResult = Apollo.MutationResult<ModifyOrdersStaffMutation>
export type ModifyOrdersStaffMutationOptions = Apollo.BaseMutationOptions<
  ModifyOrdersStaffMutation,
  ModifyOrdersStaffMutationVariables
>
export const SendOrdersToTehoDocument = gql`
  mutation SendOrdersToTeho($input: SendOrdersToTehoInput!) {
    sendOrdersToTeho(input: $input) {
      portionsNotFound
    }
  }
`
export type SendOrdersToTehoMutationFn = Apollo.MutationFunction<
  SendOrdersToTehoMutation,
  SendOrdersToTehoMutationVariables
>

/**
 * __useSendOrdersToTehoMutation__
 *
 * To run a mutation, you first call `useSendOrdersToTehoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrdersToTehoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrdersToTehoMutation, { data, loading, error }] = useSendOrdersToTehoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOrdersToTehoMutation(
  baseOptions?: Apollo.MutationHookOptions<SendOrdersToTehoMutation, SendOrdersToTehoMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<SendOrdersToTehoMutation, SendOrdersToTehoMutationVariables>(
    SendOrdersToTehoDocument,
    options
  )
}
export type SendOrdersToTehoMutationHookResult = ReturnType<typeof useSendOrdersToTehoMutation>
export type SendOrdersToTehoMutationResult = Apollo.MutationResult<SendOrdersToTehoMutation>
export type SendOrdersToTehoMutationOptions = Apollo.BaseMutationOptions<
  SendOrdersToTehoMutation,
  SendOrdersToTehoMutationVariables
>
export const TehoGetTableDocument = gql`
  query TehoGetTable($input: TehoGetTableInput!) {
    tehoGetTable(input: $input) {
      tableID
      status
    }
  }
`

/**
 * __useTehoGetTableQuery__
 *
 * To run a query within a React component, call `useTehoGetTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTehoGetTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTehoGetTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTehoGetTableQuery(
  baseOptions: Apollo.QueryHookOptions<TehoGetTableQuery, TehoGetTableQueryVariables> &
    ({variables: TehoGetTableQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<TehoGetTableQuery, TehoGetTableQueryVariables>(TehoGetTableDocument, options)
}
export function useTehoGetTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TehoGetTableQuery, TehoGetTableQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<TehoGetTableQuery, TehoGetTableQueryVariables>(TehoGetTableDocument, options)
}
export function useTehoGetTableSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TehoGetTableQuery, TehoGetTableQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<TehoGetTableQuery, TehoGetTableQueryVariables>(TehoGetTableDocument, options)
}
export type TehoGetTableQueryHookResult = ReturnType<typeof useTehoGetTableQuery>
export type TehoGetTableLazyQueryHookResult = ReturnType<typeof useTehoGetTableLazyQuery>
export type TehoGetTableSuspenseQueryHookResult = ReturnType<typeof useTehoGetTableSuspenseQuery>
export type TehoGetTableQueryResult = Apollo.QueryResult<TehoGetTableQuery, TehoGetTableQueryVariables>
export const GetTablesDocument = gql`
  query GetTables($input: GetTablesInput!) {
    getTables(input: $input) {
      ...CompleteTable
    }
  }
  ${CompleteTableFragmentDoc}
`

/**
 * __useGetTablesQuery__
 *
 * To run a query within a React component, call `useGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTablesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTablesQuery(
  baseOptions: Apollo.QueryHookOptions<GetTablesQuery, GetTablesQueryVariables> &
    ({variables: GetTablesQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetTablesQuery, GetTablesQueryVariables>(GetTablesDocument, options)
}
export function useGetTablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTablesQuery, GetTablesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetTablesQuery, GetTablesQueryVariables>(GetTablesDocument, options)
}
export function useGetTablesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTablesQuery, GetTablesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetTablesQuery, GetTablesQueryVariables>(GetTablesDocument, options)
}
export type GetTablesQueryHookResult = ReturnType<typeof useGetTablesQuery>
export type GetTablesLazyQueryHookResult = ReturnType<typeof useGetTablesLazyQuery>
export type GetTablesSuspenseQueryHookResult = ReturnType<typeof useGetTablesSuspenseQuery>
export type GetTablesQueryResult = Apollo.QueryResult<GetTablesQuery, GetTablesQueryVariables>
export const GetTransactionsDocument = gql`
  query getTransactions($input: GetTransactionsInput!) {
    getTransactions(input: $input) {
      items {
        id
        callWaiterType
        completedAt
        doneBy
        requestedAt
        restaurantID
        tableID
        tableType
        orders {
          ...TransactionOrder
        }
        type
      }
      nextToken
    }
  }
  ${TransactionOrderFragmentDoc}
`

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables> &
    ({variables: GetTransactionsQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options)
}
export function useGetTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options)
}
export function useGetTransactionsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options)
}
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>
export type GetTransactionsSuspenseQueryHookResult = ReturnType<typeof useGetTransactionsSuspenseQuery>
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>
export const GetRestaurantFeaturesStaffDocument = gql`
  query getRestaurantFeaturesStaff($input: GetRestaurantFeaturesInput!) {
    getRestaurantFeatures(input: $input) {
      restaurantID
      features {
        tableActions
        tableActionsAutomatic
        lastCallBuffer
        waiterCall
        billRequest
        quickOrder
        kitchenClosed
        kitchenOpenAutomatic
        rushHour {
          enabled
          rushHourType
          delay
        }
        roomService {
          enabled
          deliveryMethods
          deliveryFee
          freeDeliveryThreshold
          delay
          paymentMethods
          pickupEnabled
          deliveryEnabled
        }
        disabledPortions {
          id
          portionOptions
        }
        tableAreas {
          areas {
            id
            name {
              ...SupportedLocales
            }
            tables
          }
        }
        tehoEnabled
        phoneNumber
      }
    }
  }
  ${SupportedLocalesFragmentDoc}
`

/**
 * __useGetRestaurantFeaturesStaffQuery__
 *
 * To run a query within a React component, call `useGetRestaurantFeaturesStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantFeaturesStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantFeaturesStaffQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRestaurantFeaturesStaffQuery(
  baseOptions: Apollo.QueryHookOptions<GetRestaurantFeaturesStaffQuery, GetRestaurantFeaturesStaffQueryVariables> &
    ({variables: GetRestaurantFeaturesStaffQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetRestaurantFeaturesStaffQuery, GetRestaurantFeaturesStaffQueryVariables>(
    GetRestaurantFeaturesStaffDocument,
    options
  )
}
export function useGetRestaurantFeaturesStaffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantFeaturesStaffQuery, GetRestaurantFeaturesStaffQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetRestaurantFeaturesStaffQuery, GetRestaurantFeaturesStaffQueryVariables>(
    GetRestaurantFeaturesStaffDocument,
    options
  )
}
export function useGetRestaurantFeaturesStaffSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRestaurantFeaturesStaffQuery, GetRestaurantFeaturesStaffQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetRestaurantFeaturesStaffQuery, GetRestaurantFeaturesStaffQueryVariables>(
    GetRestaurantFeaturesStaffDocument,
    options
  )
}
export type GetRestaurantFeaturesStaffQueryHookResult = ReturnType<typeof useGetRestaurantFeaturesStaffQuery>
export type GetRestaurantFeaturesStaffLazyQueryHookResult = ReturnType<typeof useGetRestaurantFeaturesStaffLazyQuery>
export type GetRestaurantFeaturesStaffSuspenseQueryHookResult = ReturnType<
  typeof useGetRestaurantFeaturesStaffSuspenseQuery
>
export type GetRestaurantFeaturesStaffQueryResult = Apollo.QueryResult<
  GetRestaurantFeaturesStaffQuery,
  GetRestaurantFeaturesStaffQueryVariables
>
export const GetRestaurantStaffDocument = gql`
  query getRestaurantStaff($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      isTestRestaurant
      name {
        ...SupportedLocales
      }
      descriptionTitle {
        ...SupportedLocales
      }
      description {
        ...SupportedLocales
      }
      weekOpeningTimes {
        date {
          start
          end
        }
        weekNumber
        openingTimes {
          ...WeeklyOpeningTimesFields
        }
        kitchenOpeningTimes {
          ...WeeklyOpeningTimesFields
        }
      }
      menus {
        id
        name {
          ...Locales
        }
        menuSections {
          name {
            ...Locales
          }
          description {
            ...Locales
          }
          portions {
            ...PortionFields
          }
        }
      }
      links {
        homepageLocalized {
          ...SupportedLocales
        }
        menuLocalized {
          ...SupportedLocales
        }
      }
    }
  }
  ${SupportedLocalesFragmentDoc}
  ${WeeklyOpeningTimesFieldsFragmentDoc}
  ${LocalesFragmentDoc}
  ${PortionFieldsFragmentDoc}
`

/**
 * __useGetRestaurantStaffQuery__
 *
 * To run a query within a React component, call `useGetRestaurantStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantStaffQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRestaurantStaffQuery(
  baseOptions: Apollo.QueryHookOptions<GetRestaurantStaffQuery, GetRestaurantStaffQueryVariables> &
    ({variables: GetRestaurantStaffQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetRestaurantStaffQuery, GetRestaurantStaffQueryVariables>(GetRestaurantStaffDocument, options)
}
export function useGetRestaurantStaffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantStaffQuery, GetRestaurantStaffQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetRestaurantStaffQuery, GetRestaurantStaffQueryVariables>(
    GetRestaurantStaffDocument,
    options
  )
}
export function useGetRestaurantStaffSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRestaurantStaffQuery, GetRestaurantStaffQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetRestaurantStaffQuery, GetRestaurantStaffQueryVariables>(
    GetRestaurantStaffDocument,
    options
  )
}
export type GetRestaurantStaffQueryHookResult = ReturnType<typeof useGetRestaurantStaffQuery>
export type GetRestaurantStaffLazyQueryHookResult = ReturnType<typeof useGetRestaurantStaffLazyQuery>
export type GetRestaurantStaffSuspenseQueryHookResult = ReturnType<typeof useGetRestaurantStaffSuspenseQuery>
export type GetRestaurantStaffQueryResult = Apollo.QueryResult<
  GetRestaurantStaffQuery,
  GetRestaurantStaffQueryVariables
>
export const GetRestaurantNameDocument = gql`
  query getRestaurantName($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      name {
        ...SupportedLocales
      }
    }
  }
  ${SupportedLocalesFragmentDoc}
`

/**
 * __useGetRestaurantNameQuery__
 *
 * To run a query within a React component, call `useGetRestaurantNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRestaurantNameQuery(
  baseOptions: Apollo.QueryHookOptions<GetRestaurantNameQuery, GetRestaurantNameQueryVariables> &
    ({variables: GetRestaurantNameQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetRestaurantNameQuery, GetRestaurantNameQueryVariables>(GetRestaurantNameDocument, options)
}
export function useGetRestaurantNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantNameQuery, GetRestaurantNameQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetRestaurantNameQuery, GetRestaurantNameQueryVariables>(
    GetRestaurantNameDocument,
    options
  )
}
export function useGetRestaurantNameSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRestaurantNameQuery, GetRestaurantNameQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetRestaurantNameQuery, GetRestaurantNameQueryVariables>(
    GetRestaurantNameDocument,
    options
  )
}
export type GetRestaurantNameQueryHookResult = ReturnType<typeof useGetRestaurantNameQuery>
export type GetRestaurantNameLazyQueryHookResult = ReturnType<typeof useGetRestaurantNameLazyQuery>
export type GetRestaurantNameSuspenseQueryHookResult = ReturnType<typeof useGetRestaurantNameSuspenseQuery>
export type GetRestaurantNameQueryResult = Apollo.QueryResult<GetRestaurantNameQuery, GetRestaurantNameQueryVariables>
export const GetHotelNameDocument = gql`
  query getHotelName($input: GetHotelInput!) {
    getHotel(input: $input) {
      id
      name {
        ...SupportedLocales
      }
    }
  }
  ${SupportedLocalesFragmentDoc}
`

/**
 * __useGetHotelNameQuery__
 *
 * To run a query within a React component, call `useGetHotelNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHotelNameQuery(
  baseOptions: Apollo.QueryHookOptions<GetHotelNameQuery, GetHotelNameQueryVariables> &
    ({variables: GetHotelNameQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetHotelNameQuery, GetHotelNameQueryVariables>(GetHotelNameDocument, options)
}
export function useGetHotelNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHotelNameQuery, GetHotelNameQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetHotelNameQuery, GetHotelNameQueryVariables>(GetHotelNameDocument, options)
}
export function useGetHotelNameSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHotelNameQuery, GetHotelNameQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetHotelNameQuery, GetHotelNameQueryVariables>(GetHotelNameDocument, options)
}
export type GetHotelNameQueryHookResult = ReturnType<typeof useGetHotelNameQuery>
export type GetHotelNameLazyQueryHookResult = ReturnType<typeof useGetHotelNameLazyQuery>
export type GetHotelNameSuspenseQueryHookResult = ReturnType<typeof useGetHotelNameSuspenseQuery>
export type GetHotelNameQueryResult = Apollo.QueryResult<GetHotelNameQuery, GetHotelNameQueryVariables>
export const GetTimeDocument = gql`
  query GetTime {
    getTime {
      now
      builtOn
    }
  }
`

/**
 * __useGetTimeQuery__
 *
 * To run a query within a React component, call `useGetTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeQuery(baseOptions?: Apollo.QueryHookOptions<GetTimeQuery, GetTimeQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetTimeQuery, GetTimeQueryVariables>(GetTimeDocument, options)
}
export function useGetTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeQuery, GetTimeQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetTimeQuery, GetTimeQueryVariables>(GetTimeDocument, options)
}
export function useGetTimeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTimeQuery, GetTimeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<GetTimeQuery, GetTimeQueryVariables>(GetTimeDocument, options)
}
export type GetTimeQueryHookResult = ReturnType<typeof useGetTimeQuery>
export type GetTimeLazyQueryHookResult = ReturnType<typeof useGetTimeLazyQuery>
export type GetTimeSuspenseQueryHookResult = ReturnType<typeof useGetTimeSuspenseQuery>
export type GetTimeQueryResult = Apollo.QueryResult<GetTimeQuery, GetTimeQueryVariables>
export const OnChangeTableStateStaffDocument = gql`
  subscription OnChangeTableStateStaff($restaurantID: ID!) {
    onChangeTableStateStaff(restaurantID: $restaurantID) {
      token
      tableID
      restaurantID
      state {
        callWaiterType
        status
        waiterCall
        billRequest
        quickOrder
        lastUpdated
        requestedAt
      }
    }
  }
`

/**
 * __useOnChangeTableStateStaffSubscription__
 *
 * To run a query within a React component, call `useOnChangeTableStateStaffSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChangeTableStateStaffSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChangeTableStateStaffSubscription({
 *   variables: {
 *      restaurantID: // value for 'restaurantID'
 *   },
 * });
 */
export function useOnChangeTableStateStaffSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnChangeTableStateStaffSubscription,
    OnChangeTableStateStaffSubscriptionVariables
  > &
    ({variables: OnChangeTableStateStaffSubscriptionVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSubscription<OnChangeTableStateStaffSubscription, OnChangeTableStateStaffSubscriptionVariables>(
    OnChangeTableStateStaffDocument,
    options
  )
}
export type OnChangeTableStateStaffSubscriptionHookResult = ReturnType<typeof useOnChangeTableStateStaffSubscription>
export type OnChangeTableStateStaffSubscriptionResult = Apollo.SubscriptionResult<OnChangeTableStateStaffSubscription>
export const OnChangeRestaurantFeaturesStaffDocument = gql`
  subscription onChangeRestaurantFeaturesStaff($restaurantID: ID!) {
    onChangeRestaurantFeatures(restaurantID: $restaurantID) {
      restaurantID
      features {
        tableActions
        tableActionsAutomatic
        lastCallBuffer
        waiterCall
        billRequest
        quickOrder
        kitchenClosed
        kitchenOpenAutomatic
        rushHour {
          enabled
          rushHourType
          delay
        }
        roomService {
          enabled
          deliveryMethods
          deliveryFee
          freeDeliveryThreshold
          delay
          paymentMethods
          pickupEnabled
          deliveryEnabled
        }
        disabledPortions {
          id
          portionOptions
        }
        tableAreas {
          areas {
            id
            name {
              ...SupportedLocales
            }
            tables
          }
        }
        tehoEnabled
        phoneNumber
      }
    }
  }
  ${SupportedLocalesFragmentDoc}
`

/**
 * __useOnChangeRestaurantFeaturesStaffSubscription__
 *
 * To run a query within a React component, call `useOnChangeRestaurantFeaturesStaffSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChangeRestaurantFeaturesStaffSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChangeRestaurantFeaturesStaffSubscription({
 *   variables: {
 *      restaurantID: // value for 'restaurantID'
 *   },
 * });
 */
export function useOnChangeRestaurantFeaturesStaffSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnChangeRestaurantFeaturesStaffSubscription,
    OnChangeRestaurantFeaturesStaffSubscriptionVariables
  > &
    ({variables: OnChangeRestaurantFeaturesStaffSubscriptionVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSubscription<
    OnChangeRestaurantFeaturesStaffSubscription,
    OnChangeRestaurantFeaturesStaffSubscriptionVariables
  >(OnChangeRestaurantFeaturesStaffDocument, options)
}
export type OnChangeRestaurantFeaturesStaffSubscriptionHookResult = ReturnType<
  typeof useOnChangeRestaurantFeaturesStaffSubscription
>
export type OnChangeRestaurantFeaturesStaffSubscriptionResult =
  Apollo.SubscriptionResult<OnChangeRestaurantFeaturesStaffSubscription>
export const OnModifyOrdersStaffDocument = gql`
  subscription OnModifyOrdersStaff($restaurantID: ID!) {
    onModifyOrdersStaff(restaurantID: $restaurantID) {
      token
      restaurantID
      bundleID
      bundledOrders {
        id
        orderItems {
          ...StaffOrderFields
        }
        state
        requestedAt
      }
      orders {
        orderItems {
          ...StaffOrderFields
        }
        requestedAt
      }
      madeOrder {
        ...StaffOrderFields
      }
    }
  }
  ${StaffOrderFieldsFragmentDoc}
`

/**
 * __useOnModifyOrdersStaffSubscription__
 *
 * To run a query within a React component, call `useOnModifyOrdersStaffSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnModifyOrdersStaffSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnModifyOrdersStaffSubscription({
 *   variables: {
 *      restaurantID: // value for 'restaurantID'
 *   },
 * });
 */
export function useOnModifyOrdersStaffSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnModifyOrdersStaffSubscription,
    OnModifyOrdersStaffSubscriptionVariables
  > &
    ({variables: OnModifyOrdersStaffSubscriptionVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSubscription<OnModifyOrdersStaffSubscription, OnModifyOrdersStaffSubscriptionVariables>(
    OnModifyOrdersStaffDocument,
    options
  )
}
export type OnModifyOrdersStaffSubscriptionHookResult = ReturnType<typeof useOnModifyOrdersStaffSubscription>
export type OnModifyOrdersStaffSubscriptionResult = Apollo.SubscriptionResult<OnModifyOrdersStaffSubscription>
export const OnChangeTimeDocument = gql`
  subscription OnChangeTime {
    onChangeTime {
      now
      builtOn
    }
  }
`

/**
 * __useOnChangeTimeSubscription__
 *
 * To run a query within a React component, call `useOnChangeTimeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChangeTimeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChangeTimeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnChangeTimeSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<OnChangeTimeSubscription, OnChangeTimeSubscriptionVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSubscription<OnChangeTimeSubscription, OnChangeTimeSubscriptionVariables>(
    OnChangeTimeDocument,
    options
  )
}
export type OnChangeTimeSubscriptionHookResult = ReturnType<typeof useOnChangeTimeSubscription>
export type OnChangeTimeSubscriptionResult = Apollo.SubscriptionResult<OnChangeTimeSubscription>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
}
export default result
