import {IconNavigationClose} from '@s-group/design-system-icons'
import {Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'react-i18next'
import useOutsideClick from '@marketplace/shared-lib/src/hooks/useOutsideClick'
import {CloseButton, FocusLockElement, LoginPopUpWrapper, Triangle} from './LoginPopUpStyles'

type LoginPopUpAreaProps = {
  children: React.ReactNode
  setVisible: Dispatch<SetStateAction<boolean>>
}

const LoginPopUpArea = ({children, setVisible}: LoginPopUpAreaProps) => {
  const {t} = useTranslation('login')
  const hidePopup = () => {
    setVisible(false)
  }
  const outsideClickRef = useOutsideClick(() => {
    hidePopup()
  })
  return (
    <LoginPopUpWrapper role='dialog' aria-modal='true' data-testid='login-popup' ref={outsideClickRef}>
      <FocusLockElement>
        <CloseButton
          icon={<IconNavigationClose />}
          onClick={hidePopup}
          variant='plain'
          sizing='medium'
          color='neutral'
          aria-label={t('icons.close')}
        />
        <Triangle />
        {children}
      </FocusLockElement>
    </LoginPopUpWrapper>
  )
}

export default LoginPopUpArea
