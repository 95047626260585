import styled, {css} from 'styled-components'

import {Quantity as SDSQuantity} from '@s-group/design-system-components'

export const PortionOptionLabelWrapper = styled.div`
  /* This style ensures that if a label requires multiple lines, the last line
   * will be right-aligned. Mostly this means that for long portion names, the
   * price will be right-aligned when the label wraps. */
  text-align-last: right;
`

export const NoWrap = styled.span`
  white-space: nowrap;
`

export const QuantityWrapper = styled.span`
  /* Intentionally left blank */
`

export const Quantity = styled(SDSQuantity)<{$disableAddition?: boolean}>`
  /* Override the default background color of the quantity button */
  background-color: ${({theme}) => theme.color.white} !important;

  .quantity-button-increase {
    ${({$disableAddition}) =>
      $disableAddition &&
      css`
        pointer-events: none;
        user-select: none;
        opacity: 0.32;
      `}
  }
`

export const RadioButtonOrCheckboxWrapper = styled.span<{$additionalRowHeght?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({$additionalRowHeght}) => $additionalRowHeght && '32px'};
`
