import {ApolloClient, ApolloLink, HttpLink} from '@apollo/client'
import {Amplify} from 'aws-amplify'
import {fetchAuthSession} from 'aws-amplify/auth'
import {AuthOptions, AUTH_TYPE, createAuthLink} from 'aws-appsync-auth-link'
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link'
import * as Sentry from '@sentry/browser'
import config from 'config'
import {sanitizeData} from 'utils/sentryUtils'
import {GetCartError} from '@marketplace/shared-lib/graphql/graphql'
import cache from './cache'
import {userApiRefreshSessionLink, userApiAuthLink} from './userApiAuthLink'

Amplify.configure(config.appsync)

const url = config.appsync.API.GraphQL.endpoint

const region = config.appsync.API.GraphQL.region ?? 'eu-north-1'

const ignoreSentryException = (errorMessage: string): boolean => {
  const filterErrorList = [GetCartError.CART_DOES_NOT_EXIST]
  for (const filterError of filterErrorList) {
    if (errorMessage.includes(filterError)) {
      return true
    }
  }
  return false
}

// Intercepts GraphQL responses to capture and report errors to Sentry for monitoring and debugging
const sentryErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.errors && response.errors.length > 0) {
      for (const error of response.errors) {
        if (ignoreSentryException(error.message)) {
          continue
        }

        Sentry.captureException(error, (scope) => {
          // set fingerprint to group GraphQL errors by operation name and error message
          scope.setFingerprint(['graphql', operation.operationName || 'unknown', error.message])
          scope.setTransactionName(`GraphQL error: ${operation.operationName}: ${error.message}`)
          scope.setExtra('operationName', operation.operationName)
          scope.setExtra('query', operation.query.loc?.source.body)
          scope.setExtra('variables', sanitizeData(operation.variables))
          scope.setExtra('errorMessage', error.message)
          scope.setExtra('path', error.path)
          return scope
        })
      }
    }
    return response
  })
})

const getSessionCredentials = async () => {
  try {
    const {credentials} = await fetchAuthSession()
    if (credentials) {
      return credentials
    }
    throw new Error('No credentials')
  } catch (e) {
    console.error(`Error fetching auth session: ${e}`)
    throw e
  }
}

const auth: AuthOptions = {
  type: AUTH_TYPE.AWS_IAM,
  credentials: getSessionCredentials,
}

const authLink = createAuthLink({url, region, auth})

const subscriptionLink = createSubscriptionHandshakeLink({url, region, auth}, new HttpLink({uri: url}))

const link = ApolloLink.from([sentryErrorLink, userApiRefreshSessionLink, userApiAuthLink, authLink, subscriptionLink])

const apolloClient = new ApolloClient({
  link,
  cache,
})

export default apolloClient
