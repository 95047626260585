import {useReactiveVar} from '@apollo/client'

import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {useEffect, useRef} from 'react'
import {useInView} from 'react-intersection-observer'
import {topNotificationsHeight} from 'state/reactiveVariables'
import {Menus} from 'types/restaurantTypes'
import {generateSectionInternalName} from '../../utils/menuUtils'
import {
  CategoryLink,
  CategoryList,
  CategoryNavigation,
  CategoryNavigationFixed,
  CategoryPill,
  Scroller,
} from './OrderStyles'

type OrderCategoryNavigationProps = {
  menus: Menus
  language: string
  handleSetActive: (activate: string) => void
  activeSection: string
}

type AllMenuItemsProps = OrderCategoryNavigationProps & {
  scrollFieldVisible?: boolean
  hidden?: boolean
  offset?: number
}

const ScrollField = ({categoryName, activeSection}: {categoryName: string; activeSection: string}) => {
  const fieldRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (activeSection === categoryName && fieldRef.current) {
      fieldRef.current?.scrollIntoView({inline: 'center'})
    }
  }, [activeSection, categoryName])
  return <div ref={fieldRef} />
}

const AllMenuItems = ({
  menus,
  activeSection,
  scrollFieldVisible,
  handleSetActive,
  language,
  hidden = false,
  offset,
}: AllMenuItemsProps) => {
  if (!menus) {
    return null
  }

  const useOffset = offset ?? 0

  return (
    <CategoryList sizing='small'>
      {menus.flatMap(({id: menuId, menuSections}, menuIndex) =>
        menuSections?.map((menuSection, menuSectionIndex: number) => {
          const categoryName = generateSectionInternalName({menuIndex, menuSectionIndex})
          const key = `category-list-${menuId}-${menuSectionIndex}`
          const isSelected = scrollFieldVisible && categoryName === activeSection
          return (
            <CategoryPill
              color='neutral'
              variant='filled'
              sizing={scrollFieldVisible ? 'medium' : 'small'}
              rounding='small'
              key={key}
              checked={isSelected}
              readOnly
              showCheckmark={false}
            >
              <CategoryLink
                isDynamic
                to={categoryName}
                spy
                smooth
                duration={300}
                offset={-40 - useOffset}
                onSetActive={handleSetActive}
                role='link'
                aria-hidden={hidden}
              >
                {getLocalized(menuSection.name, language)}
                {!!scrollFieldVisible && <ScrollField categoryName={categoryName} activeSection={activeSection} />}
              </CategoryLink>
            </CategoryPill>
          )
        })
      )}
    </CategoryList>
  )
}

const OrderCategoryNavigation = ({menus, language, handleSetActive, activeSection}: OrderCategoryNavigationProps) => {
  const {ref, inView} = useInView({
    threshold: 0,
    initialInView: true,
    fallbackInView: true,
  })

  const notificationsHeight = useReactiveVar(topNotificationsHeight) ?? 0

  if (!menus || menus.length === 0) {
    return null
  }

  return (
    <>
      <CategoryNavigation ref={ref} data-testid='category-navigation-top'>
        <AllMenuItems
          menus={menus}
          language={language}
          activeSection={activeSection}
          handleSetActive={handleSetActive}
          offset={notificationsHeight}
        />
      </CategoryNavigation>
      <Scroller top={notificationsHeight}>
        <CategoryNavigationFixed hide={inView} data-testid='category-navigation-fixed'>
          <AllMenuItems
            menus={menus}
            language={language}
            activeSection={activeSection}
            handleSetActive={handleSetActive}
            scrollFieldVisible
            hidden={inView}
            offset={notificationsHeight}
          />
        </CategoryNavigationFixed>
      </Scroller>
    </>
  )
}

export default OrderCategoryNavigation
