import {BrandWrapper} from '@s-group/design-system-components'
import '@s-group/design-system-components/dist/styles/styles.css'
import '@s-group/design-system-icons/dist/styles/styles.css'
import '@s-group/design-system-tokens/web/assets/fonts/SBonusDisplay.css'
import '@s-group/design-system-tokens/web/assets/fonts/SBonusUX.css'
import '@s-group/design-system-tokens/web/tokens/colors.css'
import '@s-group/design-system-tokens/web/tokens/font.css'
import '@s-group/design-system-tokens/web/tokens/raflaamo/colors.css'
import '@s-group/design-system-tokens/web/tokens/size.css'
import '@s-group/design-system-tokens/web/tokens/space.css'
import {ReactNode} from 'react'

const SDSThemeProvider = ({children}: {children: ReactNode}) => <BrandWrapper theme='raflaamo'>{children}</BrandWrapper>

export default SDSThemeProvider
