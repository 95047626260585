import {welcomeRecommendationFlagKey} from '@marketplace/shared-lib/src/launchDarkly/flagKeys'
import useFlagValue from '@marketplace/shared-lib/src/launchDarkly/useFlagValue'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Button} from '@s-group/design-system-components'
import {PortionWithMenuID} from 'Components/Product/productCardUtils'
import WelcomeRecommendationProductCard from 'Components/Product/WelcomeRecommendationProductCard'
import {useWelcomeRecommendations} from 'hooks/useRecommendations'
import useTriggerEvent from 'hooks/useTriggerEvent'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import useTriggerViewPromotionEvent from 'hooks/useTriggerViewPromotionEvent'
import useUpdateCartMutation from 'hooks/useUpdateCartMutation'
import {useLDClient} from 'launchdarkly-react-client-sdk'
import {Fragment, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useSearchParams} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import ModalContext from 'state/ModalContext'
import {color} from 'theme'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {EventItemContext} from 'types/analyticsTypesNew'
import {mapPortionToEventItem} from 'utils/analytics'
import {getLowestPortionPriceAndSize} from 'utils/portionPriceUtils'
import Divider from '../Divider/Divider'
import {ModalBottomState} from '../ModalBottom/modalBottomTypes'
import {
  ButtonWrapper,
  WelcomeRecommendationsHeading,
  WelcomeRecommendationsWrapper,
} from './WelcomeRecommendationsStyles'

const WelcomeRecommendations = () => {
  const [, setSearchParams] = useSearchParams()
  const ldClient = useLDClient()
  const {t, i18n} = useTranslation('welcome')
  const locale = i18n.language
  const {setModalBottomState} = useContext(ModalContext)
  const navigate = useNavigate()

  // LD flag to open portion details in welcome recommendations
  const openPortionDetailsInWelcomeRecommendations = useFlagValue({
    key: welcomeRecommendationFlagKey,
    defaultValue: false,
  })

  const welcomeRecommendationsHeaderNoRecommendations = t('welcomeRecommendationsHeaderNoRecommendations')
  const noThanksButtonText = t('noThanks')
  const yesPleaseButtonText = t('yesPlease')
  const orderButtonText = t('order')

  const recommendations = useWelcomeRecommendations()
  const dynamicWelcomeRecommendationsHeader = getLocalized(recommendations?.header, locale)
  const welcomeRecommendationsHeader =
    dynamicWelcomeRecommendationsHeader !== '' ? dynamicWelcomeRecommendationsHeader : t('welcomeRecommendationsHeader')
  const {cartModify, loading: cartLoading} = useUpdateCartMutation()

  const {triggerEvent} = useTriggerEvent()
  const triggerEventNew = useTriggerEventNew()

  useTriggerViewPromotionEvent(recommendations?.portions, {
    promotion_name: welcomeRecommendationsHeader,
    creative_name: 'welcome_recommendations',
  })

  const handleOnAddItem = (portion: PortionWithMenuID, index: number) => {
    const price = getLowestPortionPriceAndSize(portion).lowestNormal.toString(10)
    const analytics: EventItemContext = {
      index,
      promotion_name: welcomeRecommendationsHeader,
      creative_name: 'welcome_recommendations',
    }
    triggerEventNew?.({
      event: 'select_promotion',
      ecommerce: {
        items: [{...mapPortionToEventItem(portion), ...analytics}],
      },
    })
    const items = [{portion, analytics, menuID: portion.menuID}]

    // Open the product details modal to show product details and add to cart
    if (openPortionDetailsInWelcomeRecommendations) {
      const params = new URLSearchParams([
        ['portion', portion.id],
        ['menu', portion.menuID],
        ['welcome-recommendation', 'true'],
      ])
      return setSearchParams(params, {replace: true})
    }

    return cartModify({
      items,
      successCallback: () => {
        triggerEvent({
          event: AnalyticsEvents.ADD_TO_CART,
          action: AnalyticsActions.ADD_TO_CART_RECOMMENDATIONS_WELCOME,
          items: [{item_name: portion.name.fi, quantity: 1, price}],
        })
        ldClient?.track('welcome-recommendations-added-to-cart')
        setModalBottomState(ModalBottomState.Closed)
        navigate(NavigationRoute.Summary)
      },
    })
  }

  const handleNoThanks = () => {
    setModalBottomState(ModalBottomState.Closed)
  }

  const handleYesPlease = () => {
    setModalBottomState(ModalBottomState.Closed)
    navigate(NavigationRoute.Menu)
  }

  const noThanksButtonTestId = 'welcome-recommendations-no-thanks-button'

  if (!recommendations?.portions.length) {
    return (
      <WelcomeRecommendationsWrapper>
        <WelcomeRecommendationsHeading
          variant='display'
          sizing='xxxxsmall'
          weight='semibold'
          role='heading'
          aria-level={1}
        >
          {welcomeRecommendationsHeaderNoRecommendations}
        </WelcomeRecommendationsHeading>
        <ButtonWrapper>
          <Button color='neutral' variant='filled' sizing='small' rounding='small' onClick={handleYesPlease}>
            {yesPleaseButtonText}
          </Button>
          <Button
            variant='plain'
            sizing='small'
            rounding='small'
            onClick={handleNoThanks}
            data-testid={noThanksButtonTestId}
          >
            {noThanksButtonText}
          </Button>
        </ButtonWrapper>
      </WelcomeRecommendationsWrapper>
    )
  }

  return (
    <WelcomeRecommendationsWrapper>
      <WelcomeRecommendationsHeading
        variant='display'
        sizing='xxxxsmall'
        weight='semibold'
        role='heading'
        aria-level={1}
      >
        {welcomeRecommendationsHeader}
      </WelcomeRecommendationsHeading>
      <Divider color={color.border.mediumSecondary} />
      {recommendations.portions.map((portion, index) => (
        <Fragment key={portion.id}>
          <WelcomeRecommendationProductCard
            portion={portion}
            onAddItem={() => handleOnAddItem(portion, index)}
            loading={cartLoading}
            ctaText={orderButtonText}
          />
          <Divider color={color.border.mediumSecondary} />
        </Fragment>
      ))}
      <Button
        variant='plain'
        sizing='small'
        rounding='small'
        onClick={handleNoThanks}
        data-testid={noThanksButtonTestId}
      >
        {noThanksButtonText}
      </Button>
    </WelcomeRecommendationsWrapper>
  )
}

export default WelcomeRecommendations
