import {Link, Text} from '@s-group/design-system-components'
import {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useLocation} from 'react-router'
import {NotificationBannerWrapper} from './NotificationBannerStyles'

type LinkProps = {
  aoa: string
  mara: string
}

// exported for testing purposes
export const getLinks = (lang?: string): LinkProps => {
  switch (lang) {
    case 'en':
      return {
        mara: 'https://www.sokoshotels.fi/en/privacy-policy/customer-register',
        aoa: 'https://www.s-kanava.fi/en/s-groups-co-op-member-and-customer-register/',
      }
    case 'sv':
      return {
        mara: 'https://www.sokoshotels.fi/sv/registerdeklaration/kundregister',
        aoa: 'https://www.s-kanava.fi/sv/s-gruppens-agarkunds-och-kundregister/',
      }
    default:
      return {
        mara: 'https://www.sokoshotels.fi/tietosuoja/asiakasrekisteri',
        aoa: 'https://www.s-kanava.fi/asiakasomistaja-ja-asiakasrekisteri/',
      }
  }
}

const MaraRegistryNotification = () => {
  const {t, i18n} = useTranslation('login')
  const location = useLocation()
  const {aoa, mara} = getLinks(i18n.language)
  const [visibility, setVisibility] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.get('newUser') === 'true') {
      setVisibility(true)
    }
  }, [location])

  if (!visibility) {
    return null
  }

  return (
    <NotificationBannerWrapper
      status='success'
      variant='tonal'
      sizing='small'
      close
      data-testid='notification-banner-primary-maraRegistryNotification'
      key='maraRegistryNotification'
    >
      <Text variant='body' sizing='small' data-testid='mara-registry-notification'>
        <Trans
          i18nKey='maraRegistryNotificationText'
          t={t}
          components={{
            aoaLink: <Link color='neutral' href={aoa} target='_blank' data-testid='aoa-link' />,
            maraLink: <Link color='neutral' href={mara} target='_blank' data-testid='mara-link' />,
          }}
        />
      </Text>
    </NotificationBannerWrapper>
  )
}

export default MaraRegistryNotification
