export enum ModalState {
  OrderFinished = 'orderFinished',
  WaiterCalled = 'waiterCalled',
  WaiterCallCancelled = 'waiterCallCancelled',
  RequestBillCancelled = 'requestBillCancelled',
  BillRequested = 'billRequested',
  CallWaiterConfirmationStep = 'callWaiterConfirmationStep',
  BillOptions = 'billOptions',
  Closed = 'closed',
  Error = 'error',
  TableDisabled = 'tableDisabled',
  PickupOrderFinished = 'pickupOrderFinished',
}
