import {useReactiveVar} from '@apollo/client'
import {useHasUserInteracted} from '@s-group/react-usercentrics'
import WelcomeRecommendations from 'Components/WelcomeRecommendations/WelcomeRecommendations'
import {useCallback, useContext, useLayoutEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router'
import ModalContext from 'state/ModalContext'
import {modalInitiatorRefVar} from 'state/reactiveVariables'
import {remoteTheme} from 'theme'
import Modal from '../Modal/ModalBottom'
import ProductDetails from '../Product/ProductDetails'
import {ModalBottomState} from './modalBottomTypes'

const ModalBottom = () => {
  const {t} = useTranslation('order')
  const {modalBottomState, setModalBottomState} = useContext(ModalContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const currentRefVar = useReactiveVar(modalInitiatorRefVar)
  const isCookieConsentNoted = useHasUserInteracted()

  const handleClose = useCallback(() => {
    setSearchParams([], {replace: true})
    if (modalBottomState === ModalBottomState.ProductDetails && currentRefVar) {
      window.setTimeout(() => currentRefVar.current?.focus(), 300)
    }
    setModalBottomState(ModalBottomState.Closed)
  }, [currentRefVar, modalBottomState, setModalBottomState, setSearchParams])

  // make sure modal closes when search params are removed for any reason (e.g. back press)
  useLayoutEffect(() => {
    if (
      modalBottomState === ModalBottomState.ProductDetails &&
      (!searchParams.get('portion') || !searchParams.get('menu'))
    ) {
      setModalBottomState(ModalBottomState.Closed)
    }
  }, [handleClose, modalBottomState, searchParams, setModalBottomState])

  if (modalBottomState === ModalBottomState.ProductDetails) {
    return (
      <Modal
        isOpen={isCookieConsentNoted}
        onClose={handleClose}
        maxWidth={remoteTheme.currentPageTheme.contentMaxWidth}
        background={remoteTheme.color.background.weakSecondary}
        showCloseButton
        noPadding
        alt={t('menu.portionDetails')}
      >
        <ProductDetails
          portionID={searchParams.get('portion') ?? ''}
          menuID={searchParams.get('menu') ?? ''}
          editMode={searchParams.get('edit') === 'true'}
          cartItemID={searchParams.get('cart-item-id') ?? ''}
          isWelcomeRecommendation={searchParams.get('welcome-recommendation') === 'true'}
          addToCartCallback={handleClose}
        />
      </Modal>
    )
  }

  if (modalBottomState === ModalBottomState.WelcomeRecommendations)
    return (
      <Modal
        isOpen={isCookieConsentNoted}
        onClose={handleClose}
        maxWidth='100%'
        showCloseButton={false}
        alt={t('menu.portionRecommendation')}
      >
        <WelcomeRecommendations />
      </Modal>
    )

  return <div />
}

export default ModalBottom
