import {Text} from '@s-group/design-system-components'
import {AutoFocusInside} from 'react-focus-lock'
import styled from 'styled-components'

export const WelcomeMain = styled.div`
  background-color: ${({theme}) => theme.color.white};
  border-radius: 4px;
  display: block;
  width: 100%;
  text-align: center;
`

export const WelcomeTextWrapper = styled(AutoFocusInside)`
  padding: ${({theme}) => theme.spacingCustom.medium};
  text-align: center;
`

export const WelcomeHeader = styled(Text)`
  border-radius: 4px 4px 0 0;
  display: grid;
  background-color: ${({theme}) => theme.color.background.weakSecondary};
  padding: ${({theme}) => theme.spacing.medium};
  text-transform: uppercase;
  margin: 0;
`
