import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {Text} from '@s-group/design-system-components'
import Divider from 'Components/Divider/Divider'
import {useTranslation} from 'react-i18next'
import {remoteTheme} from 'theme'
import {FeedbackLink, FeedbackWrapper} from './FeedbackStyles'

const Feedback = ({
  tableType,
  feedbackDescription,
  compensateDividerPadding,
}: {
  tableType?: TableType
  feedbackDescription?: string
  compensateDividerPadding?: string
}) => {
  const {t} = useTranslation('remotePage')
  const feedbackHref = tableType === TableType.PICKUP ? t('takeawayFeedbackUrl') : t('feedbackUrl')
  const description = feedbackDescription || t('feedbackDescription')

  return (
    <>
      <Divider color={remoteTheme.color.background.mediumSecondary} compensatePadding={compensateDividerPadding} />
      <FeedbackWrapper data-testid='feedback-container'>
        <Text variant='body' sizing='small'>
          {description}
        </Text>
        <FeedbackLink href={feedbackHref} external visitedStyle={false}>
          {t('feedbackLink')}
        </FeedbackLink>
      </FeedbackWrapper>
    </>
  )
}

export default Feedback
