// used for flagging s-id feature on/off in LD
export const sidLoginFlagKey = 'feature-flag-sid-login'

/**
 * used for flagging welcome recommendations features on/off in LD
 *
 * When ON:
 * the user will see only lowest price in the welcome recommendations
 * and portion details will be opened from order button
 *
 * When OFF:
 * the user will see both coop and normal prices and portion size (if available)
 * in the welcome recommendations.
 * Portion will be added to cart when user clicks on the order button
 */
export const welcomeRecommendationFlagKey = 'feature-flag-welcome-recommendation-a-b-testing'
