import {PortionFieldsFragment, PortionOptionSectionType, PriceType} from '@marketplace/shared-lib/graphql/graphql'
import {formatPricePlainText} from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'

export const joinPortionOptions = (accumulator: string, item: string, index: number) => {
  if (!item) {
    return accumulator
  }
  if (index === 0) {
    return item
  }
  if (accumulator.includes(item)) {
    return accumulator
  }
  return `${accumulator}, ${item}`
}

export const getPortionOptionsText = (
  portion: Omit<PortionFieldsFragment, 'images' | 'recommendations'>,
  currentLanguage: string
) =>
  portion?.portionOptionSections?.map((section) => {
    if (section?.type === PortionOptionSectionType.HIDDEN) {
      return {portionOptionName: '', portionOptionValues: []}
    }
    const portionOptionValues = section?.portionOptions?.flatMap(({label, price, id}) => {
      const selectionQuantity = section?.portionOptions.filter((option) => option.id === id).length
      const selectionQuantityString = selectionQuantity && selectionQuantity > 1 ? `${selectionQuantity} × ` : ''

      if (price && price.type === PriceType.ADDITIONAL) {
        return `${selectionQuantityString}${getLocalized(label, currentLanguage)} (${formatPricePlainText(price.normal ?? 0, true)})`
      }
      return `${selectionQuantityString}${getLocalized(label, currentLanguage)}`
    })
    const reducePortionOptions = portionOptionValues?.reduce(joinPortionOptions).split(', ')
    const portionOptionName = getLocalized(section?.name, currentLanguage)
    return {portionOptionName, portionOptionValues: reducePortionOptions || []}
  })
