import {Customer} from '@marketplace/shared-lib/graphql/graphql'
import {isProduction} from '@marketplace/shared-lib/src/utils/envUtils'
import {formatCurrency, formatPercentage} from '@marketplace/shared-lib/src/utils/numberUtils'
import {BrandWrapper, Link} from '@s-group/design-system-components'
import '@s-group/design-system-tokens/web/tokens/raflaamo/colors.css'
import {useTranslation} from 'react-i18next'
import {CurrentBonusAmount, NoIconWrapper, PopUpHeading, PopupRow, PopUpText} from '../Login/LoginPopUpStyles'

type CustomerData = NonNullable<Customer>

type IncomingBonusProps = {
  bonusInformation?: CustomerData['benefitsAndPurchases']
}

const userPortalLinkProd = 'https://s-kayttajatili.fi/asiakasomistajuus/bonus'
const userPortalLinkTest = 'https://test.s-kayttajatili.fi/asiakasomistajuus/bonus'
const linkToUserProfile = isProduction ? userPortalLinkProd : userPortalLinkTest

/**
 * This component populates bonusinformation for customer with S-Card.
 * If no data is available assume no bonus gathered yet.
 * @param bonusInformation this is an array but the first item (if any) should contain current months bonus info
 * @returns React component with populated values
 */
const IncomingBonus = ({bonusInformation}: IncomingBonusProps) => {
  const {t} = useTranslation('login')
  const periodBonusBenefitAmount = bonusInformation?.[0].periodBonusBenefitAmount ?? 0
  const bonusLevel = bonusInformation?.[0].periodBonusPercent ?? 0
  return (
    <PopupRow>
      <NoIconWrapper>
        <PopUpHeading tagName='h3' sizing='xxsmall' weight='bold' data-testid='current-bonus-amount-header'>
          {t('currentBonusAmount')}
        </PopUpHeading>
        <CurrentBonusAmount
          tagName='h3'
          variant='heading'
          weight='bold'
          sizing='large'
          data-testid='current-bonus-amount'
        >
          {formatCurrency(periodBonusBenefitAmount * 100)}
        </CurrentBonusAmount>
        <PopUpText variant='body' sizing='small' data-testid='current-bonus-level'>
          {`${t('bonusLevel')}\u00A0`}
          {formatPercentage(bonusLevel)}
        </PopUpText>
        <BrandWrapper theme='sbrand'>
          <PopUpText variant='body' sizing='small'>
            <Link
              href={linkToUserProfile}
              target='_blank'
              external
              standalone
              color='primary'
              data-testid='link-to-bonus-details'
            >
              {`${t('seeDetails')}\u00A0`}
            </Link>
          </PopUpText>
        </BrandWrapper>
      </NoIconWrapper>
    </PopupRow>
  )
}

export default IncomingBonus
