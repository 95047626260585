import styled, {css} from 'styled-components'
import {WhiteText} from 'styles/GeneralStyles'

const floatingCartPadding = css`
  padding: ${({theme}) =>
    `${theme.spacing.small} ${theme.spacingCustom.medium} ${theme.spacingCustom.large} ${theme.spacingCustom.medium}`};
`

export const FloatingCartContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 100px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.color.black};
  justify-content: space-between;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  z-index: 9000;
  ${floatingCartPadding};
`

export const CartSummary = styled(WhiteText)`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`

export const CartSummaryItems = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const StatusWrapper = styled.div`
  word-break: break-word;
  padding-right: ${({theme}) => theme.spacing.xxxsmall};
`
