import {useReactiveVar} from '@apollo/client'
import {OrderState, TableStatusType, TableType} from '@marketplace/shared-lib/graphql/graphql'
import {usePrevious} from '@marketplace/shared-lib/src/hooks'
import {
  convertLocalStorageStringToBoolean,
  readLocalStorage,
  writeLocalStorage,
} from '@marketplace/shared-lib/src/utils/localStorage'
import {isOrderingPossible} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import {ModalState} from 'Components/Modal/modalTypes'
import {ModalBottomState} from 'Components/ModalBottom/modalBottomTypes'
import {useContext, useEffect} from 'react'
import {useLocation, useNavigate, useSearchParams} from 'react-router'
import ModalContext from 'state/ModalContext'
import {cartID, customerFullAge} from 'state/reactiveVariables'
import {remoteWelcomeRecommationsShown} from 'utils/constants'
import {includesAlcoholPortions} from 'utils/menuUtils'
import {getPendingOrders} from 'utils/order'
import {NavigationRoute} from '../routes/routeConstants'
import useFilteredMenus from './useFilteredMenus'
import {useWelcomeRecommendations} from './useRecommendations'
import useRestaurantContext from './useRestaurantContext'

const {PICKUP, OPEN_ROOM, ROOM, RESTAURANT_SELECTION} = TableType
const {PickupOrderFinished, OrderFinished} = ModalState
const {IDLE, IN_PROGRESS} = OrderState

const useModalToggler = () => {
  const {tableData, restaurantData, restaurantFeatures} = useRestaurantContext()
  const currentCartID = useReactiveVar(cartID)
  const currentCustomerFullAge = useReactiveVar(customerFullAge)
  const {setModalState, setModalBottomState} = useContext(ModalContext)
  const [searchParams] = useSearchParams()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const recommendations = useWelcomeRecommendations()

  const {pendingOrders, bundledPendingOrders} = getPendingOrders(tableData, currentCartID)
  const hasPendingOrders = pendingOrders && pendingOrders.length > 0
  const hasBundledPendingOrders = bundledPendingOrders && bundledPendingOrders.length > 0
  const hasAnyOrders = hasPendingOrders || hasBundledPendingOrders

  const currentMultipleOrderState = !hasAnyOrders ? IDLE : IN_PROGRESS
  const prevMultipleOrderState = usePrevious<OrderState>(currentMultipleOrderState)
  const tableType = tableData?.getTable.tableType

  const table = tableData?.getTable
  const weekOpeningTimes = restaurantData?.getRestaurant.weekOpeningTimes
  const menus = useFilteredMenus()

  const menusIncludeAlcoholPortions = includesAlcoholPortions(menus)

  const orderingPossible = isOrderingPossible({
    table,
    restaurantFeatures,
    weekOpeningTimes,
  })

  const tableStatus = table?.state.status

  // Show Order finished modal when order is ready
  useEffect(() => {
    if (prevMultipleOrderState === IN_PROGRESS && currentMultipleOrderState === IDLE) {
      if (tableType === PICKUP) {
        setModalState(PickupOrderFinished)
        return
      }

      setModalState(OrderFinished)
      // When order is completed, navigate to Home if on OrderConfirmation page
      if (pathname.startsWith(NavigationRoute.OrderConfirmation.toString())) {
        navigate(NavigationRoute.Home, {replace: true})
      }
    }
  }, [setModalState, prevMultipleOrderState, currentMultipleOrderState, pathname, tableType, navigate])

  const welcomeRecommendationsShown = convertLocalStorageStringToBoolean(
    readLocalStorage(remoteWelcomeRecommationsShown)
  )

  // Show welcome recommendations when entering the app
  useEffect(() => {
    // Recommentations not displayed if already shown previously
    // Recommendations not displayed if age dialog has not been answered and menus include alcohol portions
    // Recommendations not displayed if room service or take-away
    // Recommendations not displayed if they are not available
    // Recommendations not displayed if ordering is not currently possible
    if (
      welcomeRecommendationsShown !== true &&
      (!menusIncludeAlcoholPortions || currentCustomerFullAge !== undefined) &&
      orderingPossible &&
      tableType &&
      ![RESTAURANT_SELECTION, OPEN_ROOM, ROOM, PICKUP].includes(tableType) &&
      recommendations.portions.length > 0 &&
      tableStatus === TableStatusType.IDLE
    ) {
      writeLocalStorage(remoteWelcomeRecommationsShown, 'true')
      setModalBottomState(ModalBottomState.WelcomeRecommendations)
      // If table is disabled, close the modal
    } else if (tableStatus === TableStatusType.DISABLED) {
      setModalBottomState(ModalBottomState.Closed)
    }
  }, [
    setModalBottomState,
    orderingPossible,
    currentCustomerFullAge,
    menusIncludeAlcoholPortions,
    tableType,
    tableStatus,
    recommendations.portions.length,
    welcomeRecommendationsShown,
  ])

  // Show Product details modal when portion is present in search params
  useEffect(() => {
    // Check that restaurantData is fetched before opening
    if (searchParams.get('portion') && restaurantData) {
      setModalBottomState(ModalBottomState.ProductDetails)
    }
  }, [restaurantData, searchParams, setModalBottomState])
}

export default useModalToggler
