import {DeliveryMethod, PaymentMethod} from '@marketplace/shared-lib/graphql/graphql'
import {formatPricePlainText} from '@marketplace/shared-lib/src/utils/formatPrice'
import {Form, Text} from '@s-group/design-system-components'
import {IconStatusWarning} from '@s-group/design-system-icons'
import {colors} from '@s-group/design-system-tokens/web/tokens/raflaamo/theme'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import RadioButtonOrCheckbox from 'Components/SelectionBox/RadioButtonOrCheckbox'
import useCartUtils from 'hooks/useCartUtils'
import useCreateOrderMutation from 'hooks/useCreateOrderMutation'
import useCustomerDetails from 'hooks/useCustomerDetails'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useUpdateCartMutation from 'hooks/useUpdateCartMutation'
import {useEffect, useMemo, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import {color, spacingCustom} from 'theme'
import {deliveryPortion, getLoyaltyProgramOptions} from 'utils/cart'
import {ExtendedCustomerDetails, trimPhoneNumber, validatePhoneNumber, validateText} from 'utils/order'
import CartRecommendations from './CartRecommendations'
import DeliveryItem from './DeliveryItem'
import LoyaltyProgramSelection from './LoyaltyProgramSelection'
import {Title} from './OrderStyles'
import PaymentMethodSelection from './PaymentMethodSelection'
import SummaryHeader from './SummaryHeader'
import SummaryItems from './SummaryItems'
import SummaryOrderSubmission from './SummaryOrderSubmission'
import {
  DeliveryMethodSelector,
  InputField,
  InputFieldHelperText,
  InputFieldLabel,
  PickupDisclaimer,
  PreparationTimeDisclaimer,
  SectionContainer,
  SummaryFormRow,
  SummaryWrapper,
  TermsLink,
} from './SummaryStyles'
import SummaryTotal from './SummaryTotal'

const minNameLen = 1

/**
 * Summary/cart for room service orders.
 *
 * See also:
 * - SummaryDefault.tsx
 * - SummaryTakeaway.tsx
 */
const SummaryRoomService = () => {
  const {t} = useTranslation('order')
  const {tableData, restaurantData, restaurantFeatures, cartData} = useRestaurantContext()
  const {token, restaurantID, tableType, tableID} = tableData?.getTable || {}
  const restaurantName = restaurantData?.getRestaurant.name.fi
  const {cartItems, totalPrice, totalQuantity, isFoodOrderBlockedDueToKitchenClosed, orderContainsAlcohol} =
    useCartUtils()

  // use previous customer details as default values
  const customerDetails = useCustomerDetails()

  const setOrderProps = {
    restaurantID,
    token,
    restaurantName,
    tableID,
    tableType,
  }

  const {cartModify, loading: cartLoading} = useUpdateCartMutation()
  const {submitOrder, loading: submitOrderLoading} = useCreateOrderMutation(setOrderProps)
  const navigate = useNavigate()
  const [navigateToMenu, setNavigateToMenu] = useState(false)

  // loyalty program value is defined for room service orders only
  const availableLoyaltyProgramOptions = getLoyaltyProgramOptions(restaurantID)
  // payment method is only defined for room service orders
  const availablePaymentMethods = useMemo(() => {
    return restaurantFeatures?.roomService?.paymentMethods ?? [PaymentMethod.CARD, PaymentMethod.ROOM_BILL]
  }, [restaurantFeatures?.roomService?.paymentMethods])

  const [nameValid, setNameValid] = useState(false)
  const [firstNameVisited, setFirstNameVisited] = useState(false)
  const [lastNameValid, setLastNameValid] = useState(false)
  const [lastNameVisited, setLastNameVisited] = useState(false)
  const [phoneVisited, setPhoneVisited] = useState(false)
  const [phoneValid, setPhoneValid] = useState(false)
  const [roomServiceCustomerDetails, setRoomServiceCustomerDetails] = useState<ExtendedCustomerDetails>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    paymentMethod: undefined,
    loyaltyProgram: undefined,
  })

  const {firstName, lastName, phoneNumber, paymentMethod, loyaltyProgram} = roomServiceCustomerDetails ?? {}

  const onEditCustomerDetails = (key: keyof ExtendedCustomerDetails, value: string | boolean) =>
    setRoomServiceCustomerDetails({...roomServiceCustomerDetails, [key]: value})

  const {enabled: rushHourEnabled, delay: rushHourDelay} = restaurantFeatures?.rushHour ?? {}
  const {
    enabled: roomServiceEnabled,
    deliveryMethods,
    deliveryFee,
    freeDeliveryThreshold,
    delay: roomServiceDelay = 15,
  } = restaurantFeatures?.roomService ?? {}
  const deliveryEnabled = deliveryMethods?.includes(DeliveryMethod.DELIVERY)
  const pickupEnabled = deliveryMethods?.includes(DeliveryMethod.PICKUP)
  const totalRoomServiceDelay = rushHourEnabled && rushHourDelay ? roomServiceDelay + rushHourDelay : roomServiceDelay

  // because of legislation, alcohol pickup is not allowed for room service orders
  const pickupAllowed = pickupEnabled && !orderContainsAlcohol
  // delivery is allowed for room service orders even if alcohol is included
  const pickupOrDeliveryAllowed = pickupAllowed || deliveryEnabled

  let initialDelivery: '' | 'pickup' | 'room'
  const deliveryChoice = pickupEnabled && deliveryEnabled
  if (deliveryChoice) {
    initialDelivery = ''
  } else {
    initialDelivery = pickupEnabled ? 'pickup' : 'room'
  }
  const [deliveryType, setDeliveryType] = useState(initialDelivery)

  const cartTotal = cartData?.getCart.total.normal
  const missingRoomOrderDetails = !phoneValid || !nameValid || !deliveryType || !loyaltyProgram || !paymentMethod
  const actualDeliveryFee = freeDeliveryThreshold && cartTotal && cartTotal >= freeDeliveryThreshold ? 0 : deliveryFee

  const submitIsDisabled =
    cartLoading ||
    totalQuantity === 0 ||
    submitOrderLoading ||
    !pickupOrDeliveryAllowed ||
    missingRoomOrderDetails ||
    isFoodOrderBlockedDueToKitchenClosed ||
    (!pickupAllowed && deliveryType === 'pickup')

  useEffect(() => {
    setNameValid(validateText(minNameLen, firstName))
    setLastNameValid(validateText(minNameLen, lastName))
    setPhoneValid(validatePhoneNumber(phoneNumber))

    if (!deliveryEnabled) {
      setDeliveryType('pickup')
    }
    if (!pickupEnabled) {
      setDeliveryType('room')
    }
  }, [firstName, lastName, phoneNumber, tableType, deliveryEnabled, pickupEnabled, setDeliveryType])

  useEffect(() => {
    if (availablePaymentMethods.length === 1) {
      setRoomServiceCustomerDetails({...roomServiceCustomerDetails, paymentMethod: availablePaymentMethods[0]})
    }
  }, [availablePaymentMethods, roomServiceCustomerDetails])

  const handleSubmitOrder = () => {
    const portion = deliveryPortion(t, deliveryType, actualDeliveryFee)
    const items = [{portion}]
    cartModify({
      items,
      successCallback: () =>
        submitOrder({
          nickName: firstName,
          lastName,
          phoneNumber: trimPhoneNumber(phoneNumber),
          loyaltyProgram,
          paymentMethod,
        }),
    })
  }

  useEffect(() => {
    if (navigateToMenu) {
      navigate(NavigationRoute.Menu)
    }

    return () => {
      setNavigateToMenu(false)
    }
  }, [navigateToMenu, navigate])

  useEffect(() => {
    if (!customerDetails.isLoading) {
      setRoomServiceCustomerDetails((prev) => ({...prev, ...customerDetails.details}))
    }
  }, [customerDetails])

  // Wait until initial customer details retrieved
  if (customerDetails.isLoading) {
    return <LoadingScreen />
  }

  return (
    <SummaryWrapper>
      <SummaryHeader tableType={tableType} />
      <SummaryItems cartItems={cartItems} />
      {deliveryType === 'room' && deliveryFee && (
        <DeliveryItem normalFee={deliveryFee} discountedFee={actualDeliveryFee} />
      )}
      <SummaryTotal totalPrice={totalPrice} />
      {roomServiceEnabled && (
        <>
          <CartRecommendations />
          <SectionContainer>
            <Title data-testid='summary-title' variant='heading' sizing='xxsmall' weight='medium'>
              {t('summary.orderInfo')}
            </Title>
          </SectionContainer>
          <Form>
            <SummaryFormRow>
              <InputFieldLabel sizing='small' htmlFor='roomNumber'>
                {t('summary.roomNumber')}
              </InputFieldLabel>
              <Text variant='heading' sizing='xxxsmall' id='roomNumber'>
                {tableID}
              </Text>
            </SummaryFormRow>
            <SummaryFormRow>
              <InputFieldLabel data-testid='first-name-input-label' sizing='small' htmlFor='firstNameContainer'>
                {t('summary.firstNameLabel')}
              </InputFieldLabel>
              <InputField
                value={firstName ?? ''}
                sizing='small'
                id='firstNameContainer'
                aria-required
                onChange={(e) => onEditCustomerDetails('firstName', e.target.value)}
                onBlur={() => setFirstNameVisited(true)}
                data-testid='first-name-input'
                error={firstNameVisited && !nameValid}
                autoFocus
              />
              {firstNameVisited && !nameValid && (
                <InputFieldHelperText
                  data-testid='first-name-input-error'
                  sizing='small'
                  color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}
                >
                  {t('summary.firstNameError')}
                </InputFieldHelperText>
              )}
            </SummaryFormRow>
            <SummaryFormRow>
              <InputFieldLabel data-testid='last-name-input-label' sizing='small' htmlFor='lastNameContainer'>
                {t('summary.lastNameLabel')}
              </InputFieldLabel>
              <InputField
                value={lastName ?? ''}
                sizing='small'
                id='lastNameContainer'
                aria-required
                onChange={(e) => onEditCustomerDetails('lastName', e.target.value)}
                onBlur={() => setLastNameVisited(true)}
                data-testid='last-name-input'
                error={lastNameVisited && !lastNameValid}
              />
              {lastNameVisited && !lastNameValid && (
                <InputFieldHelperText
                  data-testid='last-name-input-error'
                  sizing='small'
                  color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}
                >
                  {t('summary.lastNameError')}
                </InputFieldHelperText>
              )}
            </SummaryFormRow>
            <SummaryFormRow>
              <InputFieldLabel data-testid='phone-number-input-label' sizing='small' htmlFor='phoneNumberContainer'>
                {t('summary.phoneNumberLabel')}
              </InputFieldLabel>
              <InputField
                value={phoneNumber ?? ''}
                sizing='small'
                id='phoneNumberContainer'
                type='tel'
                aria-required
                onChange={(e) => onEditCustomerDetails('phoneNumber', e.target.value)}
                onBlur={() => setPhoneVisited(true)}
                data-testid='phone-number-input'
                error={phoneVisited && !phoneValid}
              />
              {phoneVisited && !phoneValid && (
                <InputFieldHelperText
                  data-testid='phone-number-input-error'
                  sizing='small'
                  color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}
                >
                  {t('summary.phoneError')}
                </InputFieldHelperText>
              )}
            </SummaryFormRow>
            <SummaryFormRow grid={spacingCustom.medium}>
              <DeliveryMethodSelector>
                <Text variant='heading' sizing='xxxsmall' weight='medium'>
                  <Trans i18nKey={deliveryChoice ? 'summary.deliveryChoice' : 'summary.deliveryNoChoice'} ns='order' />
                </Text>
                {pickupEnabled && (
                  <>
                    <RadioButtonOrCheckbox
                      value='asap'
                      buttonType='radio'
                      label={
                        <Text variant='body' sizing='small' weight={deliveryType === 'pickup' ? 'medium' : 'regular'}>
                          {t('summary.deliveryPickup')}
                        </Text>
                      }
                      id='delivery-pickup'
                      name='delivery-pickup'
                      testId='delivery-pickup'
                      checked={deliveryType === 'pickup'}
                      onChange={() => setDeliveryType('pickup')}
                      disabled={!pickupAllowed}
                    />
                    {!pickupAllowed && (
                      <PickupDisclaimer
                        data-testid='alcohol-delivery-disclaimer'
                        variant='body'
                        sizing='xsmall'
                        weight='bold'
                      >
                        <IconStatusWarning color={color.element.error} />
                        {t('summary.alcoholDeliveryDisclaimer')}
                      </PickupDisclaimer>
                    )}
                  </>
                )}
                {deliveryEnabled && (
                  <RadioButtonOrCheckbox
                    id='delivery-room'
                    name='delivery-room'
                    testId='delivery-room'
                    buttonType='radio'
                    checked={deliveryType === 'room'}
                    onChange={() => setDeliveryType('room')}
                    value='asap'
                    label={
                      <Text variant='body' sizing='small' weight={deliveryType === 'room' ? 'medium' : 'regular'}>
                        {t('summary.deliveryRoom', {price: formatPricePlainText(actualDeliveryFee ?? 0)})}
                      </Text>
                    }
                  />
                )}
                <PreparationTimeDisclaimer variant='body' sizing='small' data-testid='delivery-room-total-delay'>
                  {t('summary.deliveryRoomDelayNotification', {delay: totalRoomServiceDelay})}
                </PreparationTimeDisclaimer>
              </DeliveryMethodSelector>
              <LoyaltyProgramSelection
                availablePrograms={availableLoyaltyProgramOptions}
                selectedValue={loyaltyProgram}
                onChange={(value) => onEditCustomerDetails('loyaltyProgram', value)}
              />
              <PaymentMethodSelection
                availableMethods={availablePaymentMethods}
                selectedValue={paymentMethod}
                onChange={(value) => onEditCustomerDetails('paymentMethod', value)}
              />
            </SummaryFormRow>
            <SummaryOrderSubmission handleSubmitOrder={handleSubmitOrder} submitIsDisabled={submitIsDisabled} />
            <Text variant='body' sizing='small'>
              {t('summary.termsHeader')}{' '}
              <TermsLink href={t('summary.termsLink') ?? ''} target='_blank' rel='noreferrer'>
                {t('summary.termsLinkText')}
              </TermsLink>
            </Text>
          </Form>
        </>
      )}
    </SummaryWrapper>
  )
}

export default SummaryRoomService
