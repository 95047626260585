import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {deliveryPortionRoom} from '@marketplace/shared-lib/src/utils/cartUtils'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Button} from '@s-group/design-system-components'
import {FeedbackScoreForm} from 'Components/FeedbackScoreForm'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router'
import {spacing} from 'theme'
import {getAllOrders} from 'utils/order'
import {OrderConfirmationDetails} from './OrderConfirmationDetails'
import {OrderConfirmationPickup} from './OrderConfirmationPickup'
import {Description, OrderConfirmationWrapper, Title} from './OrderStyles'
import OrderHistoryItem from './orderHistoryView/OrderHistoryItem'
import {ThankYouWrapper} from './orderHistoryView/OrderHistoryStyles'

const {ROOM, PICKUP} = TableType

// There is only one mini ama restaurant for now. This is a temporary solution.
export const MINI_AMA_RESTAURANT_ID = '651144'

const ThankYou = () => {
  const navigate = useNavigate()
  const {t, i18n} = useTranslation('order')
  const {restaurantData, cartData, tableData, handledOrderData} = useRestaurantContext()
  const cartID = cartData?.getCart.id
  const {id} = useParams()
  const restaurantName = getLocalized(restaurantData?.getRestaurant.name, i18n.language)
  const tableID = tableData?.getTable.tableID ?? ''
  const tableType = tableData?.getTable.tableType
  const restaurantID = restaurantData?.getRestaurant.id

  const allOrders = getAllOrders(tableData, handledOrderData, cartID)
  const pendingOrders = allOrders.pendingOrders ?? []
  const ordersInProgress = allOrders.ordersInProgress ?? []
  const handledOrders = allOrders.handledOrders ?? []
  const order = [...pendingOrders, ...ordersInProgress, ...handledOrders].find((orderItem) => orderItem.orderID === id)
  const isTakeaway = tableType === PICKUP
  const isRoomService = tableType === ROOM

  const descriptionTranslationKey = (() => {
    if (!order?.products) return 'confirmation.orderNotFoundDescription'
    if (isTakeaway) {
      return 'confirmation.thanksForTheOrderDescription.pickupOrder'
    }
    if (isRoomService) {
      if (order.products.some((cur) => cur.portion.id === deliveryPortionRoom)) {
        return 'confirmation.thanksForTheOrderDescription.hotelRoomDelivery'
      }
      return 'confirmation.thanksForTheOrderDescription.hotelRoomPickup'
    }
    return 'confirmation.thanksForTheOrderDescription.restaurantTable'
  })()

  return (
    <ThankYouWrapper>
      <Title variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase' role='heading' aria-level={1}>
        {t(order?.products ? 'confirmation.thanksForTheOrder' : 'confirmation.orderNotFound')}
      </Title>
      <Description
        variant='body'
        sizing='small'
        $paddingBottom={spacing.xxxxsmall}
        data-testid='confirmed-order-details'
      >
        <Trans i18nKey={descriptionTranslationKey} ns='order' />
      </Description>
      {restaurantID === MINI_AMA_RESTAURANT_ID && (
        <Description data-testid='confirmed-mini-amarillo-order-details'>
          <Trans i18nKey='confirmation.thanksForTheOrderDescription.miniAmaPickup' ns='order' />
        </Description>
      )}
      <OrderConfirmationWrapper>
        {order && (
          <>
            <OrderHistoryItem order={order} tableType={tableType} />
            {isRoomService && <OrderConfirmationDetails order={order} tableID={tableID} />}
            {isTakeaway && <OrderConfirmationPickup order={order} />}
            <FeedbackScoreForm restaurantName={restaurantName} tableID={tableID} tableType={tableType} />
          </>
        )}
        <Button
          onClick={() => navigate('/')}
          color='neutral'
          variant='filled'
          rounding='small'
          data-testid='back-to-front-page'
        >
          {t('confirmation.backToFrontpage')}
        </Button>
      </OrderConfirmationWrapper>
    </ThankYouWrapper>
  )
}

export default ThankYou
