import {welcomeRecommendationFlagKey} from '@marketplace/shared-lib/src/launchDarkly/flagKeys'
import useFlagValue from '@marketplace/shared-lib/src/launchDarkly/useFlagValue'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Text} from '@s-group/design-system-components'
import {IconNavigationArrowRight} from '@s-group/design-system-icons'
import {useTranslation} from 'react-i18next'
import {spacing} from 'theme'
import {getLowestPortionPriceAndSize} from '../../utils/portionPriceUtils'
import CoopMemberPrice from '../Price/CoopMemberPrice'
import {
  AddToCartButton,
  ProductRecommendationDietTags,
  ProductRecommendationLowestPrice,
  ProductRecommendationPrice,
  ProductRecommendationTitle,
  WelcomeRecommendationBottomRow,
  WelcomeRecommendationCardWrapper,
  WelcomeRecommendationDescription,
} from './ProductRecommendationCardStyles'
import {SizeLabel} from './ProductStyles'
import {PortionWithMenuID} from './productCardUtils'

interface ProductRecommendationCardProps {
  portion: PortionWithMenuID
  onAddItem: () => void
  loading: boolean
  ctaText?: string
}

const WelcomeRecommendationProductCard = ({portion, onAddItem, loading, ctaText}: ProductRecommendationCardProps) => {
  const {i18n, t} = useTranslation('order')
  // LD flag to open portion details in welcome recommendations
  const openPortionDetailsInWelcomeRecommendations = useFlagValue({
    key: welcomeRecommendationFlagKey,
    defaultValue: false,
  })
  const locale = i18n.language
  const title = getLocalized(portion.name, locale)
  const description = getLocalized(portion.description, locale)
  const buttonLabel: string = ctaText ?? t('menu.add')
  const buttonAriaLabel: string = ctaText ?? t('menu.addToOrder')
  const dietTags: string = portion.diet
    .map((dietTag) => t(`menu.dietTags.abbreviations.${dietTag}`))
    .filter((dietTagText) => dietTagText) // filter out empty strings
    .join(', ')
  const {lowestNormal, lowestCoop, size, hasMultiple} = getLowestPortionPriceAndSize(portion)
  const hasMultiplePrices = hasMultiple || lowestCoop
  const sizeLabel = size ? getLocalized(size, locale) : undefined

  return (
    <WelcomeRecommendationCardWrapper data-testid={`product-recommendation-card-${portion.id}`}>
      <ProductRecommendationTitle
        data-testid='product-recommendation-card-title'
        variant='heading'
        sizing='xxsmall'
        weight='medium'
      >
        {title}
        {dietTags !== '' && <ProductRecommendationDietTags>{` (${dietTags})`}</ProductRecommendationDietTags>}
      </ProductRecommendationTitle>
      {description && (
        <WelcomeRecommendationDescription
          variant='body'
          sizing='small'
          data-testid='product-recommendation-card-description'
        >
          {description}
        </WelcomeRecommendationDescription>
      )}
      <WelcomeRecommendationBottomRow>
        {openPortionDetailsInWelcomeRecommendations ? (
          <ProductRecommendationLowestPrice>
            {hasMultiplePrices && (
              <Text variant='body' sizing='medium'>
                {t('menu.lowestPrice')}
              </Text>
            )}
            <Text variant='body' sizing='medium' weight='bold' data-testid='product-recommendation-lowest-price'>
              {formatPrice({price: lowestCoop || lowestNormal})}
            </Text>
          </ProductRecommendationLowestPrice>
        ) : (
          <ProductRecommendationPrice>
            <Text
              variant='body'
              sizing='medium'
              weight={!lowestCoop ? 'bold' : 'regular'}
              data-testid='product-recommendation-card-normal-price'
            >
              {formatPrice({price: lowestNormal})}
            </Text>
            <CoopMemberPrice
              testId='product-recommendation-card-coop-price'
              coopMemberPrice={lowestCoop}
              marginLeft='0'
            />
            {sizeLabel && (
              <SizeLabel
                variant='body'
                sizing='small'
                weight='light'
                data-testid={`product-recommendation-size-label-${portion.id}`}
              >
                / {sizeLabel}
              </SizeLabel>
            )}
          </ProductRecommendationPrice>
        )}
        <AddToCartButton
          rounding='small'
          color='neutral'
          variant='filled'
          sizing='xsmall'
          onClick={onAddItem}
          disabled={loading}
          aria-label={buttonAriaLabel}
          icon={<IconNavigationArrowRight />}
          iconPos='after'
          $paddingSide={spacing.small}
        >
          {buttonLabel}
        </AddToCartButton>
      </WelcomeRecommendationBottomRow>
    </WelcomeRecommendationCardWrapper>
  )
}

export default WelcomeRecommendationProductCard
