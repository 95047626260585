/* eslint-disable @typescript-eslint/no-explicit-any */
import {Modal} from '@s-group/design-system-components'
import styled from 'styled-components'

export const ModalComponent = styled(Modal)<{$maxWidth?: string; $noPadding?: boolean}>`
  ${({$maxWidth}) => $maxWidth && `max-width: ${$maxWidth};`}
  .modal-body {
    ${({$noPadding}) => $noPadding && `padding: 0;`}
  }
`
export const ModalContent = styled.div<{$contentWidth?: string}>`
  display: flex;
  justify-content: center;
  ${({$contentWidth}) => $contentWidth && `width: ${$contentWidth};`}
`

export const ModalContentWrapper = styled.span`
  width: 100%;
`

export const StyledModalFullScreen = styled(Modal)<{$opacity?: number}>`
  background: ${({theme}) => theme.color.black};
  color: ${({theme}) => theme.color.white};
  width: 100vw;
  height: 100vh; /* fallback for old browsers */
  height: 100svh; /* modern browsers */
  position: relative;
  z-index: 200;
  overflow: auto;
  opacity: ${({$opacity}) => $opacity ?? 1};
  transition: all 0.3s ease-in-out;
  font-family: ${({theme}) => theme.font.family.roboto};
`
export const FullScreenCloseButton = styled.div`
  position: absolute;
  margin: 0;
  top: ${({theme}) => theme.spacing.xxlarge};
  right: ${({theme}) => theme.spacing.xxlarge};
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  cursor: pointer;
`

export const ModalWrapper = styled.div<{whiteBackground?: boolean}>`
  color: ${({whiteBackground, theme}) => (whiteBackground ? theme.color.black : theme.color.white)};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

export const ModalTextWrapper = styled.div`
  margin-top: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ModalButtonWrapper = styled.div`
  padding: 2rem;
  background-color: ${({theme}) => theme.color.lightGrey};
  display: flex;
  width: 100%;
  justify-content: space-between;
`
