import {LDFlagValue, useLDClient} from 'launchdarkly-react-client-sdk'

/**
 * A small abstraction hook to get single flag value from LaunchDarkly
 * TODO: maybe can evaluate based on third parameter if we want it to be specific type boolean, string etc.?
 * @param key string of the flag key from LD
 * @param defaultValue fallback value if such flag key is not found
 * @returns returns desired flag value from LD
 */

type UseFlagValueProps = {key: string; defaultValue?: LDFlagValue}

const useFlagValue = ({key, defaultValue}: UseFlagValueProps): LDFlagValue => {
  const ldClient = useLDClient()
  return ldClient?.variation(key, defaultValue)
}

export default useFlagValue
