import {LDProvider} from 'launchdarkly-react-client-sdk'
import {defaultContext} from './useLDContext'

type Props = {
  children: React.ReactNode
  clientSideID: string
}

/**
 * Provider for LaunchDarkly feature flags.
 * Default context is set to 'marketplace-restaurant' and anonymous for providing default flags.
 * Context can be updated with useLDContext.
 * @param children The children to wrap with the provider
 * @param clientSideID The service id from LD
 *
 */
const MarketplaceLDProvider = ({children, clientSideID}: Props) => {
  return (
    <LDProvider clientSideID={clientSideID} context={defaultContext}>
      {children}
    </LDProvider>
  )
}

export default MarketplaceLDProvider
