import {useEffect, useRef} from 'react'
import * as Sentry from '@sentry/react'
import {useHasServiceConsent, useIsInitialized} from '@s-group/react-usercentrics'
import {currentEnv} from '@marketplace/shared-lib/src/utils/envUtils'
import config from 'config'
import {sanitizeData, SentryTagKeys} from 'utils/sentryUtils'

const initSentry = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: currentEnv,
    integrations: [
      // By default React logs all errors to the console, even if you are using a
      // React error boundary. Therefore, error boundary is not needed to capture
      // those errors.
      // https://docs.sentry.io/platforms/javascript/configuration/integrations/captureconsole/
      // https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
      Sentry.captureConsoleIntegration({levels: ['error', 'warn']}),
    ],

    // Default object depth is 3, but we want to see more of the objects since
    // we are dealing with complex objects.
    // Data beyond this depth will be truncated to something like "[Object]".
    // See also sanitizeData().
    normalizeDepth: 25,

    beforeSend: (event, hint) => {
      // Discard errors with "Support for defaultProps will be removed" because
      // they are a known issue in React 18 with some third-party libraries.
      if (
        event.exception &&
        event.exception.values &&
        event.exception.values.some((exception) =>
          exception.value?.includes('Support for defaultProps will be removed')
        )
      ) {
        return null
      }

      const updatedTags = {
        ...(event.tags ?? {}),
        [SentryTagKeys.Component]: 'remote',
        [SentryTagKeys.AgeConsent]: event.tags?.[SentryTagKeys.AgeConsent] ?? false,
        [SentryTagKeys.Language]: event.tags?.[SentryTagKeys.Language] ?? 'unknown',
        [SentryTagKeys.TableType]: event.tags?.[SentryTagKeys.TableType] ?? 'unknown',
        [SentryTagKeys.RestaurantId]: event.tags?.[SentryTagKeys.RestaurantId] ?? 'unknown',
        [SentryTagKeys.RestaurantName]: event.tags?.[SentryTagKeys.RestaurantName] ?? 'unknown',
      }

      // sanitize sensitive data before sending it to Sentry
      const sanitizedData = event.request?.data ? sanitizeData(event.request.data) : event.request?.data

      if (window?.location.hostname === 'localhost') {
        console.log('Sentry event:', event)
        console.log('Original exception:', hint.originalException || hint.syntheticException)
        return null
      }

      return {
        ...{
          ...event,
          request: event.request && {
            ...event.request,
            data: sanitizedData,
          },
        },
        tags: updatedTags,
      }
    },
  })
}

/**
 * Hook to initialize Sentry if
 * - the user has given consent to Sentry in Usercentrics
 * - and Sentry is enabled for the current environment
 *
 * useSentry() must be called within UsercentricsProvider.
 */
export const useSentry = () => {
  const isUsercentricsInitialized = useIsInitialized()
  const hasSentryConsent = useHasServiceConsent(config.SENTRY_ID_FOR_USERCENTRICS)
  const isSentryInitialized = useRef(false)

  useEffect(() => {
    // If Usercentrics is not initialized, we can't check the consent, so let's abort
    if (!isUsercentricsInitialized) return

    // Initialise Sentry once if the user has given consent
    if (!isSentryInitialized.current && config.SENTRY_DSN && hasSentryConsent) {
      // Let's be extra cautious when we're initializing an external service
      // and catch any errors that might occur.
      try {
        initSentry()
      } catch (error) {
        console.error('Error initializing Sentry:', error)
      }
      isSentryInitialized.current = true
    }

    // Close Sentry if the user has revoked the consent
    if (isSentryInitialized.current && !hasSentryConsent) {
      Sentry.close()
        .then(() => {
          isSentryInitialized.current = false
        })
        .catch((error) => {
          console.error('Error closing Sentry:', error)
          // reload the page to make sure Sentry is not initialized
          window.location.reload()
        })
    }
  }, [hasSentryConsent, isUsercentricsInitialized])
}
