import {Text} from '@s-group/design-system-components'
import {PortionOptionsStringWrapper} from './PortionOptionsStringStyles'

type PortionOptionsProps = {
  portionOptionName: string
  portionOptionValues: string[]
}[]

type PortionOptionsStringProps = {
  portionOptions: PortionOptionsProps
  mainIndex?: number
  showPortionOptionName?: boolean
  size?: 'small' | 'medium' | 'large'
}

const defineKeyValue = (currentIndex: number, mainIndex?: number) => {
  return mainIndex ? `portion-${mainIndex}-option-${currentIndex}` : `portion-option-${currentIndex}`
}

const getOptionsArray = (portionOptions: PortionOptionsProps, showPortionOptionName: boolean) =>
  portionOptions
    .flatMap(({portionOptionName, portionOptionValues}) => {
      const optionName = portionOptionName && `${portionOptionName}:`
      const options = portionOptionValues.map((value) => value)
      return [!!showPortionOptionName && optionName, ...options]
    })
    .filter((option) => option)

const PortionOptionsString = ({
  portionOptions,
  mainIndex,
  showPortionOptionName = false,
  size = 'small',
}: PortionOptionsStringProps) => {
  const portionOptionArray = getOptionsArray(portionOptions, showPortionOptionName)
  return (
    <PortionOptionsStringWrapper>
      {portionOptionArray.map((portionOption, index) => (
        <Text
          variant='body'
          sizing={size}
          key={defineKeyValue(index, mainIndex)}
          data-testid={`option-${index}-selections`}
        >
          {portionOption}
          {index !== portionOptionArray.length - 1 && ','}
        </Text>
      ))}
    </PortionOptionsStringWrapper>
  )
}

export default PortionOptionsString
