import {useHasServiceConsent} from '@s-group/react-usercentrics'
import {LDSingleKindContext, useLDClient} from 'launchdarkly-react-client-sdk'
import {useEffect, useMemo} from 'react'
import {TableType} from '../../graphql/graphql'
import {
  getPersistedUserContext,
  MarketplaceContext,
  MarketplaceUserContext,
  setPersistedUserContext,
} from '../utils/cookies'

type UseLDClientProps = {
  LD_ID: string
  restaurantID?: string
  tableType?: TableType
  isTestTable?: boolean
  restaurantName?: string
  consentNeeded?: boolean
  isStaff?: boolean
}

export const defaultContext: MarketplaceContext = {
  kind: 'multi',
  restaurant: {
    kind: 'marketplace-restaurant',
    key: 'unknown',
    name: 'unknown',
    tableType: 'unknown',
    anonymous: true,
    isStaff: false,
  },
  user: {
    kind: 'raflaamo-guest',
    anonymous: true,
  },
}

/**
 * Initialize LaunchDarkly context with user and restaurant context
 * If theres no user context in cookies, generate new user context
 * @param LD_ID LD_ID for Usercentrics service consent check
 * @param restaurantID Restaurant ID
 * @param tableType Table type
 * @param restaurantName Restaurant name
 * @param isTestTable If table is test table
 * @param consentNeeded Is user's consent is needed to use assigned LD user context. default: true
 * @param isStaff For flags to distinguish staff accessing restaurant from customers
 */
const useLDContext = ({
  LD_ID,
  restaurantID,
  tableType,
  restaurantName,
  isTestTable,
  consentNeeded = true,
  isStaff = false,
}: UseLDClientProps) => {
  const ldClient = useLDClient()
  const ldConsent = useHasServiceConsent(LD_ID)
  const hasLdConsent = consentNeeded ? ldConsent : true
  // Get persisted user context from cookies
  const userContext = useMemo(() => getPersistedUserContext(), [])
  const resolveUserContext: LDSingleKindContext = useMemo(() => {
    /**
     * When user has given service development consent
     * return existing user context or generate and return new context
     */
    if (hasLdConsent) {
      return userContext && userContext?.anonymous !== true
        ? userContext
        : {kind: defaultContext.user.kind, key: crypto.randomUUID()}
    }
    // When user has not given service development consent return default context
    return defaultContext.user
  }, [hasLdConsent, userContext])

  useEffect(() => {
    const user: MarketplaceUserContext = {
      ...resolveUserContext,
      kind: defaultContext.user.kind,
      isTestTable,
    }
    // If ldClient and restaurantID is available, identify user with context
    if (ldClient && restaurantID) {
      const context: MarketplaceContext = {
        kind: 'multi',
        restaurant: {
          kind: defaultContext.restaurant.kind,
          key: restaurantID,
          name: restaurantName ?? defaultContext.restaurant.name,
          tableType: tableType ?? 'unknown',
          isStaff,
        },
        user,
      }
      ldClient.identify(context)
      setPersistedUserContext(user)
    }
  }, [restaurantID, resolveUserContext, isTestTable, tableType, ldClient, restaurantName, isStaff])
}

export default useLDContext
