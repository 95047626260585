import {useReactiveVar} from '@apollo/client'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useEffect, useRef, useState} from 'react'
import {previousCartIDsVar, restaurantSelectionTableTokens} from 'state/reactiveVariables'
import {remoteRestaurantSelectionToken} from 'utils/constants'
import {CustomerDetails, getCustomerDetails, getLatestOrderItem, getOtherOrdersCustomerDetails} from 'utils/order'
import useCustomerAccount from './useCustomerAccount'

type CustomerDetailsState = {
  details: CustomerDetails | null
  isLoading: boolean
}

/**
 * Get customer details from the latest order item in the current cart or from
 * other orders made by the same customer.
 * Uses signed in user's contact details if no current or previous cart is found and SID login is enabled.
 *
 * Data can be used to prefill customer details in the order form.
 */
const useCustomerDetails = (): CustomerDetailsState => {
  const {tableData, cartData, handledOrderData} = useRestaurantContext()
  const {token} = tableData?.getTable || {}
  const otherTableTokens = useReactiveVar(restaurantSelectionTableTokens).filter((tableToken) => tableToken !== token)
  const previousCartIDsVars = useReactiveVar(previousCartIDsVar)

  const [customerDetails, setCustomerDetails] = useState<CustomerDetailsState>({
    details: null,
    isLoading: true,
  })

  const cartID = cartData?.getCart.id

  const restaurantSelectionToken = localStorage.getItem(remoteRestaurantSelectionToken)
  const previousCartIDs = previousCartIDsVars.flatMap((id) => Object.values(id)).filter((id) => id !== null)

  const customerDetailsFromCurrentCart = getCustomerDetails(getLatestOrderItem(tableData, handledOrderData, cartID))
  const customerDetailsFromPreviousCart = getOtherOrdersCustomerDetails(otherTableTokens, previousCartIDs)

  const customerDetailsRef = useRef(customerDetailsFromCurrentCart ?? null)

  // In restaurant selection type of order flow if there is no current cart
  // get customer details from orders in other tables made by the same customer
  if (!customerDetailsFromCurrentCart && restaurantSelectionToken && otherTableTokens.length) {
    customerDetailsRef.current = customerDetailsFromPreviousCart ?? null
  }

  // If no cart data available and SID login enabled, fetch logged in customer's details
  const {customerAccountData, customerAccountLoading, isLoginEnabled} = useCustomerAccount()

  useEffect(() => {
    // Data is not immediately available after loading stops so check if it is still undefined
    if (isLoginEnabled && (customerAccountLoading || customerAccountData?.getCustomerAccount.customer === undefined))
      return

    // No cart data available, use signed in user's contact information
    if (!customerDetailsRef.current) {
      const signedInCustomer = customerAccountData?.getCustomerAccount.customer
      customerDetailsRef.current = signedInCustomer
        ? {
            firstName: signedInCustomer.name?.first ?? '',
            lastName: signedInCustomer.name?.last ?? '',
            phoneNumber: signedInCustomer.phone?.number ?? '',
          }
        : null
    }

    setCustomerDetails({
      details: customerDetailsRef.current,
      isLoading: customerAccountLoading,
    })
  }, [customerAccountLoading, customerAccountData, isLoginEnabled])

  return customerDetails
}

export default useCustomerDetails
