import {useGetCustomerAccountQuery} from '@marketplace/shared-lib/graphql/graphql'
import {useNetworkConnection, useVisibilityChange} from '@marketplace/shared-lib/src/hooks'
import {sidLoginFlagKey} from '@marketplace/shared-lib/src/launchDarkly/flagKeys'
import useFlagValue from '@marketplace/shared-lib/src/launchDarkly/useFlagValue'
import {useEffect} from 'react'
import {triggerLoginEvent} from './useDataLayer'

const useCustomerAccount = () => {
  const isLoginEnabled = useFlagValue({key: sidLoginFlagKey, defaultValue: false})
  const {visibleAfterHidden} = useVisibilityChange(100)
  const {reconnected} = useNetworkConnection(100)

  const {data, loading, error, refetch} = useGetCustomerAccountQuery({
    fetchPolicy: 'cache-first',
    skip: !isLoginEnabled,
    onCompleted: ({getCustomerAccount}) => {
      if (getCustomerAccount.customer?.casId) {
        const casId = getCustomerAccount.customer.analyticsConsent ? getCustomerAccount.customer.casId : undefined
        triggerLoginEvent(casId)
      }
    },
  })

  //  refetch
  // Initiate when view hidden and visible again or network connection is restored
  useEffect(() => {
    const triggerRefetch = async () => {
      try {
        await refetch()
      } catch {
        // @TODO: handle errors in useGetCustomerAccountQuery onError link
      }
    }
    if (visibleAfterHidden || reconnected) {
      triggerRefetch()
    }
  }, [visibleAfterHidden, refetch, reconnected])

  return {customerAccountData: data, customerAccountLoading: loading, customerAccountError: error, isLoginEnabled}
}

export default useCustomerAccount
