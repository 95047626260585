import {PortionSelectedIds} from 'types/productCardTypes'
import {PortionOptionWithStatus} from 'Components/SelectionBox/SelectionBoxTypes'
import {DisabledPortion, PortionOption} from '@marketplace/shared-lib/graphql/graphql'
import {getPortionSelections, getSelectedPortionOptions, PortionWithMenuID} from './productCardUtils'

export type HandleOptionChangeType = {
  portionsSelectionOptions: PortionSelectedIds[]
  previousSelectedPortions: PortionWithMenuID[]
  portion: PortionWithMenuID
  optionSectionId: string
  optionId: string
  maxCount?: number | null
  decreaseQuantity?: boolean
  deselect?: boolean
}

type HandleOptionChangeReturnType = {
  portionsSelections: PortionSelectedIds[]
  selectedPortions: PortionWithMenuID[]
}

export const handleOptionChangeCheckbox = ({
  portionsSelectionOptions,
  previousSelectedPortions,
  portion,
  optionSectionId,
  optionId,
  maxCount,
  decreaseQuantity,
  deselect,
}: HandleOptionChangeType): HandleOptionChangeReturnType => {
  const {id: selectedPortionId} = portion
  /** Find portion selection by portionID from portion selections */
  const portionSelections = getPortionSelections(selectedPortionId, portionsSelectionOptions)

  /** If portion is not found return previous selections */
  if (!portionSelections?.length)
    return {
      portionsSelections: portionsSelectionOptions,
      selectedPortions: previousSelectedPortions,
    }

  const isDeselect = decreaseQuantity || deselect
  let selectedOptionCount = 0

  const newSelection = portionSelections.map(({selectedSectionId, selectedOptionIds}) => {
    /** Check that selected optionSectionId */
    if (optionSectionId === selectedSectionId) {
      if (selectedOptionIds?.length) {
        selectedOptionCount = selectedOptionIds?.length
      }
      /** Get an array of the previously selected portion options with this optionID */
      const existingPortion = selectedOptionIds?.filter((id) => id === optionId)
      /** If there are previously selected options and the decreaseQuantity is true */
      if (selectedOptionIds && existingPortion && decreaseQuantity) {
        selectedOptionCount -= 1
        /** Get other selected options */
        const filteredSelectedOptionIds = selectedOptionIds.filter((id) => id !== optionId) || []
        /**
         * If there is only one previous selection
         * or if deselect is true
         * return selectedOptionIds without this portion option
         */
        if (existingPortion.length === 1 || deselect) {
          return {selectedSectionId: optionSectionId, selectedOptionIds: filteredSelectedOptionIds}
        }
        /**
         * If there are more than one previous selections
         * remove one from existingPortion
         * return that with filteredSelectedOptionIds
         */
        existingPortion.pop()
        return {
          selectedSectionId: optionSectionId,
          selectedOptionIds: [...filteredSelectedOptionIds, ...existingPortion],
        }
      }
      return {selectedSectionId, selectedOptionIds}
    }
    return {selectedSectionId, selectedOptionIds}
  })

  const updatedPortionsSelections = portionsSelectionOptions.map((selections) => {
    /**
     * Do not update portionsSelectionOptions if
     * there is no selection for selectedPortionId
     * or isDeselect id false and if maxCount is reached
     */
    if (!selections[selectedPortionId] || (!isDeselect && maxCount && selectedOptionCount >= maxCount))
      return selections
    /**
     * If isDeselect is true
     * return newSelection from above
     */
    if (isDeselect) return {[selectedPortionId]: newSelection}

    /** Get previously selected optionIds for selectedSectionId */
    const ids = portionSelections.filter((selection) => selection?.selectedSectionId === optionSectionId)[0]
      ?.selectedOptionIds

    /** Get other selected sections */
    const otherSections = selections[selectedPortionId].filter(
      ({selectedSectionId}) => selectedSectionId !== optionSectionId
    )

    /**
     * Return other selected selections
     * and this section with added optionId in selectedOptionIds
     */
    return {
      [selectedPortionId]: [
        ...otherSections,
        {
          selectedSectionId: optionSectionId,
          selectedOptionIds: ids?.length ? [...ids, optionId] : [optionId],
        },
      ],
    }
  })

  const selectedPortionSelections = getPortionSelections(selectedPortionId, updatedPortionsSelections)
  if (!selectedPortionSelections)
    return {
      portionsSelections: portionsSelectionOptions,
      selectedPortions: previousSelectedPortions,
    }

  const portionItem = getSelectedPortionOptions(portion, selectedPortionSelections)
  const updatedSelectedPortions = previousSelectedPortions.map((item) =>
    item.id === selectedPortionId ? portionItem : item
  )

  return {portionsSelections: updatedPortionsSelections, selectedPortions: updatedSelectedPortions}
}

/**
 * Resolves whether the checkboxes or radio buttons for portion options should
 * be disabled and if the portion option is out of stock.
 */
export const getPortionOptionsWithStatus = ({
  portionID,
  portionOptions,
  selectedOptionIds,
  maxCount,
  disabledPortions,
  buttonType,
}: {
  portionID: string
  portionOptions: PortionOption[]
  selectedOptionIds: string[]
  maxCount: number | null | undefined
  disabledPortions: DisabledPortion[]
  buttonType: 'checkbox' | 'radio'
}): PortionOptionWithStatus[] => {
  return portionOptions.map((option) => {
    const isSelected = selectedOptionIds.some((id) => id === option.id)
    const isMaxSelectionsReached = !!maxCount && selectedOptionIds.length >= maxCount
    const outOfStock = disabledPortions.some(
      ({id, portionOptions: portionOptionIDs}) => id === portionID && portionOptionIDs.includes(option.id)
    )
    const disabled = outOfStock || (!isSelected && isMaxSelectionsReached && buttonType === 'checkbox')

    return {
      ...option,
      disabled,
      outOfStock,
    } satisfies PortionOptionWithStatus
  })
}
