import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {Text} from '@s-group/design-system-components'
import {triggerEvent} from 'hooks/useDataLayer'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {getHasGivenFeedbackScore, setHasGivenFeedbackScore} from 'utils/cookies'
import useTriggerEventNew from '../../hooks/useTriggerEventNew'
import {EmojiButton, Ul, Wrapper} from './FeedbackScoreFormStyles'

interface FeedbackScoreFormProps {
  restaurantName: string
  tableID: string
  tableType?: TableType
}

const FeedbackScoreForm = ({restaurantName, tableID, tableType}: FeedbackScoreFormProps) => {
  const {t} = useTranslation('order')
  const [scoreGiven, setScoreGiven] = useState(false)
  const triggerEventNew = useTriggerEventNew()

  const options = [
    {id: 1, icon: '😢'},
    {id: 2, icon: '🙁'},
    {id: 3, icon: '😐'},
    {id: 4, icon: '🙂'},
    {id: 5, icon: '😃'},
  ]

  useEffect(() => {
    const cookie = getHasGivenFeedbackScore()
    if (cookie) {
      setScoreGiven(true)
    }
  }, [])

  const handleClick = (score: number) => {
    setScoreGiven(true)
    setHasGivenFeedbackScore()
    triggerEvent({
      event: AnalyticsEvents.SET_ORDER,
      action: AnalyticsActions.GIVE_SCORE,
      restaurant_name: restaurantName,
      table: `${tableID}_${score}`,
      table_type: tableType,
    })
    triggerEventNew?.({
      event: 'feedback_score',
      data: {
        feedback_score_value: score,
      },
    })
  }

  return (
    <Wrapper>
      {scoreGiven ? (
        <Text variant='heading' sizing='xsmall' weight='medium' role='heading' aria-level={2}>
          {t('confirmation.thankYouForFeedback')}
        </Text>
      ) : (
        <>
          <Text variant='heading' sizing='xxsmall' weight='medium' role='heading' aria-level={2}>
            {t('confirmation.howDidTheOrderGo')}
          </Text>
          <Ul>
            {options.map((option) => (
              <li key={option.id}>
                <EmojiButton
                  variant='plain'
                  iconOnly
                  onClick={() => handleClick(option.id)}
                  data-testid={`feedback-button-${option.id}`}
                >
                  {option.icon}
                </EmojiButton>
              </li>
            ))}
          </Ul>
        </>
      )}
    </Wrapper>
  )
}

export {FeedbackScoreForm}
