import {useModalFeatures} from '@marketplace/shared-lib/src/hooks'
import {useAfterModalOpen} from '@marketplace/shared-lib/src/hooks/useAfterModalOpen'
import {ModalProps} from '@marketplace/shared-lib/src/UI/Modal/modalTypes'
import {IconNavigationClose} from '@s-group/design-system-icons'
import FocusLock from 'react-focus-lock'
import {useTranslation} from 'react-i18next'
import {ModalBottomCloseButton, ModalBottomContentWrapper, StyledModalBottom} from './ModalBottomStyles'

const ModalBottom = (props: ModalProps) => {
  const {
    isOpen,
    noPadding,
    setIsOpen,
    small = false,
    children,
    showCloseButton = true,
    maxWidth,
    onClose,
    background,
    alt,
  } = props
  const {t} = useTranslation('remotePage')
  const {handleClose, afterOpen, animate} = useModalFeatures({setIsOpen, onClose})
  useAfterModalOpen(isOpen, afterOpen)

  return (
    <StyledModalBottom
      position='bottom'
      $small={small}
      open={isOpen}
      $maxWidth={maxWidth}
      $background={background}
      $animate={animate}
      closeOnOutsideClick
      onClose={handleClose}
      $noPadding={noPadding}
      aria-label={alt}
    >
      <FocusLock autoFocus={isOpen}>
        {showCloseButton && (
          <ModalBottomCloseButton
            aria-label={t('close')}
            color='neutral'
            variant='filled'
            sizing='small'
            rounding='pill'
            onClick={handleClose}
            data-testid='modal-close-button'
            iconOnly
          >
            <IconNavigationClose size='24' aria-hidden />
          </ModalBottomCloseButton>
        )}
        <ModalBottomContentWrapper>{children}</ModalBottomContentWrapper>
      </FocusLock>
    </StyledModalBottom>
  )
}

export default ModalBottom
