import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {DiscountedPrice, Price, PriceContainer, ProductTitle} from 'Components/Product/ProductStyles'
import {useTranslation} from 'react-i18next'
import {PriceItem} from './SummaryStyles'

const DeliveryItem = ({normalFee, discountedFee}: {normalFee: number; discountedFee?: number}) => {
  const {t} = useTranslation('order')

  const discountedPrice = formatPrice({price: discountedFee || 0})
  const price = discountedPrice || formatPrice({price: normalFee})
  const originalPrice = formatPrice({price: normalFee})
  const showOriginalPrice = discountedFee !== normalFee

  return (
    <PriceItem key='delivery-item'>
      <ProductTitle data-testid='delivery-item-title' variant='body' sizing='small' weight='bold'>
        {t('summary.roomServiceFee')}
      </ProductTitle>
      <PriceContainer>
        <Price variant='body' sizing='small' weight='bold' data-testid='delivery-item-total-price'>
          {price}
        </Price>
        {showOriginalPrice && (
          <DiscountedPrice variant='body' sizing='small'>
            {originalPrice}
          </DiscountedPrice>
        )}
      </PriceContainer>
    </PriceItem>
  )
}

export default DeliveryItem
