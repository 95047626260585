import {Checkbox, RadioButton} from '@s-group/design-system-components'
import {RadioButtonOrCheckboxProps} from './SelectionBoxTypes'

const RadioButtonOrCheckbox = ({
  id,
  buttonType,
  label,
  name,
  value,
  onChange,
  checked,
  disabled = false,
  testId,
}: RadioButtonOrCheckboxProps) => {
  const Component = buttonType === 'radio' ? RadioButton : Checkbox
  return (
    <Component
      filled
      sizing='xsmall'
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      data-testid={testId}
      // width has to be 100% to make right-aligned items in the label work
      // e.g. quantity selector in the portion options
      labelProps={{'data-testid': `${testId}-label`, style: {width: '100%'}}}
    >
      {label}
    </Component>
  )
}

export default RadioButtonOrCheckbox
