import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {DataLayerEvent} from 'types/analyticsTypesNew'

const triggerEvent = (event: DataLayerEvent) => {
  // Test restaurants, don't send analytics data.
  if (event.restaurant_id === '395262') return
  if (event.restaurant_id === '123123') return
  if (event.restaurant_name === 'E2E Ravintola') return
  if (event.restaurant_name === 'TestiRavintola') return
  if (event.restaurant_name === 'EsittelyRavintola') return
  // Test tables, don't send analytics data
  if (event.table_type === TableType.TEST) return
  if (event.table_id === '4321') return
  if (event.table_id === 'TEST') return
  if (event.table_id === 'TESTI') return

  if (typeof window === 'undefined') return

  // https://issuetracker.google.com/issues/396043331
  window.gmo = window.gmo || {webView: {darkMode: {pageHasDarkTheme: () => false}}}
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(event)

  // Flush dataLayer variables after push
  // https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
  window.dataLayer.push({
    event: undefined,
    data: undefined,
    ecommerce: undefined,
    restaurant_id: undefined,
    restaurant_name: undefined,
    table_id: undefined,
    table_type: undefined,
    table_status: undefined,
    cart_id: undefined,
    cost_center_id: undefined,
    restaurant_features_bill_request: undefined,
    restaurant_features_kitchen_closed: undefined,
    restaurant_features_quick_order: undefined,
    restaurant_features_room_service_enabled: undefined,
    restaurant_features_room_service_delivery_methods: undefined,
    restaurant_features_rush_hour: undefined,
    restaurant_features_table_actions: undefined,
    restaurant_features_waiter_call: undefined,
  })
}

export default triggerEvent
