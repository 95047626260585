import {CheckboxGroup, RadioButtonGroup} from '@s-group/design-system-components'
import {ChangeEvent} from 'react'
import PortionOptionLabel from './PortionOptionLabel'
import RadioButtonOrCheckbox from './RadioButtonOrCheckbox'
import {Quantity, QuantityWrapper, RadioButtonOrCheckboxWrapper} from './SelectionBoxStyles'
import {SelectionBoxGroupProps} from './SelectionBoxTypes'

/**
 * Selection group component for portion options.
 *
 * Renders a group of radio buttons or checkboxes.
 */
const SelectionBoxGroup = ({
  options,
  optionSectionId,
  selectedIds,
  onChange,
  portionId,
  buttonType,
  maxCount,
}: SelectionBoxGroupProps) => {
  const WrapperComponent = buttonType === 'radio' ? RadioButtonGroup : CheckboxGroup
  const isCheckBox = buttonType === 'checkbox'

  return (
    <WrapperComponent>
      {options.map((portionOption) => {
        const {id: portionOptionId, disabled} = portionOption
        const labelElement = <PortionOptionLabel portionOption={portionOption} />
        const isSelected = selectedIds?.some((id) => id === portionOptionId) ?? false
        const allSelectionsQuantity = selectedIds?.length || 0
        const thisSelectionQuantity = selectedIds?.filter((id) => id === portionOptionId).length || 0
        const showQuantitySelector = isSelected && maxCount && maxCount > 1 && isCheckBox
        const additionDisabled = (maxCount && allSelectionsQuantity === maxCount) || false
        const selectionDisabled = disabled || (additionDisabled && !isSelected && isCheckBox)

        const handleOnChange = ({decreaseQuantity, deselect}: {decreaseQuantity?: boolean; deselect?: boolean}) =>
          onChange(portionOptionId, decreaseQuantity, deselect)

        const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
          if (!e.target.checked) {
            return handleOnChange({decreaseQuantity: true, deselect: true})
          }
          return handleOnChange({})
        }

        /**
         * Use additional row height for checkbox with Quantity component
         */
        const additionalRowHeght = (maxCount && maxCount > 1 && isCheckBox) || false

        return (
          <RadioButtonOrCheckboxWrapper key={portionOptionId} $additionalRowHeght={additionalRowHeght}>
            <RadioButtonOrCheckbox
              testId={`portion-option-${portionId}-${portionOptionId}`}
              buttonType={buttonType}
              name={optionSectionId}
              value={portionOptionId}
              label={labelElement}
              onChange={handleSelect}
              checked={isSelected}
              disabled={selectionDisabled}
            />
            {showQuantitySelector && (
              <QuantityWrapper>
                <Quantity
                  type='button'
                  $disableAddition={additionDisabled}
                  sizing='xsmall'
                  color='neutral'
                  variant='outlined'
                  value={thisSelectionQuantity}
                  onChange={(e) =>
                    handleOnChange({
                      decreaseQuantity: thisSelectionQuantity > e || false,
                      deselect: !e || e === 0,
                    })
                  }
                />
              </QuantityWrapper>
            )}
          </RadioButtonOrCheckboxWrapper>
        )
      })}
    </WrapperComponent>
  )
}

export default SelectionBoxGroup
