import {TZDate} from '@date-fns/tz'
import {format} from 'date-fns'
import * as Sentry from '@sentry/react'
import {PickupTime} from '../../graphql/graphql'

// Currently all restaurants are in the Finnish timezone. This hardcoded
// timezone should be changed to restaurant-specific timezone in the future if
// we expand outside of Finland to other timezones.
export const timezoneFinland = 'Europe/Helsinki'

export function isValidDate(dateString: string): boolean {
  const date = new Date(dateString)
  return !Number.isNaN(date.getTime())
}

/**
 * This function tries to parse meaningful date and time from a date string.
 * Also handles error if dateString is not valid
 * @param dateString value tried to be parsed as date
 * @returns formattedDateString or false
 */
export const formatDateString = ({
  dateString,
  onlyTime,
}: {
  dateString: string
  onlyTime?: boolean
}): string | undefined => {
  let formattedDateString: string | undefined
  // if dateString is some invalid format, we don't want the app to crash
  if (!isValidDate(dateString)) {
    Sentry.captureMessage('Invalid date string', {
      level: 'warning',
      extra: {
        dateString,
      },
    })
    return undefined
  }
  try {
    const date = dateString ? new Date(dateString) : undefined
    if (onlyTime) {
      formattedDateString = date && format(TZDate.tz(timezoneFinland, date), 'HH:mm')
    } else {
      formattedDateString = date && format(TZDate.tz(timezoneFinland, date), 'dd.MM. HH:mm')
    }
  } catch (error) {
    // let's just log the error and not crash the app
    console.error('Error parsing date', error)
  }
  return formattedDateString
}

/**
 * Returns either 'ASAP' or time in HH:mm format.
 *
 * Returns '--:--' if the pickup time is missing or invalid.
 */
export const formatPickupTime = (pickupTime: PickupTime | undefined | null): string => {
  if (!pickupTime) {
    console.error('Pickup time data is missing')
    return '--:--'
  }
  if (pickupTime.asap) return 'ASAP'
  if (pickupTime.time) {
    const formattedTime = formatDateString({dateString: pickupTime.time, onlyTime: true})
    if (formattedTime) return formattedTime
  }
  console.error('Invalid pickup time:', pickupTime)
  return '--:--'
}
