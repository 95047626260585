import Image from '@marketplace/shared-lib/src/UI/Image/Image'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Text} from '@s-group/design-system-components'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {setModalInitiatorRefVar} from 'state/reactiveVariables'
import {color, spacing} from 'theme'
import {ProductCardProps} from 'types/productCardTypes'
import {resolveIsUrlValid} from 'utils/images'
import {getLowestPortionPriceAndSize} from 'utils/portionPriceUtils'
import {
  DietTagsAbbreviations,
  IconAdd,
  LowestPricePrefix,
  OutOfStockNotice,
  PriceValue,
  ProductCardTitleRow,
  ProductContainerButton,
  ProductDescription,
  ProductImageWrapper,
  ProductInfoDetails,
  ProductInfoWrapper,
  ProductTitle,
} from './ProductStyles'
import {useGetCommaSeparatedDietTagsAbbreviations} from './productCardUtils'

/**
 * Product card shows basic info about the product. Used in the menu listings.
 */
const ProductCard = ({portion, onClick}: ProductCardProps) => {
  const {restaurantFeatures} = useRestaurantContext()
  const isDisabled =
    restaurantFeatures?.disabledPortions?.some(
      ({id, portionOptions}) => id === portion.id && portionOptions.length === 0
    ) ?? false

  const {i18n, t} = useTranslation('order')
  const locale = i18n.language
  const getDietTags = useGetCommaSeparatedDietTagsAbbreviations()
  const menuItemRef = useRef(null)

  const [isValidImageUrl, setIsValidImageUrl] = useState<boolean>(false)

  const {name, description, id: portionId, images, diet} = portion
  const {url, text} = images[0] || {}
  const {lowestNormal, lowestCoop, hasMultiple} = getLowestPortionPriceAndSize(portion)

  useEffect(() => {
    const validateImageUrl = async () => {
      const isValid = await resolveIsUrlValid(url)
      setIsValidImageUrl(isValid)
    }

    if (url) {
      validateImageUrl()
    }
  }, [url])

  const title = getLocalized(name, locale)
  const descriptionTranslated = getLocalized(description, locale)
  const commaSeparatedDietTagsAbbreviations = getDietTags(diet)

  const handleClick = () => {
    if (onClick) {
      onClick()
      setModalInitiatorRefVar(menuItemRef)
    }
  }

  return (
    <ProductContainerButton
      variant='plain'
      color='neutral'
      onClick={handleClick}
      ref={menuItemRef}
      data-testid={`product-card-${portionId}`}
      aria-labelledby={portionId}
      aria-describedby={`product-description-${portionId}`}
    >
      <ProductCardTitleRow>
        <ProductTitle
          variant='heading'
          sizing='xxsmall'
          weight='medium'
          $textColor={color.text.strongPrimary}
          data-testid={`product-title-${portionId}`}
        >
          {title}
          {commaSeparatedDietTagsAbbreviations && (
            <DietTagsAbbreviations
              variant='heading'
              sizing='xxsmall'
              weight='regular'
              $textColor={color.text.strongPrimary}
            >{`(${commaSeparatedDietTagsAbbreviations})`}</DietTagsAbbreviations>
          )}
        </ProductTitle>
        <IconAdd size='16' color='white' alt-label={t('menu.addToOrder')} />
      </ProductCardTitleRow>
      <ProductInfoWrapper>
        <ProductInfoDetails>
          {descriptionTranslated && (
            <ProductDescription variant='body' sizing='small' id={`product-description-${portionId}`}>
              {descriptionTranslated}
            </ProductDescription>
          )}
          {isDisabled && (
            <OutOfStockNotice status='warning' variant='plain' sizing='small' header={t('menu.outOfStock')} />
          )}
          <PriceValue data-testid={`product-card-price-${portionId}`}>
            {hasMultiple && (
              <LowestPricePrefix variant='body' sizing='small' weight='light'>
                {t('menu.lowestPrice')}
              </LowestPricePrefix>
            )}
            <Text weight={!lowestCoop ? 'bold' : 'regular'}>{formatPrice({price: lowestNormal})}</Text>
            <CoopMemberPrice marginLeft={spacing.medium} coopMemberPrice={lowestCoop} testId='coop-member-price' />
          </PriceValue>
        </ProductInfoDetails>
        <ProductImageWrapper>
          {isValidImageUrl && <Image biasWidth={360} url={url} alt={getLocalized(text, locale)} />}
        </ProductImageWrapper>
      </ProductInfoWrapper>
    </ProductContainerButton>
  )
}

export default ProductCard
