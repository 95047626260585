import {Button, Loader} from '@s-group/design-system-components'
import {IconUserProfile, IconUserProfileFilled} from '@s-group/design-system-icons'
import {SDS_BRAND_COLOR_BACKGROUND_DEFAULT_NEUTRAL} from '@s-group/design-system-tokens/web/tokens/raflaamo/colors.es6'
import useCustomerAccount from 'hooks/useCustomerAccount'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {LoginButtonWrapper} from './CustomerAccountButtonStyles'
import LoginPopUp from './LoginPopUp'

type Props = {
  desktop?: boolean
}

type CustomerIconProps = {
  isLoggedIn: boolean
  loading: boolean
}

const CustomerIcon = ({isLoggedIn, loading}: CustomerIconProps) => {
  const {t} = useTranslation('remotePage')
  if (loading) {
    return (
      <Loader
        size='small'
        type='circle'
        data-testid='customer-account-loader'
        color={SDS_BRAND_COLOR_BACKGROUND_DEFAULT_NEUTRAL}
        aria-label={t('loading')}
      />
    )
  }
  return isLoggedIn ? <IconUserProfileFilled /> : <IconUserProfile />
}

const CustomerAccountButton = ({desktop}: Props) => {
  const {t} = useTranslation('login')
  const [visible, setVisible] = useState<boolean>(false)

  const {customerAccountData, customerAccountLoading} = useCustomerAccount()
  const customer = customerAccountData?.getCustomerAccount.customer
  const isLoggedIn = !!customer
  const label = isLoggedIn ? t('userActions') : t('login')

  const toggleVisible = () => {
    if (!customerAccountLoading) {
      setVisible(!visible)
    }
  }

  return (
    <LoginButtonWrapper>
      <Button
        sizing='small'
        variant='filled'
        color='neutral'
        rounding='small'
        icon={<CustomerIcon isLoggedIn={isLoggedIn} loading={customerAccountLoading} />}
        iconOnly={!desktop}
        onClick={toggleVisible}
        data-testid='usermenu-login-button'
        aria-label={label}
      />
      <LoginPopUp customer={customer || null} visible={visible} setVisible={setVisible} />
    </LoginButtonWrapper>
  )
}

export default CustomerAccountButton
