import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import ProductRecommendationCard from 'Components/Product/ProductRecommendationCard'
import {PortionWithMenuID} from 'Components/Product/productCardUtils'
import {useCartRecommendations} from 'hooks/useRecommendations'
import useTriggerEvent from 'hooks/useTriggerEvent'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import useTriggerViewPromotionEvent from 'hooks/useTriggerViewPromotionEvent'
import useUpdateCartMutation from 'hooks/useUpdateCartMutation'
import {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {EventItemContext} from 'types/analyticsTypesNew'
import {mapPortionToEventItem} from 'utils/analytics'
import {getLowestPortionPriceAndSize} from 'utils/portionPriceUtils'
import {RecommendationsHeader, RecommendationsWrapper} from './CartRecommendationsStyles'

const CartRecommendations = () => {
  const {t, i18n} = useTranslation('order')
  const locale = i18n.language
  const recommendations = useCartRecommendations()
  const dynamicCartRecommendationsHeader = getLocalized(recommendations?.header, locale)
  const {cartModify, loading: cartLoading} = useUpdateCartMutation()
  const {triggerEvent} = useTriggerEvent()
  const triggerEventNew = useTriggerEventNew()

  const recommendationsHeader =
    dynamicCartRecommendationsHeader !== '' ? dynamicCartRecommendationsHeader : t('summary.recommendationsHeader')

  useTriggerViewPromotionEvent(recommendations?.portions, {
    promotion_name: recommendationsHeader,
    creative_name: 'cart_recommendations',
  })

  const handleOnAddItem = (portion: PortionWithMenuID, index: number) => {
    const price = getLowestPortionPriceAndSize(portion).lowestNormal.toString(10)
    const analytics: EventItemContext = {
      index,
      promotion_name: recommendationsHeader,
      creative_name: 'cart_recommendations',
    }
    triggerEventNew?.({
      event: 'select_promotion',
      ecommerce: {
        items: [{...mapPortionToEventItem(portion), ...analytics}],
      },
    })

    const items = [{portion, analytics, menuID: portion.menuID}]

    cartModify({
      items,
      successCallback: () =>
        triggerEvent({
          event: AnalyticsEvents.ADD_TO_CART,
          action: AnalyticsActions.ADD_TO_CART_RECOMMENDATIONS_CART,
          items: [{item_name: portion.name.fi, quantity: 1, price}],
        }),
    })
  }

  if (!recommendations?.portions.length) {
    return null
  }

  return (
    <RecommendationsWrapper data-testid='cart-recommendations'>
      <RecommendationsHeader variant='heading' sizing='xxsmall' weight='medium'>
        {recommendationsHeader}
      </RecommendationsHeader>
      {recommendations.portions.map((portion, index) => (
        <Fragment key={portion.id}>
          <ProductRecommendationCard
            portion={portion}
            onAddItem={() => handleOnAddItem(portion, index)}
            loading={cartLoading}
          />
        </Fragment>
      ))}
    </RecommendationsWrapper>
  )
}

export default CartRecommendations
