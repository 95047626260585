import {Text} from '@s-group/design-system-components'
import Header from 'Components/Header/Header'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {Main} from 'styles/GeneralStyles'
import {color} from 'theme'
import {getAllOrders} from 'utils/order'
import {OrderConfirmationWrapper} from '../OrderStyles'
import OrderHistoryItem from './OrderHistoryItem'
import {OrderHistoryTitleWrapper} from './OrderHistoryStyles'

const OrderHistory = () => {
  const navigate = useNavigate()
  const {t} = useTranslation('order')
  const {
    tableData,
    tableLoading,
    cartData,
    cartLoading,
    cartError,
    handledOrderData,
    handledOrderLoading,
    handledOrderError,
  } = useRestaurantContext()
  const cartID = cartData?.getCart.id
  const {pendingOrders, ordersInProgress, handledOrders} = getAllOrders(tableData, handledOrderData, cartID)
  const ordersAmount = (pendingOrders?.length ?? 0) + (handledOrders?.length ?? 0) + (ordersInProgress?.length ?? 0)
  const hasOrders = ordersAmount > 0
  const isLoading = tableLoading || cartLoading || handledOrderLoading
  const hasError = handledOrderError || cartError
  const tableType = tableData?.getTable.tableType

  useEffect(() => {
    if ((!isLoading && !hasOrders) || hasError) {
      navigate('/', {replace: true})
    }
  }, [isLoading, hasOrders, hasError, navigate])

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <Main $backgroundColor={color.background.weakSecondary}>
      <NotificationBanner />
      <Header isOrderFlow />
      <OrderHistoryTitleWrapper>
        <Text
          variant='display'
          sizing='xxxxsmall'
          weight='bold'
          transform='uppercase'
          data-testid='order-history-title'
        >
          {hasOrders ? `${t('history.title')}: ` : t('history.title')}
        </Text>
        <Text data-testid='order-history-title-order-count' variant='display' sizing='xxxxsmall' weight='semibold'>
          {ordersAmount}
        </Text>
      </OrderHistoryTitleWrapper>
      <OrderConfirmationWrapper data-testid='order-history-confirmation-items'>
        {pendingOrders &&
          pendingOrders.map((order, index) => <OrderHistoryItem order={order} tableType={tableType} key={index} />)}
        {ordersInProgress &&
          ordersInProgress.map((order, index) => <OrderHistoryItem order={order} tableType={tableType} key={index} />)}
        {handledOrders &&
          handledOrders.map((order, index) => <OrderHistoryItem order={order} tableType={tableType} key={index} />)}
      </OrderConfirmationWrapper>
    </Main>
  )
}

export default OrderHistory
