import {Button, Text} from '@s-group/design-system-components'
import styled from 'styled-components'

export const PopupWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({theme}) => theme.spacing.small};
  padding: ${({theme}) => theme.spacing.small} ${({theme}) => theme.spacing.xsmall};
  position: relative;
  text-align: center;
  max-width: 330px;
`

export const PopupTitle = styled(Text)`
  margin: 0;
`

export const PopupText = styled(Text)`
  padding-top: ${({theme}) => theme.spacing.xxsmall};
`

export const PopupButton = styled(Button)`
  width: 100%;
  max-width: 8rem;
`
export const TakeawayFeedbackWrapper = styled.span`
  display: grid;
  gap: ${({theme}) => theme.spacingCustom.medium};
  padding-top: ${({theme}) => theme.spacing.xxsmall};
`
