import {ApolloProvider} from '@apollo/client'
import MarketplaceLDProvider from '@marketplace/shared-lib/src/launchDarkly/LDProvider'
import SDSThemeProvider from '@marketplace/shared-lib/src/SDSThemeProvider'
import apolloClient from 'apollo/apolloClient'
import config from 'config'
import {useUCScript} from 'hooks/useUCScript'
import RemoteRoutes from 'routes/RemoteRoutes'
import ModalProvider from 'state/ModalProvider'
import RestaurantProvider from 'state/RestaurantProvider'
import {ThemeProvider} from 'styled-components'
import GlobalStyle from 'styles/GlobalStyle'
import {remoteTheme} from 'theme'
import {useSentry} from 'utils/sentryInit'

const App = (): JSX.Element => {
  useUCScript()
  useSentry()

  return (
    <ApolloProvider client={apolloClient}>
      <SDSThemeProvider>
        <ThemeProvider theme={remoteTheme}>
          <GlobalStyle />
          <MarketplaceLDProvider clientSideID={config.LAUNCH_DARKLY?.CLIENT_SIDE_ID || ''}>
            <RestaurantProvider>
              <ModalProvider>
                <RemoteRoutes />
              </ModalProvider>
            </RestaurantProvider>
          </MarketplaceLDProvider>
        </ThemeProvider>
      </SDSThemeProvider>
    </ApolloProvider>
  )
}

export default App
