import {Language, remoteLanguages} from '@marketplace/shared-lib/src/types/language'
import * as Sentry from '@sentry/react'
import {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {SentryTagKeys} from 'utils/sentryUtils'
import {LanguageMenuSelect, LanguageMenuWrapper} from './LanguageMenuStyles'

declare global {
  interface Window {
    UC_UI: {
      updateLanguage: (language: string) => void
    }
  }
}

window.UC_UI = window.UC_UI || {}

const LanguageMenu = (): ReactElement => {
  const {t, i18n} = useTranslation('remotePage')

  const changeLanguage = async (language: Language) => {
    // save the language to local storage
    window.localStorage.setItem('i18nextLng', language)
    await i18n.changeLanguage(language)
    Sentry.setTag(SentryTagKeys.Language, language)
    try {
      window?.UC_UI?.updateLanguage(language)
    } catch {
      console.warn('no UC_UI')
    }
  }

  return (
    <LanguageMenuWrapper>
      <LanguageMenuSelect
        defaultValue={i18n.language}
        data-testid='language-switcher-dropdown'
        rounding='small'
        sizing='xsmall'
        color='black'
        onChange={(event) => changeLanguage(event.target.value as Language)}
        aria-label={t('language')}
      >
        {remoteLanguages.map((language) => (
          <option translate='no' data-testid={`language-switcher-option-${language}`} key={language} value={language}>
            {language.toUpperCase()}
          </option>
        ))}
      </LanguageMenuSelect>
    </LanguageMenuWrapper>
  )
}

export default LanguageMenu
