import {PortionFieldsFragment, PortionOptionPrice, PriceType} from '@marketplace/shared-lib/graphql/graphql'
import {PortionSelectedIds, SelectedIds} from 'types/productCardTypes'
import {OrderAndCartFields} from 'types/tableTypes'

/**
 * Get normal price and coop member price (if any) based on which radio button option is selected, for ex. price of 75cl bottle vs 16cl glass
 * @param portionOptions all portion options
 * @param selectedPortions all currently selected portion options, either default or user selected
 */

export const getPortionPrice = (portionOptions: PortionFieldsFragment, selectedPortions?: SelectedIds) => {
  let absolutePrice = portionOptions.price?.normal
  let coopMemberPrice = portionOptions.price?.coopMember
  let additivePrices: number[] = []
  let coopMemberAddictivePrices: number[] = []

  const selectedOptionsPrices = portionOptions.portionOptionSections
    .flatMap((optionSection) =>
      optionSection?.portionOptions
        .filter(
          (option) =>
            selectedPortions?.some(({selectedOptionIds}) => selectedOptionIds?.includes(option.id)) && option.price
        )
        .map((option) => option.price)
    )
    .filter((p): p is PortionOptionPrice => !!p)

  selectedOptionsPrices.forEach((selectedPrice) => {
    const {type, normal, coopMember} = selectedPrice
    // If an option with an absolute price is selected, use that for absolute price
    if (type === PriceType.ABSOLUTE) {
      absolutePrice = normal
      coopMemberPrice = coopMember
    }
    // Gather selected additive prices for later summing
    if (type === PriceType.ADDITIONAL) {
      additivePrices = [...additivePrices, normal]
      // if there's no coopMember pricing for additional prices, add normal price from selection
      coopMemberAddictivePrices = [...coopMemberAddictivePrices, coopMember || normal]
    }
  })
  if (!absolutePrice) absolutePrice = 0

  const totalNormalPrice = [...additivePrices, absolutePrice].reduce((sum, priceToAdd) => sum + priceToAdd)
  const totalCoopMemberPrice = coopMemberPrice
    ? [...coopMemberAddictivePrices, coopMemberPrice].reduce((sum, priceToAdd) => sum + priceToAdd)
    : undefined
  return {totalNormalPrice, totalCoopMemberPrice}
}

/**
 * Get normal and coop member (if any) total prices of all selected portions
 * Get normal price and coop member price (if any) based on which radio button option is selected, for ex. price of 75cl bottle vs 16cl glass
 * @param selectedPortions all selected portions (default + recommended)
 * @param portionsSelections all portions selections
 */
export const getPortionsTotalPrices = (
  selectedPortions: PortionFieldsFragment[],
  portionsSelections: PortionSelectedIds[]
) => {
  let hasCoopPrices = false
  const result = selectedPortions.reduce(
    (acc, portion) => {
      const selections = portionsSelections.find((selection) => selection[portion.id])?.[portion.id]
      const {totalNormalPrice, totalCoopMemberPrice} = getPortionPrice(portion, selections)
      if (totalCoopMemberPrice) hasCoopPrices = true
      acc.totalNormal += totalNormalPrice
      acc.totalCoop += totalCoopMemberPrice || totalNormalPrice
      return acc
    },
    {totalNormal: 0, totalCoop: 0}
  )

  return {
    totalNormalPrice: result.totalNormal,
    totalCoopMemberPrice: hasCoopPrices ? result.totalCoop : undefined,
  }
}

const isNotNullish = <T>(input: T | null | undefined): input is T => {
  return input !== null && input !== undefined
}

/**
 * Get lowest normal and coop member ABSOLUTE price.
 * Get info if portion has multiple ABSOLUTE prices.
 * Get size of portion with ABSOLUTE price.
 * @param portionParam portion with all sections and options
 */
export const getLowestPortionPriceAndSize = (portionParam: PortionFieldsFragment | OrderAndCartFields['portion']) => {
  const {price: portionPrice, portionOptionSections} = portionParam
  let lowestNormal = 0
  let lowestCoop
  let hasMultiple = false
  let size

  if (portionPrice) {
    lowestNormal = portionPrice.normal
    lowestCoop = portionPrice.coopMember
    hasMultiple = false
  }

  const optionsAbsolutePrices = portionOptionSections.flatMap((optionSection) =>
    optionSection?.portionOptions
      .filter((option) => option.price?.type === PriceType.ABSOLUTE)
      .map((option) => (option.price ? {price: option.price, label: option.label} : null))
  )

  if (optionsAbsolutePrices.length > 0) {
    const {price, label} = optionsAbsolutePrices
      .filter(isNotNullish)
      .reduce((prev, curr) => (prev.price.normal < curr.price.normal ? prev : curr))

    lowestNormal = price.normal
    lowestCoop = price.coopMember
    size = label
  }

  if (optionsAbsolutePrices.length > 1) {
    hasMultiple = true
  }

  return {
    lowestNormal,
    lowestCoop,
    hasMultiple,
    size,
  }
}
