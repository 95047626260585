import FooterFeedbackLink from 'Components/Feedback/FooterFeedbackLink'
import {SiteInfo} from 'Components/SiteInfo'
import {TableActions} from 'Components/TableActions'
import type {RestaurantContextProps} from 'state/RestaurantContext'

type RestaurantMainContentProps = {
  restaurantData: NonNullable<RestaurantContextProps['restaurantData']>
  restaurantFeatures: RestaurantContextProps['restaurantFeatures']
  tableData: NonNullable<RestaurantContextProps['tableData']>
}
const RestaurantMainContent = ({restaurantData, restaurantFeatures, tableData}: RestaurantMainContentProps) => (
  <>
    <SiteInfo restaurantData={restaurantData} />
    <TableActions restaurantFeatures={restaurantFeatures} table={tableData.getTable} />
    <FooterFeedbackLink />
  </>
)

export default RestaurantMainContent
