import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import {LDContextCommon, LDMultiKindContext, LDSingleKindContext} from 'launchdarkly-react-client-sdk'
import {TableType} from '../../graphql/graphql'

const LD_COOKIE_CONTEXT_KEY = 'ld-context'
const COOKIE_EXPIRY_MS = 2624016

export interface MarketplaceRestaurantContext extends LDContextCommon {
  kind: 'marketplace-restaurant'
  tableType: TableType | 'unknown'
  isStaff: boolean
}

export interface MarketplaceUserContext extends LDContextCommon {
  kind: 'raflaamo-guest'
  isTestTable?: boolean
}

export interface MarketplaceContext extends LDMultiKindContext {
  restaurant: MarketplaceRestaurantContext
  user: MarketplaceUserContext
}

export const setPersistedUserContext = (context: LDSingleKindContext) => {
  const expirytime = COOKIE_EXPIRY_MS
  try {
    Cookies.set(LD_COOKIE_CONTEXT_KEY, JSON.stringify(context), {
      secure: true,
      expires: expirytime,
    })
  } catch (exception) {
    Sentry.captureMessage('Failed to set persisted context', {
      level: 'warning',
      extra: {
        exception,
      },
    })
  }
}

export const getPersistedUserContext = () => {
  try {
    const stored = Cookies.get(LD_COOKIE_CONTEXT_KEY)
    if (stored) {
      return JSON.parse(stored) as LDSingleKindContext
    }
  } catch (exception) {
    Sentry.captureMessage('Failed to get persisted context', {
      level: 'warning',
      extra: {
        exception,
      },
    })
  }
  return undefined
}
