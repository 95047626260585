import type {GetRestaurantListItemQueryResult, RestaurantSelection} from '@marketplace/shared-lib/graphql/graphql'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Button} from '@s-group/design-system-components'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {FoodImage, ListItemContainer, RestaurantName, TextContainer} from './RestaurantListItemStyles'

export type RestaurantListItemType = RestaurantSelection & {
  restaurant: NonNullable<GetRestaurantListItemQueryResult['data']>['getRestaurant']
}

const RestaurantListingImage = ({restaurant}: {restaurant: RestaurantListItemType['restaurant']}) => {
  const {
    i18n: {language},
  } = useTranslation()

  const image = restaurant.visualContent.listingImage ?? restaurant.visualContent.hero ?? restaurant.visualContent.logo
  if (!image) return null

  let altText = getLocalized(image.text, language)
  if (!altText) {
    altText = getLocalized(restaurant.name, language)
  }

  return <FoodImage src={image.url} aria-hidden />
}

type RestaurantListItemProps = {
  item: RestaurantListItemType
}

const RestaurantListItem = ({item: {restaurant, tableToken}}: RestaurantListItemProps) => {
  const {
    t,
    i18n: {language},
  } = useTranslation('remotePage')
  const navigate = useNavigate()

  const navigateToTable = useCallback(() => {
    if (tableToken) {
      navigate(`/${tableToken}`)
    }
  }, [navigate, tableToken])

  return (
    <ListItemContainer
      tabIndex={0}
      aria-label={`${t('restaurantSelection.selectButton')} ${getLocalized(restaurant.name, language)}`}
      data-testid={`restaurant-list-item-${restaurant.id}`}
      onClick={navigateToTable}
      onKeyUp={(event) => (event.key === 'Enter' || event.key === ' ') && navigateToTable()}
    >
      <RestaurantListingImage restaurant={restaurant} />
      <TextContainer>
        <RestaurantName variant='display' sizing='xxxxxsmall' weight='bold' transform='uppercase'>
          {getLocalized(restaurant.name, language)}
        </RestaurantName>
        <Button sizing='xsmall' rounding='small' variant='filled' color='neutral' tabIndex={-1}>
          {t('restaurantSelection.selectButton')}
        </Button>
      </TextContainer>
    </ListItemContainer>
  )
}

export default RestaurantListItem
