// Base dataLayer model must not change between different events
// Events must implement a subset of the base model

import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {AnalyticsEvents, DataLayerFlush, DataLayerModel, DataLayerOrderItem} from 'types/analyticsTypes'
import {MadeOrder, OrderAndCartFields} from 'types/tableTypes'
import {getLowestPortionPriceAndSize} from 'utils/portionPriceUtils'

export const triggerEvent = (event: DataLayerModel) => {
  /* eslint-disable camelcase */
  const {table_type} = event
  if (table_type && table_type === TableType.TEST) return

  if (typeof window === 'undefined') return
  // https://issuetracker.google.com/issues/396043331
  window.gmo = window.gmo || {webView: {darkMode: {pageHasDarkTheme: () => false}}}
  if (!Array.isArray(window.dataLayer)) {
    window.dataLayer = []
  }
  window.dataLayer.push(event)

  // Flush dataLayer variables after push
  // https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
  window.dataLayer.push({
    event: undefined,
    action: undefined,
    restaurant_name: undefined,
    cart_id: undefined,
    table: undefined,
    table_type: undefined,
    items: undefined,
  } satisfies DataLayerFlush)
}

export const mapCartToDataLayerEvent = (madeOrder: MadeOrder): DataLayerOrderItem[] => {
  const items = madeOrder?.cart?.map(({portion, quantity, unitPrice}) => {
    return {
      item_name: portion.name.fi,
      quantity,
      price: unitPrice.normal.toString(10),
      item_category: portion.type,
    }
  })
  return items ?? []
}

// TODO-NL: how to handle portion options which have effect on the final price?
export const mapOrderItemsToDataLayerItems = (items: OrderAndCartFields[]) =>
  items.map(({portion}) => {
    const price = getLowestPortionPriceAndSize(portion).lowestNormal.toString(10)
    return {item_name: portion.name.fi, quantity: 1, price}
  })

// Sets user_id dimension in GTM so that following events are associated with the user
export const triggerLoginEvent = (userId?: string) => {
  triggerEvent({event: AnalyticsEvents.LOGIN, user_id: userId})
}

// Clears user_id dimension in GTM so that following events are not associated with the user
export const triggerLogoutEvent = () => {
  triggerEvent({event: AnalyticsEvents.LOGOUT, user_id: undefined})
}
