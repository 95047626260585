import {large, small} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Button, Text} from '@s-group/design-system-components'
import styled from 'styled-components'

export const AgeDialogContent = styled.div<{$paddingBottom?: boolean}>`
  padding-bottom: ${({theme}) => theme.spacingCustom.medium};
  margin: 0 ${({theme}) => theme.spacing.small};
`

export const AgeDialogHeader = styled(Text)`
  display: block;
  margin: ${large} 0 ${small};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-gap: ${({theme}) => theme.spacing.small};
  margin: 0 ${({theme}) => theme.spacingCustom.large};
`

export const YesNoButton = styled(Button)`
  width: 100%;
  max-width: 8rem;
`

export const ButtonWrapper = styled.div`
  width: 10rem;
  margin: 0 ${({theme}) => theme.spacing.xsmall};
`
