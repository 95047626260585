import getSrcAttributes from './functions'
import type {ImageProps} from './types'

const Image = (props: ImageProps) => {
  const {damId, url, testid, alt} = props || {}

  if (!damId && !url) return null

  if (url && !url?.includes('dam-id')) {
    return (
      <picture>
        <img alt={alt} src={url} data-testid={testid ?? 'non-bias-image'} />
      </picture>
    )
  }

  const {src, sizes, webpSet, svgSet, pngSet, jpgSet} = getSrcAttributes(props)
  const breakPoints = sizes?.map((size) => `(min-width: ${size}px)`).join(',')
  const hasAlt = !!alt.length

  return (
    <picture data-testid={testid ?? 'bias-image'}>
      {webpSet && <source type='image/webp' srcSet={webpSet} sizes={breakPoints} />}
      {svgSet && <source type='image/svg' srcSet={svgSet} sizes={breakPoints} />}
      {pngSet && <source type='image/png' srcSet={pngSet} sizes={breakPoints} />}
      {jpgSet && <source type='image/jpg' srcSet={jpgSet} sizes={breakPoints} />}
      <img alt={hasAlt ? alt : undefined} src={src} aria-hidden={!hasAlt} />
    </picture>
  )
}

export default Image
