import {Select} from '@s-group/design-system-components'
import styled from 'styled-components'

const LanguageMenuWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  height: 24px;
  padding: ${({theme}) => `${theme.spacing.small} ${theme.spacing.xxsmall} ${theme.spacing.small} 0`};
`

const LanguageMenuSelect = styled(Select)`
  select {
    width: 56px;
    padding: ${({theme}) => `${theme.spacing.xxsmall} ${theme.spacing.xsmall}`};
    background-image: url('./chevron-down.svg');
    background-color: ${(props) => props.theme.color.background.inverseGrey};
    background-repeat: no-repeat;
    background-position: 75% 50%;
    border-color: ${(props) => props.theme.color.background.inverseGrey};
    color: ${(props) => props.theme.color.white};
  }

  ::after {
    display: none;
  }
`

export {LanguageMenuSelect, LanguageMenuWrapper}
