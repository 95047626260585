import {TableStatusType} from '@marketplace/shared-lib/graphql/graphql'
import {areGlobalTableActionsEnabled, isRestaurantKitchenOpen} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import {Button, Notification, Text} from '@s-group/design-system-components'
import {IconContentDocumentHistory} from '@s-group/design-system-icons'
import FooterFeedbackLink from 'Components/Feedback/FooterFeedbackLink'
import PhoneNumber from 'Components/PhoneNumber/PhoneNumber'
import {SiteInfo} from 'Components/SiteInfo'
import {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import type {RestaurantContextProps} from 'state/RestaurantContext'
import {getAllOrders} from 'utils/order'
import {resolveRestaurantPhoneNumber} from 'utils/restaurant'
import useRestaurantContext from '../../hooks/useRestaurantContext'
import {ContentWrapper, PromptContent} from './RestaurantMainContentStyles'
import {TakeawayDescription} from './TakeawayMainContentStyles'

type TakeawayMainContentProps = {
  restaurantData: NonNullable<RestaurantContextProps['restaurantData']>
  restaurantFeatures: NonNullable<RestaurantContextProps['restaurantFeatures']>
}
const TakeawayMainContent = ({restaurantData, restaurantFeatures}: TakeawayMainContentProps) => {
  const {t} = useTranslation('remotePage')
  const navigate = useNavigate()
  const {tableData, cartData, handledOrderData} = useRestaurantContext()
  const {tableActions, tableActionsAutomatic, kitchenClosed, kitchenOpenAutomatic, lastCallBuffer} = restaurantFeatures

  const cartID = cartData?.getCart.id
  const table = tableData?.getTable

  const isKitchenOpen = isRestaurantKitchenOpen({
    kitchenClosed,
    kitchenOpenAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
  })

  const tableActionsEnabled = areGlobalTableActionsEnabled({
    tableActions,
    tableActionsAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
    lastCallBuffer,
  })

  // Table specific settings override only if they are explicitly switched off
  const tableDisabled = table?.state.status === TableStatusType.DISABLED || table?.state.quickOrder === false
  const orderingDisabled = !tableActionsEnabled || !isKitchenOpen || !restaurantFeatures?.quickOrder || tableDisabled

  const {pendingOrders, ordersInProgress, handledOrders} = getAllOrders(tableData, handledOrderData, cartID)
  const hasOrders = (pendingOrders?.length ?? 0) + (handledOrders?.length ?? 0) + (ordersInProgress?.length ?? 0) > 0

  const navigateToMenu = () => navigate(NavigationRoute.Menu)

  // Take user directly to menu if there's no previous orders
  useEffect(() => {
    if (!hasOrders && !orderingDisabled) {
      navigate(NavigationRoute.Menu)
    }
  }, [hasOrders, orderingDisabled, navigate])

  const menuButtonText = orderingDisabled ? `${t('seeTheMenus')}` : `${t('startOrdering')}`

  const restaurantPhoneNumber = useMemo(
    () => resolveRestaurantPhoneNumber(restaurantFeatures, restaurantData),
    [restaurantFeatures, restaurantData]
  )

  return (
    <ContentWrapper>
      <SiteInfo restaurantData={restaurantData} subheaderOverride={t('takeawayServiceSubheader')} />
      <PromptContent>
        <TakeawayDescription data-testid='takeaway-description' variant='body' sizing='small'>
          {t('takeawayDescription')}
        </TakeawayDescription>
        <Button
          rounding='small'
          color='neutral'
          variant='filled'
          sizing='small'
          data-testid='takeaway-start-ordering-button'
          onClick={navigateToMenu}
        >
          {menuButtonText}
        </Button>
        {hasOrders && (
          <Button
            variant='outlined'
            color='neutral'
            rounding='small'
            sizing='small'
            icon={<IconContentDocumentHistory />}
            onClick={() => navigate(NavigationRoute.OrderHistory)}
            data-testid='order-history-button'
          >
            {t('orderHistoryButton')}
          </Button>
        )}
        {orderingDisabled && (
          <Notification status='warning' variant='tonal' sizing='small' alignment='left' close={false}>
            <Text>{t('takeawayServiceDisabled')}</Text>
          </Notification>
        )}
        {hasOrders && restaurantPhoneNumber && (
          <PhoneNumber description={`${t('phoneNumberDescription')}: `} phoneNumber={restaurantPhoneNumber} />
        )}
      </PromptContent>
      <FooterFeedbackLink tableType={table?.tableType} />
    </ContentWrapper>
  )
}

export default TakeawayMainContent
