import {QueryResult, QueryHookOptions, OperationVariables, SubscribeToMoreOptions, DocumentNode} from '@apollo/client'
import {useEffect, useCallback, useRef} from 'react'
import useVisibilityChange from './useVisibilityChange'
import useNetworkConnection from './useNetworkConnection'

// no magic about this number; pulled out of thin air
const resubscribeDelayOnErrorInMs = 5000

type SubscriptionOption<TData, TSubscriptionVariables extends OperationVariables, TSubscriptionData> = Omit<
  SubscribeToMoreOptions<TData, TSubscriptionVariables, TSubscriptionData>,
  'document' | 'variables'
> & {
  document: DocumentNode
  variables?: TSubscriptionVariables
}

type UsePersistentSubscriptionOptions<
  TData,
  TVariables extends OperationVariables,
  TSubscriptionData,
  TSubscriptionVariables extends OperationVariables,
> = Omit<QueryHookOptions<TData, TVariables>, 'skip'> & {
  skip?: boolean
  subscriptionOptions: SubscriptionOption<TData, TSubscriptionVariables, TSubscriptionData>[]
  resubscribeThresholdInMs: number
}

/**
 * A custom hook that combines Apollo Client's useQuery and subscribeToMore
 * functionalities. It manages subscriptions with automatic resubscription on
 * network or tab visibility changes.
 *
 * This hook handles initial data loading, subscription setup, and provides
 * mechanisms to handle subscription errors. It also automatically resubscribes
 * when the browser tab becomes visible after being hidden or when the network
 * connection is restored.
 */
const usePersistentSubscription = <
  TData,
  TVariables extends OperationVariables,
  TSubscriptionData,
  TSubscriptionVariables extends OperationVariables,
>(
  useQueryHook: (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
  options: UsePersistentSubscriptionOptions<TData, TVariables, TSubscriptionData, TSubscriptionVariables>
): Pick<QueryResult<TData, TVariables>, 'data' | 'loading' | 'error' | 'previousData' | 'refetch'> => {
  const {subscriptionOptions, resubscribeThresholdInMs, ...queryOptions} = options
  // this triggers the initial load of the data
  const {data, loading, error, previousData, refetch, subscribeToMore} = useQueryHook(queryOptions)
  // some helpers to restore the subscription after browser tab switch or network connection restored
  const {visibleAfterHidden} = useVisibilityChange(resubscribeThresholdInMs)
  const {reconnected} = useNetworkConnection(resubscribeThresholdInMs)

  const unsubscribeFunctions = useRef<((() => void) | undefined)[]>([])
  const resubscribeTimers = useRef<(NodeJS.Timeout | undefined)[]>([])

  // refetch() does not respect the skip value from useQuery.
  // Therefore, we need to track the skip state independently.
  const skipRef = useRef(queryOptions.skip)
  useEffect(() => {
    skipRef.current = queryOptions.skip
  }, [queryOptions.skip])

  const subscribe = useCallback(() => {
    subscriptionOptions.forEach((subscription, idx) => {
      const {document, variables, onError, ...restSubscriptionOptions} = subscription
      // If the query is skipped, it makes no sense to subscribe either. Also, we
      // should unsubscribe any ongoing subscriptions.
      if (skipRef.current) {
        unsubscribeFunctions.current[idx]?.()
        if (resubscribeTimers.current[idx]) clearTimeout(resubscribeTimers.current[idx])
        return
      }

      // unsubscribe possible previous subscription(s)
      unsubscribeFunctions.current[idx]?.()

      // wrap the onError callback to refetch and resubscribe after a small delay
      const onErrorWrapped = (err: Error) => {
        console.info('Subscription error:', err)
        if (resubscribeTimers.current) clearTimeout(resubscribeTimers.current[idx])
        resubscribeTimers.current[idx] = setTimeout(async () => {
          // Only refetch and resubscribe if not skipped
          if (!skipRef.current) {
            await refetch()
            subscribe()
          }
        }, resubscribeDelayOnErrorInMs)
        onError?.(err)
      }

      unsubscribeFunctions.current[idx] = subscribeToMore({
        document,
        variables,
        onError: onErrorWrapped,
        ...restSubscriptionOptions,
      })
    })
    // We need to JSON.stringify the options to make sure that the useEffect
    // hook is triggered only when the options change. This is necessary because
    // the options are objects and objects are compared by reference by React.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    skipRef.current,
    refetch,
    subscribeToMore,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(subscriptionOptions),
  ])

  // initial subscription
  useEffect(() => {
    subscribe()

    const currentUnsubscribeFunctions = unsubscribeFunctions.current
    const currentResubscribeTimers = resubscribeTimers.current

    // unsubscribe and clear timers on unmount
    return () => {
      currentUnsubscribeFunctions.forEach((current) => {
        current?.()
      })
      currentResubscribeTimers.forEach((current) => {
        if (current) clearTimeout(current)
      })
    }
  }, [subscribe])

  // Refetch and resubscribe when view is hidden and visible again or
  // network connection is restored.
  // Don't do this if skip is true.
  useEffect(() => {
    const refetchAndResubscribe = async () => {
      await refetch()
      subscribe()
    }
    if (!skipRef.current && (visibleAfterHidden || reconnected)) {
      refetchAndResubscribe()
    }
  }, [visibleAfterHidden, refetch, subscribe, reconnected])

  return {data, loading, error, previousData, refetch}
}

export default usePersistentSubscription
