import type {GetHotelQuery, GetRestaurantQuery} from '@marketplace/shared-lib/graphql/graphql'
import {Hero} from 'Components/Hero'
import {SiteInfo} from 'Components/SiteInfo'
import {TableActionsExpired} from 'Components/TableActions'
import {Main, MainContent} from 'styles/GeneralStyles'
import {color} from 'theme'

export const loggedOutTestId = 'logged-out-view'

type LoggedOutViewProps = {
  restaurantData?: GetRestaurantQuery
  hotelData?: GetHotelQuery['getHotel']
}

export const LoggedOut = ({restaurantData, hotelData}: LoggedOutViewProps): JSX.Element => {
  return (
    <Main data-testid={loggedOutTestId} $backgroundColor={color.background.weakSecondary}>
      <Hero restaurantData={restaurantData} hotelData={hotelData} />
      <MainContent>
        <SiteInfo restaurantData={restaurantData} hotelData={hotelData} />
        <TableActionsExpired />
      </MainContent>
    </Main>
  )
}
