import {Button, Modal} from '@s-group/design-system-components'
import styled from 'styled-components'

export const StyledModalBottom = styled(Modal)<{
  $small: boolean
  $maxWidth?: string
  $background?: string
  $animate?: boolean
  $noPadding?: boolean
}>`
  background: ${({$background, theme}) => $background ?? theme.color.white};
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: ${({$maxWidth}) => $maxWidth ?? '100vw'};
  ${({$small}) => $small && 'max-width: 450px'};
  overflow: hidden;

  /* Note: we must use svh (small viewport height) instead of vh.
   * svh acknowledges any elements taking up vertical space (e.g. browser
   * address bar)
   * fallback for old browsers (e.g. FF90) */
  max-height: calc(90vh - 125px - 3rem);

  /* modern browsers: height of the modal - height of the bottom action box */
  max-height: calc(90svh - 125px);
  transform: ${({$animate}) => ($animate ? 'translate(0,0)' : 'translate(0, 90vh)')};
  transition: transform 0.14s ease-in-out;

  .modal-body {
    ${({$noPadding}) => $noPadding && `padding: 0;`}
    z-index: 7000;
  }
`

export const ModalBottomContentWrapper = styled.div`
  height: 100%;
`

export const ModalBottomCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 9000;
`
